<div class="modal-header">
    <h4 class="modal-title">Configuración de {{phase.nombre}}</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
  </div>
  
 
  <div class="modal-body-scroll">
    <form [formGroup]="voboForm">

      <!-- Vobo 1 -->
      <ng-container *ngFor="let ph of phase.vobos">
      <div class="vobo-item ">
        <h5 class="fw-bold text-primary">{{ph.area}}</h5>
        <mat-form-field appearance="fill" class="compact-field">
          <mat-label>Responsables Configurados</mat-label>
          <mat-select [formControlName]="ph.area" multiple>
              <mat-option *ngFor="let u of ph.users" [value]="u.id">{{u.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      </ng-container>

    </form>
  </div>
  

  <div mat-dialog-actions class="modal-footer">
    <button mat-button color="warn" (click)="cerrarModal()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="guardarVobos()" [disabled]="!voboForm||(voboForm&&voboForm.invalid)">Guardar</button>
  </div>