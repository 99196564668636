import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { ProjectModel } from 'src/app/models/project.model';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-project-extra-stores-add-file',
  templateUrl: './project-extra-stores-add-file.component.html',
  styleUrls: ['./project-extra-stores-add-file.component.scss']
})
export class ProjectExtraStoresAddFileComponent implements OnInit {
  bodyForm : FormData = new FormData(); 
    form!: FormGroup;
    fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);  
    project: ProjectModel = new ProjectModel();

  constructor(
     @Inject(MAT_DIALOG_DATA) public data : any,
        private main : MainService,
        private fb : FormBuilder,
        private projectService : ProjectsService,
        private matDialog: MatDialog,
        private dialogRef: MatDialogRef<ProjectExtraStoresAddFileComponent>,
  ) {
    this.project = this.data.project;
   }

  ngOnInit(): void {
    this.loadForm();
    this.preloadFormData();
  }

  loadForm() {
      this.form = this.fb.group({
        blob : [null, {validators:[Validators.required]}]
      });
    }
  
    preloadFormData(){
      this.bodyForm.append('id_proj', this.project.id.toString() as string); 
      this.bodyForm.append('folder', this.project.folder as string);      
    }
  
    onValueChange(files : any)
    { 
      if(files.length >= 2)
      {      
        files.splice(1, 1);
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Sólo puede adjuntarse un documento'
        });
      }
  
      for (const item of files) {
        if(!(item.name.endsWith('.xlsx') && !(item.name.endsWith('.xls'))))
        {
          const index = files.indexOf(item);
    
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            html: 'Solo se permiten documentos en formato xls, xlsx'
          });
      
          if (index >= 0) {
            files.splice(index, 1);
          }
        }
        else {
  
        }
      }
    }
  
    uploadFile(){
      if(this.form.value.blob != null && this.form.value.blob?.length!=0){
  
        this.bodyForm.append("blob", this.form.value.blob[0] as File);
        
        Swal.fire({
          icon: 'info',
          title: 'Subiendo archivo...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        this.projectService.uploadExtraSucFile(this.bodyForm).subscribe(
          (result:any)=>{
            if(result.message == 'Success'){
              Swal.fire({
                icon: 'success',
                title: 'Archivo subido',
                text: 'Archivo subido exitosamente'          
              }).then(() => {            
                this.dialogRef.close({ reload: true });
              });
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
              });
            }
          },
          (err:any)=>{
            this.main.HideLoading();
            if(err.error.message == "Invalid file") {
              Swal.fire({
                icon: 'warning',
                title: 'Error',
                html: 'Solo se permiten documentos en formato .XLSX'
              });
        
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
              });
            }
          }
        )
        
    }else{
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'El documento no ha sido adjuntado'
        });
      }
    }
}
