<div class="content-wrapper">
    <!-- Content -->
    <div class="container-fluid flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary"><mat-icon class="align-middle">campaign</mat-icon> Campañas 
            <button mat-flat-button color="primary" class="ms-4" (click)="openAddProjectDialog()">Crear campaña nueva <mat-icon>campaign</mat-icon></button>
        </h4>
        <div>
        <!-- Card active projects -->
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-success align-middle">stop</mat-icon> Campañas activas</h5>
                        <div class="card-body" *ngIf="(projectListData.progress | json) != '[]'">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre campaña</th>
                                        <th>Status</th>
                                        <th>Fecha Inicio</th>
                                        <th>Creado por</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr *ngFor="let proy of projectListData.progress; let i = index">
                                        <td role="button"><strong>{{i+1}}</strong></td>
                                        <td role="button" (click)="goToProject(proy.id)"><strong>{{proy.name}}</strong></td>
                                        <td role="button" class="">
                                            <button type="button" class="btn btn-warning disabled" aria-expanded="false">
                                                En progreso
                                            </button>
                                        </td>
                                        <td role="button"><i> {{proy.date  | date : "dd/MM/yyyy"}} </i></td>                                        
                                        <td role="button">{{proy.owner}}</td>

                                        <td>
                                            <!--<button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="openProjectLogs(proy)">
                                                <mat-icon matBadge="{{proy.notif==0 ? '' : proy.notif}}" matBadgeColor="warn">forum</mat-icon>
                                            </button>-->
                                            <button mat-icon-button [matMenuTriggerFor]="menuCampana" matTooltip="Más acciones" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menuCampana="matMenu">
                                                <button (click)="openEditProjectDialog(proy)" mat-menu-item>
                                                    <mat-icon>edit</mat-icon>
                                                    <span>Editar</span>
                                                </button>
                                                <button (click)="deleteProjectConfirm(proy)" mat-menu-item>
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Eliminar</span>
                                                </button>
                                            </mat-menu> 
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Card active campaigns End-->

        <!-- Card inactive campaigns -->
        <div class="row" *ngIf="(projectListData.finished.length != 0)">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-warning align-middle">stop</mat-icon> Campañas Finalizadas {{ actual_year }}</h5>                  
                        <div class="card-body">
                        <div class="table text-nowrap">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre campaña</th>
                                        <th>Status</th>
                                        <th>Fecha Inicio</th>
                                        <th>Creado por</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr *ngFor="let proy of projectListData.finished; let i = index">
                                        <td role="button"><strong>{{i+1}}</strong></td>
                                        <td role="button" (click)="goToProject(proy.id)"><strong>{{proy.name}}</strong></td>
                                        <td role="button" class="">
                                            <button type="button" *ngIf="proy.status == 2" class="btn btn-success disabled" aria-expanded="false">
                                                Finalizada
                                            </button>
                                        </td>
                                        <td role="button"><i> {{proy.date  | date : "dd/MM/yyyy"}} </i></td>       
                                        <td role="button">{{proy.owner}}</td>                                 
                                        <td>
                                            <button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="openProjectLogs(proy)">
                                                <mat-icon matBadge="{{proy.notif==0 ? '' : proy.notif}}" matBadgeColor="warn">forum</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="deleteProjectConfirm(proy)">
                                                <mat-icon>delete</mat-icon>
                                            </button>                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Card inactive projects  past years-->
        <div class="row"  *ngIf="(projectListData.inactive | json) != '[]'">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-warning align-middle">stop</mat-icon> Campañas inactivas años anteriores</h5>
                        <div class="card-body">
                        <div class="table text-nowrap">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre campaña</th>
                                        <th>Status</th>
                                        <th>Fecha Límite</th>
                                        <th>Creado por</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr *ngFor="let proy of projectListData.pastyears let i = index">
                                        <td role="button"><strong>{{i+1}}</strong></td>
                                        <td role="button" (click)="goToProject(proy.id)"><strong>{{proy.name}}</strong></td>
                                        <td role="button" class="">
                                            <button type="button" class="btn btn-warning disabled" aria-expanded="false">
                                                En progreso
                                            </button>
                                        </td>
                                        <td role="button"><i> {{proy.date  | date : "dd/MM/yyyy"}} </i></td>  
                                        <td role="button">{{proy.owner}}</td>                                      
                                        <td>
                                            <button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="openProjectLogs(proy)">
                                                <mat-icon matBadge="{{proy.notif==0 ? '' : proy.notif}}" matBadgeColor="warn">forum</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="deleteProjectConfirm(proy)">
                                                <mat-icon>delete</mat-icon>
                                            </button>                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>