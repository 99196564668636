import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { map } from 'rxjs';
import { UsersService } from './users.service';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { StoreService } from './store.service';
import { ProjectsService } from './projects.service';
import { prototype } from 'events';

@Injectable({
  providedIn: 'root'
})
export class CutoffService {

  constructor(
    private main: MainService,
    private usrService : UsersService,
    private storesService : StoreService,
    private projectService : ProjectsService

  ) { }
  private areaNameProjects : string = '/projects'
  private areaNameStores : string = '/stores'

  current_project_camp_id = 0;

  project_cutoff_file_data : any = [];

  checkIfSameProjectThenReplace(id_proy_camp : number){

    if(this.current_project_camp_id != id_proy_camp ){
        this.loadCampaignAndFile(id_proy_camp);
    }
  }

  private getCutOffData(id_cutoff: number){
    return this.main.getRequest(this.areaNameStores + "/cuttoff/" + id_cutoff);
  }

  private loadCampaignAndFile(id_proy_camp : number) {
    this.main.ShowLoading();
    this.projectService.getProjects(id_proy_camp).subscribe((x: any) => {
      this.main.HideLoading();
  
      let selectedSucursalCorteId = x.data.project.selected_cutoff_id;
    
      if(selectedSucursalCorteId){
      
        this.getCutOffData(selectedSucursalCorteId).subscribe((y: any) =>{
          
            let fileX = y.data[0].file;
            if(fileX){
               
                this.main.ShowLoading();
                this.storesService.getCuttOffData(fileX).subscribe(
                  (result : any) => {    
                    this.main.HideLoading();
                    this.project_cutoff_file_data = result.data;
                    this.current_project_camp_id = id_proy_camp; 
                    console.log(result.data, this.current_project_camp_id);
                  },
                  (err : any) => {
                    this.main.HideLoading();
                    console.log(err);
                  }
                );

            }
      
        });
      }
    },
    (err:any) => {
      console.log(err);
          this.main.HideLoading();
    })
  }

   filtrarSucursales(filtros: any[]): any[] {
    const filtrosProcesados = filtros.map(filtro => {
      const valorNum = parseInt(filtro.valor);
      return {
        ...filtro,
        valor: isNaN(valorNum) ? filtro.valor : valorNum
      };
    });

  
    return this.project_cutoff_file_data.filter((sucs:any) => {
      return filtrosProcesados.every(filtro => {
        let atributo = sucs[filtro.nombre];
  
        if (atributo === undefined) {
          return false;
        }
        const atributoConvertido = parseFloat(atributo);
        const atributoIsNum = !isNaN(atributoConvertido);

        if (typeof filtro.valor === 'number' && atributoIsNum) {
          switch (filtro.operador) {
            case 1: // Igual
              return atributoConvertido === filtro.valor;
            case 2: // Mayor que
              return atributoConvertido > filtro.valor;
            case 3: // Menor que
              return atributoConvertido < filtro.valor;
            default:
              return false;
          }
        } else {

          if (filtro.operador === 1) {
            return atributo === filtro.valor;
          }
          return false; 
        }
      });
    });
  }

  getAtributos(){
    return this.main.getRequest(this.areaNameStores + "/attributes/list");
  }

}