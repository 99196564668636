import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectModel } from 'src/app/models/project.model';
import { CutoffService } from 'src/app/services/cutoff.service';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-diagramation-inventory',
  templateUrl: './projects-diagramation-inventory.component.html',
  styleUrls: ['./projects-diagramation-inventory.component.scss']
})
export class ProjectsDiagramationInventoryComponent implements OnInit { 

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  project: ProjectModel = new ProjectModel();
  displayedColumns: string[] = [];
  allColumns: string[] = [];
  dataSource: any = [];
  data_inv: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private projectService : ProjectsService,
    private main : MainService,
    private _cutoffS : CutoffService,
  ) {
    this.project = data.project;

    this._cutoffS.checkIfSameProjectThenReplace(data.project.id_proy_camp).then(x=>{
      this.getInventory();
    });
   }

  ngOnInit(): void {
    

  }

  getInventory(){
    this.main.ShowLoading();
    this.projectService.getProjectInvTotals(this.project.id).subscribe(
      (result:any) => {
       this.main.HideLoading();
       if(result.message == "Success") {

            let columnas_sum:any = {};
            let columnas_total:any = {};
            let arr_reglas:any = [];
            let arr_cant:any = [];
            const def_fields_stores:any = ["id", "Plaza", "FarmID", "Filial", "Nombre"];
            
            result.totales.map((config:any)=> {
              if(config.formato == 3) {
                const r = JSON.parse(config.prioridad_filtro)[0];
                r.cant_type = 4; // de por maximos
                r.pop_config = config.pop_config;
                r.id_pop_config = config.id_pc;
                arr_reglas.push(r);
               }else if(config.formato == 2) {
                    const r = JSON.parse(config.prioridad_filtro)[0];
                    r.cant_type = 3;
                    r.id_pop_config = config.id_pc;
                    r.pop_config = config.pop_config;
                    arr_reglas.push(r);
                }
                else if(!config.reglas_distribucion || config.reglas_distribucion.length == 0 || config.reglas_distribucion == "[]") {
                    const r = {cant_type: 1, multiplicador: 1, id_pop_config: config.id_pc, pop_config: config.pop_config};
                    arr_reglas.push(r);
                }
                else  {
                    const r = JSON.parse(config.reglas_distribucion)[0];
                    r.cant_type = 2;
                    r.id_pop_config = config.id_pc;
                    r.pop_config = config.pop_config;
                    arr_reglas.push(r);
                }
            });
        
            let arr_fields_stores:any = [];
            arr_reglas.map((e:any)=> {
                if(e.nombre) {
                  arr_fields_stores.push(e.nombre);
                  if(!arr_cant.some((ac:any)=> ac.atributo==e.nombre)) {
                    arr_cant.push({
                        pop_config: e.pop_config,
                        atributo: e.nombre,
                        cantidad: parseInt(e.multiplicador),
                        tipo: e.cant_type
                    });
                  }
                }
            });
            const arr_fields_totals = arr_fields_stores;
            arr_fields_stores = def_fields_stores.concat(arr_fields_stores);
            
            let filtros:any = [];
            if(result.totales.pop_sucursales_filtro) filtros = JSON.parse(result.totales.pop_sucursales_filtro);
            if(result.totales.sucursales_filtro) filtros = [ ...filtros, ...JSON.parse(result.totales.sucursales_filtro)];
            
            const storeFile:any = this._cutoffS.filtrarSucursales(filtros).map((e:any)=>{ 
                let obj_store:any = {};
                arr_fields_stores.map((key:string)=> {
                  obj_store[key]= e[key];
                });
                return obj_store;
            });

            arr_fields_stores.map((key:string)=> {
              columnas_sum[key] = this._cutoffS.project_cutoff_file_data.map((e:any)=>e[key]).filter((e:any)=>e).map((e:any)=>parseInt(e)).reduce((a:any, b:any) => a + b, 0);
            });
            
            const dt_totales:any = [];
            arr_cant.map((e:any)=> { 
                let cantidad = 0;
                let sel_data = result.data.find((rd:any)=>rd.pop==e.pop_config);
                if(e.tipo == 1) {
                  cantidad = storeFile.length*e.cantidad;
                }else if (e.tipo == 4){
                  //Se supone que e.atributo es el maximo, por lo tanto sumo el valor que trae en el archivo 
                  cantidad = storeFile.reduce((total: number, sf: any) => {
                   return total + Number(sf[e.atributo]);
                   }, 0);
                 } 
                else {
                  cantidad = columnas_sum[e.atributo]?columnas_sum[e.atributo]*e.cantidad:0;
                }
                const sel = sel_data?sel_data.cantidad:0;
                const disp = cantidad-sel;
                dt_totales.push({
                  Material: e.atributo,
                  Inventario: cantidad,
                  Disponible: disp,
                  Seleccionado: sel
                }) 
            });
            console.log(dt_totales);

            this.displayedColumns = Object.keys(dt_totales[0]).filter(key => key && key.trim() !== '');
            this.allColumns = this.displayedColumns;
            
            this.data_inv = dt_totales;
            this.dataSource = new MatTableDataSource(dt_totales);        
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
      },
      (err:any) => {
        this.main.HideLoading();
        console.log(err);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

   if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
   }
  }

  generateExcelResumen(){
    if(this.data_inv.length == 0){
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text: 'No hay datos de resumen actualmente'          
      });
    }else{
      this.main.generateExcel(this.data_inv, "Inventario" + this.project.nombre, "inv");
    }
  }

}
