import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectMedia } from 'src/app/models/project.model';
import { CutoffService } from 'src/app/services/cutoff.service';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-new-media',
  templateUrl: './new-media.component.html',
  styleUrls: ['./new-media.component.scss']
})
export class NewMediaComponent implements OnInit {
  dataSt!: any;
  idProyecto : number = 0;
  sucursalesCount = 0;
  isEdit : boolean = false;
  idMedio : number = 0;

  prioridadConf : any[] = [{id: 0, nombre: '', multiplicador : ''}]

  constructor(@Inject(MAT_DIALOG_DATA) public data : any, public dref: MatDialogRef<NewMediaComponent>,
    private _projService : ProjectsService,
    private _cutoff : CutoffService,
    private _main : MainService
  ) { }

  ngOnInit(): void {
    this.idProyecto =  this.data.id_proyecto;
    this.isEdit = this.data.isEdit;
    
    if(!this.isEdit){
      this.dataSt = {
        sucursalesVacias: false, multipleItems: false, filtroSucursales :false,
        name: '', popMaterials: [], segmentable: false, formato: ""
      }
  
    }else{

      this.dataSt = {
        sucursalesVacias: this.data.medio.sucursales_vacias , multipleItems: this.data.medio.multiples_elementos, filtroSucursales : this.data.medio.sucursal_filtro,
        name: this.data.medio.nombre, popMaterials: [], segmentable: this.data.medio.segmentable , formato: ""
      }

      this.selectedFormato = this.data.medio.formato;
      this.idMedio = this.data.medio.id

      this.prioridadConf = this.data.medio.prioridad_filtro != '' ? JSON.parse(this.data.medio.prioridad_filtro) : [{id: 0, nombre: '', multiplicador : ''}];

      this.reglasAlcance = this.data.medio.sucursales_filtro != '' ? JSON.parse(this.data.medio.sucursales_filtro) : [];

      this.calculateStores();

    }
    
    this.getAtributos();
  }

  

  //atributosSucursales = ['DANGLER', 'MATERIAL', 'ATRIBUTO X'];
  comparadores = ['Igual a', 'Mayor o igual que', 'Menor o igual que'];
  atributos : any[] = [];
  
  reglasAlcance: any[] = [];
  alcance: string = '';
  selectedFormato = 0;

  /* void close(0):
     Closes dialog. */
  close(): void { this.dref.close(); }

  /* boolean invalidInput(0):
     Validates all the field's data to enable the "Submit" button.
     > True if all fields valid. Otherwise, false. */
  invalidInput(): boolean {
    return (this.dataSt.name === '');
  }


  register(): void { 
    if(!this.data.medios.find((x:any) => x.nombre.toLowerCase().trim() ===  this.dataSt.name.toLowerCase().trim())){
      let registerData = {id_campana_proyecto : this.idProyecto, nombre : this.dataSt.name, sucursal_filtro : this.dataSt.filtroSucursales, sucursales_vacias : this.dataSt.sucursalesVacias,
        multiples_elementos : this.dataSt.sucursalesVacias, segmentable : this.dataSt.segmentable, formato : this.selectedFormato, prioridad_filtro : JSON.stringify(this.prioridadConf), sucursales_filtro : JSON.stringify(this.reglasAlcance)}
 
        Swal.fire({
         icon: 'info',
         title: 'Guardando...',
         allowEscapeKey: false,
         allowOutsideClick: false,
         showConfirmButton: false,
         didOpen: () => {
           Swal.showLoading();
         }
       });
 
       this._projService.createMedio(registerData).subscribe(
         (result:any) => {
           if(result.message == "Success") {
             Swal.fire({
               icon: 'success',
               title: 'Guardado',
               html: 'Medio guardado correctamente'
             }).then(() => {            
               this.dref.close(this.dataSt);
             });
           }
         },
         (err:any) => {
           console.log(err);
           Swal.fire({
             icon: 'error',
             title: 'Error al guardar',
             text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
           });
         }
       );
    }else{
      Swal.fire({
        icon: 'warning',
        title: 'Nombre duplicado',
        text: 'Ya existe un medio con este nombre'
      });
    }
   // this.dref.close(this.dataSt);
  }

  edit(): void { 
    if(!this.data.medios.find((x:any) => x.nombre.toLowerCase().trim() ===  this.dataSt.name.toLowerCase().trim())){
    let registerData = {id : this.idMedio, id_campana_proyecto : this.idProyecto, nombre : this.dataSt.name, sucursal_filtro : this.dataSt.filtroSucursales, sucursales_vacias : this.dataSt.sucursalesVacias,
       multiples_elementos : this.dataSt.sucursalesVacias, segmentable : this.dataSt.segmentable, formato : this.selectedFormato, prioridad_filtro : JSON.stringify(this.prioridadConf), sucursales_filtro : JSON.stringify(this.reglasAlcance)}

       Swal.fire({
        icon: 'info',
        title: 'Guardando...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this._projService.editMedios(registerData).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              html: 'Medio guardado correctamente'
            }).then(() => {            
              this.dref.close(this.dataSt);
            });
          }
        },
        (err:any) => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
          });
        }
      );
    }else{
      Swal.fire({
        icon: 'warning',
        title: 'Nombre duplicado',
        text: 'Ya existe un medio con este nombre'
      });
    }
    
   // this.dref.close(this.dataSt);
  }

  agregarRegla() {
    this.reglasAlcance.push({
      nombre: null,
      id: null,
      operador: 1,
      valor: null
    });   
  }

  getRanges(rangeSelect : any){
    return rangeSelect.split("-");
  }

  converToObject(obj: string){
    try{
      return JSON.parse(obj);
    }catch(ex){
      return [];
    }
  }

  setItemName(value : any, rule : any){
    const selectedAtributo = this.atributos.find(x => x.id === value);
    if (selectedAtributo) {
        rule.nombre = selectedAtributo.name;
        if(rule.operador){
          rule.operador = 1;
        } 
    }
  }

  getItem(atribute_id : any) : any{
    return this.atributos.filter(x=>  x.id === atribute_id)[0];
  }

  calculateStores(){
    this.sucursalesCount = this._cutoff.filtrarSucursales(this.reglasAlcance).length;
  }

  getAtributos(){
    this._main.ShowLoading();7
    this._cutoff.getAtributos().subscribe((x: any) => {
      this._main.HideLoading();

      this.atributos = x.data;

    });
  }

  eliminarRegla(index: number) {
    this.reglasAlcance.splice(index, 1);
  }

  trackByFn(index: number, item: any): number {
    return index;
  }
}
