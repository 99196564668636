import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, SafePipe } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LayoutComponent } from './components/shared/layout/layout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { CampaignAddComponent } from './components/shared/dialogs/campaign-add/campaign-add.component';
import { DistributionComponent } from './components/distribution/distribution.component';
import { SettingsComponent } from './components/settings/settings.component';
import { UsersComponent } from './components/users/users.component';
import { UsersAddComponent } from './components/shared/dialogs/users-add/users-add.component';
import { UsersEditComponent } from './components/shared/dialogs/users-edit/users-edit.component';
import { UsersEditPasswordComponent } from './components/shared/dialogs/users-edit-password/users-edit-password.component';
import { ProfileEditComponent } from './components/profile/profile-edit/profile-edit.component';
import { ProfileNotificationsComponent } from './components/profile/profile-notifications/profile-notifications.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { DistributionUploadComponent } from './components/shared/dialogs/distribution-upload/distribution-upload.component';
import { DistributionUploadFileComponent } from './components/shared/dialogs/distribution-upload-file/distribution-upload-file.component';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { DistributionArtComponent } from './components/shared/dialogs/distribution-art/distribution-art.component';
import { DistributionArtFileComponent } from './components/shared/dialogs/distribution-art-file/distribution-art-file.component';
import { DistributionPreprojectComponent } from './components/shared/dialogs/distribution-preproject/distribution-preproject.component';
import { DistributionPreprojectFileComponent } from './components/shared/dialogs/distribution-preproject-file/distribution-preproject-file.component';
import { DistributionLogsComponent } from './components/shared/dialogs/distribution-logs/distribution-logs.component';
import { CampaignLogsComponent } from './components/shared/dialogs/campaign-logs/campaign-logs.component';
import { DistributionStrategyAddComponent } from './components/shared/dialogs/distribution-strategy-add/distribution-strategy-add.component';
import { DistributionMaterialListComponent } from './components/shared/dialogs/distribution-material-list/distribution-material-list.component';
import { DistributionIrregularitiesComponent } from './components/shared/dialogs/distribution-irregularities/distribution-irregularities.component';
import { UsersEditPasswordFirsttimeComponent } from './components/shared/dialogs/users-edit-password-firsttime/users-edit-password-firsttime.component';
import { NotifEditComponent } from './components/shared/dialogs/notif-edit/notif-edit.component';
import { TemplateDesignComponent } from './components/template-design/template-design.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
//Encuestas related component modules
import { SurveysDistributionComponent } from './components/surveys/distribution/surveys-distribution.component';
import { SurveysViewComponent } from './components/surveys/surveys-view/surveys-view.component';
import { TicketDetailsComponent } from './components/tickets/ticket-details/ticket-details.component';
import { TicketListComponent } from './components/tickets/ticket-list/ticket-list.component';
import { SurveysConfirmComponent } from './components/shared/dialogs/surveys-confirm/surveys-confirm.component';
import { SurveysTicketsLogsComponent } from './components/shared/dialogs/surveys-tickets-logs/surveys-tickets-logs.component';
import { SurveyMaterialFileComponent } from './components/shared/dialogs/survey-material-file/survey-material-file.component';
import { SurveyImageSliderComponent } from './components/shared/dialogs/survey-image-slider/survey-image-slider.component';
import { SurveyMageUploadFileComponent } from './components/shared/dialogs/surveys-msg-upload-file/surveys-msg-upload-file.component';
import { SurveyImageMsgViewerComponent } from './components/shared/dialogs/survey-msg-img-viewer/survey-msg-img-viewer.component';
import { TemplatesFileViewertComponent } from './components/shared/dialogs/templates-file-viewer/templates-file-viewer.component';
import { SurveysComentaryComponent } from './components/shared/dialogs/surveys-comentary/surveys-comentary.component';
import { TicketAllListComponent } from './components/tickets/ticket-all-list/ticket-all-list.component';
//Projects related component modules
import { ProjectsAddComponent } from './components/shared/dialogs/projects-add/projects-add.component';
import { ProjectsListComponent } from './components/projects/projects-list/projects-list.component';
import { ProjectsViewComponent } from './components/projects/projects-view/projects-view.component';
import { ProjectsPromotionsAddComponent } from './components/shared/dialogs/projects-promotions-add/projects-promotions-add.component';
import { ProjectsPromotionsAddFileComponent } from './components/shared/dialogs/projects-promotions-add-file/projects-promotions-add-file.component';
import { ProjectsCampaignViewComponent } from './components/projects/projects-campaign-view/projects-campaign-view.component';
import { ProjectsCampaignCommentsAddComponent } from './components/shared/dialogs/projects-campaign-comments-add/projects-campaign-comments-add.component';
import { ProjectsCampaignFileAddComponent } from './components/shared/dialogs/projects-campaign-file-add/projects-campaign-file-add.component';
import { ProjectsProvidersListComponent } from './components/shared/dialogs/projects-providers-list/projects-providers-list.component';
import { ProjectsProvidersAddComponent } from './components/shared/dialogs/projects-providers-add/projects-providers-add.component';
import { ProjectsProvidersViewComponent } from './components/projects/projects-providers-view/projects-providers-view.component';
import { ProjectsProvidersResponseComponent } from './components/shared/dialogs/projects-providers-response/projects-providers-response.component';
import { ProjectsProvidersFormHistoryComponent } from './components/shared/dialogs/projects-providers-form-history/projects-providers-form-history.component';
import { ProjectsCampaignAddComponent } from './components/shared/dialogs/projects-campaign-add/projects-campaign-add.component';
import { ProjectsLogsComponent } from './components/shared/dialogs/projects-logs/projects-logs.component';
import { ProjectsCampaignsLogsComponent } from './components/shared/dialogs/projects-campaigns-logs/projects-campaigns-logs.component';
import { ProvidersListComponent } from './components/providers/providers-list/providers-list.component';
import { ProvidersSignUpComponent } from './components/providers/providers-sign-up/providers-sign-up.component';
import { ProvidersAddMemberComponent } from './components/shared/dialogs/providers-add-member/providers-add-member.component';
import { ProvidersEditMemberComponent } from './components/shared/dialogs/providers-edit-member/providers-edit-member.component';
import { ProvidersApprovalComponent } from './components/shared/dialogs/providers-approval/providers-approval.component';
import { ProvidersAddComponent } from './components/providers/providers-add/providers-add.component';
import { ProjectsCampaignsFileLogsComponent } from './components/shared/dialogs/projects-campaigns-file-logs/projects-campaigns-file-logs.component';
import { ProvidersProjectsComponent } from './components/providers/providers-projects/providers-projects.component';
import { ProjectsProvidersViewConceptoFileComponent } from './components/shared/dialogs/projects-providers-view-concepto-file/projects-providers-view-concepto-file.component';
import { ProjectsProvidersImgAddFileComponent } from './components/shared/dialogs/projects-providers-view-img-file/projects-providers-view-img-file.component';
import { ProjectsProvidersReloadComponent } from './components/shared/dialogs/projects-providers-reload/projects-providers-reload.component'
import { ProvidersSurveysLogsComponent } from './components/shared/dialogs/providers_surveys_logs/providers_surveys_logs.component'
import { SurveyMaterialEditComponent } from './components/shared/dialogs/survey-material-edit/survey-material-edit.component';
import { ProjectsProvidersReSendComponent } from './components/shared/dialogs/projects-providers-resend/projects-providers-resend.component';
import { StoresListComponent } from './components/stores/stores-list/stores-list.component';
import { StoresAttributesPendingListComponent } from './components/shared/dialogs/stores-attributes-pending-list/stores-attributes-pending-list.component';
import { StoresAttributesPendingListReasonComponent } from './components/shared/dialogs/stores-attributes-pending-list-reason/stores-attributes-pending-list-reason.component';
import { StoresCampaignCutoffComponent } from './components/stores/stores-campaign-cutoff/stores-campaign-cutoff.component';
import { StoresCampaignCutoffAddComponent } from './components/shared/dialogs/stores-campaign-cutoff-add/stores-campaign-cutoff-add.component';
import { StoresAttributesListComponent } from './components/stores/stores-attributes-list/stores-attributes-list.component';
import { StoresAttributesAddComponent } from './components/shared/dialogs/stores-attributes-add/stores-attributes-add.component';
import { StoresAttributesValuesListComponent } from './components/shared/dialogs/stores-attributes-values-list/stores-attributes-values-list.component';
import { StoresAttributesValuesListUploadComponent } from './components/shared/dialogs/stores-attributes-values-list-upload/stores-attributes-values-list-upload.component';
import { StoresAttributesValuesListSetallComponent } from './components/shared/dialogs/stores-attributes-values-list-setall/stores-attributes-values-list-setall.component';
import { StoresListZoneComponent } from './components/stores/stores-list-zone/stores-list-zone.component';
import { StoresAttributesEditComponent } from './components/shared/dialogs/stores-attributes-edit/stores-attributes-edit.component';
import { StoresCampaignCutoffListComponent } from './components/shared/dialogs/stores-campaign-cutoff-list/stores-campaign-cutoff-list.component';
import { StoresAttributesModalComponent } from './components/shared/dialogs/stores-attributes-modal/stores-attributes-modal.component';
import { StoresAddComponent } from './components/shared/dialogs/stores-add/stores-add.component';
import { NgxMaskModule } from 'ngx-mask';
import { GroupsComponent } from './components/segmentation/groups/groups.component';
import { GroupsCreateFromListComponent } from './components/shared/dialogs/groups-create-from-list/groups-create-from-list.component';
import { GroupsCreateFromExcelComponent } from './components/shared/dialogs/groups-create-from-excel/groups-create-from-excel.component';
import { MaterialsDistributionComponent } from './components/segmentation/distribution/distribution.component';
import { MaterialsAddToDistributionComponent } from './components/shared/dialogs/materials-add-to-distribution/materials-add-to-distribution.component';
import { MaterialsEditComponent } from './components/shared/dialogs/materials-edit/materials-edit.component';
import { MaterialsImportDistributionComponent } from './components/shared/dialogs/materials-import-distribution/materials-import-distribution.component';
import { GroupsEditComponent } from './components/shared/dialogs/groups-edit/groups-edit.component';
import { StoresCreateListUploadComponent } from './components/shared/dialogs/stores-create-upload/stores-create-upload.component';
import { AttributesRedorderComponent } from './components/shared/dialogs/attributes-reorder/attributes-reorder.component';
import { PreprojectGenerateComponent } from './components/preprojects/preproject-generate.component';
import { PrepojectStrategyAddComponent } from './components/shared/dialogs/preproject-strategy-add/preproject-strategy-add.component';
import { PreProjectsDocsAddFileComponent } from './components/shared/dialogs/preprojects-form-docs-add-file/preprojects-form-docs-add-file.component';
import { ProjectsTotalsAddComponent } from './components/shared/dialogs/preprojects-totals-add/preprojects-totals-add.component';
import { PreProjectsTotalAddFileComponent } from './components/shared/dialogs/preprojects-totals-add-file/preprojects-totals-add-file.component';
import { ProjectsPDFsAddComponent } from './components/shared/dialogs/preprojects-pdfs-add/preprojects-pdfs-add.component';
import { PreProjectsPDFsAddFileComponent } from './components/shared/dialogs/preprojects-pdfs-add-file/preprojects-pdfs-add-file.component';
import { StoreChangeLogsComponent } from './components/shared/dialogs/sucursales_changelog/sucursales_changelogs.component';
import { ProjectConfigComponent } from './components/projects/project-config/project-config.component';
import { ProjectsVoboAddComponent } from './components/shared/dialogs/projects-vobo-add/projects-vobo-add.component';
import { ProjectPopConfigComponent } from './components/projects/project-pop-config/project-pop-config.component';
import { ProjectMediaComponent } from './components/projects/project-media/project-media.component';
import { PopMaterialsComponent } from './components/projects/pop-materials/pop-materials.component';
import { NewMediaComponent } from './components/shared/dialogs/new-media/new-media.component';
import { NewPopMaterialComponent } from './components/shared/dialogs/new-pop-material/new-pop-material.component';
import { EditPopMaterialComponent } from './components/shared/dialogs/edit-pop-material/edit-pop-material.component';
import { ProjectDiagramationComponent } from './components/projects/project-diagramation/project-diagramation.component';
import { ProjectDiagramationAddComponent } from './components/shared/dialogs/project-diagramation-add/project-diagramation-add.component';
import { ProjectDiagramationVobosComponent } from './components/shared/dialogs/project-diagramation-vobos/project-diagramation-vobos.component';
import { ProjectDiagramationVobosConfigComponent } from './components/shared/dialogs/project-diagramation-vobos-config/project-diagramation-vobos-config.component';
import { ProjectsArtUploadComponent } from './components/shared/dialogs/projects-art-upload/projects-art-upload.component';
import { ProjectsDiagramationInventoryComponent } from './components/shared/dialogs/projects-diagramation-inventory/projects-diagramation-inventory.component';
import { ProjectsDiagramationDistributionComponent } from './components/shared/dialogs/projects-diagramation-distribution/projects-diagramation-distribution.component';

import { GoogleMapsModule } from '@angular/google-maps';
import { NgApexchartsModule } from 'ng-apexcharts';

import { TablesDirective } from './tables.directive';
import { DashboardTrackingComponent } from './components/dashboard-tracking/dashboard-tracking.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    DashboardComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    CampaignAddComponent,
    DistributionComponent,
    SettingsComponent,
    UsersComponent,
    UsersAddComponent,
    UsersEditComponent,
    UsersEditPasswordComponent,
    ProfileEditComponent,
    ProfileNotificationsComponent,
    LoadingComponent,
    DistributionUploadComponent,
    DistributionUploadFileComponent,
    DistributionArtComponent,
    DistributionArtFileComponent,
    DistributionPreprojectComponent,
    DistributionPreprojectFileComponent,
    DistributionLogsComponent,
    CampaignLogsComponent,
    SurveysTicketsLogsComponent,
    DistributionStrategyAddComponent,
    DistributionMaterialListComponent,
    DistributionIrregularitiesComponent,
    UsersEditPasswordFirsttimeComponent,
    NotifEditComponent,
    TemplateDesignComponent,
    SurveysDistributionComponent,
    SurveysViewComponent,
    TicketDetailsComponent, 
    TicketListComponent,
    SurveysConfirmComponent,
    ProjectsAddComponent,
    ProjectsListComponent,
    ProjectsViewComponent,
    ProjectsPromotionsAddComponent,
    ProjectsPromotionsAddFileComponent,
    ProjectsCampaignViewComponent,
    ProjectsCampaignCommentsAddComponent,
    ProjectsCampaignFileAddComponent,
    ProjectsProvidersListComponent,
    ProjectsProvidersAddComponent,
    ProjectsProvidersViewComponent,
    ProjectsProvidersResponseComponent,
    SurveyMaterialFileComponent,
    SurveyImageSliderComponent,
    ProjectsProvidersFormHistoryComponent,
    SurveyMageUploadFileComponent,
    SurveyImageMsgViewerComponent,
    TemplatesFileViewertComponent,
    ProjectsCampaignAddComponent,
    ProjectsLogsComponent,
    ProjectsCampaignsLogsComponent,
    ProvidersListComponent,
    ProvidersSignUpComponent,
    ProvidersAddMemberComponent,
    ProvidersApprovalComponent,
    ProvidersAddComponent,
    ProjectsCampaignsFileLogsComponent,
    ProvidersProjectsComponent,
    SafePipe,
    ProjectsProvidersViewConceptoFileComponent,
    ProjectsProvidersImgAddFileComponent,
    ProjectsProvidersReloadComponent,
    SurveyMaterialEditComponent,
    ProvidersSurveysLogsComponent,
    ProjectsProvidersReSendComponent,
    ProvidersEditMemberComponent,
    StoresListComponent,
    StoresAttributesPendingListComponent,
    StoresAttributesPendingListReasonComponent,
    StoresCampaignCutoffComponent,
    StoresCampaignCutoffAddComponent,
    StoresAttributesListComponent,
    StoresAttributesAddComponent,
    StoresAttributesValuesListComponent,
    StoresAttributesValuesListUploadComponent,
    StoresAttributesValuesListSetallComponent,
    StoresListZoneComponent,
    StoresAttributesEditComponent,
    StoresCampaignCutoffListComponent,
    MaterialsDistributionComponent,
    GroupsComponent,
    GroupsCreateFromListComponent,
    GroupsCreateFromExcelComponent,
    StoresAttributesModalComponent,
    StoresAddComponent,
    MaterialsAddToDistributionComponent,
    MaterialsEditComponent,
    MaterialsImportDistributionComponent,
    GroupsEditComponent,
    TablesDirective,
    SurveysComentaryComponent,
    StoresCreateListUploadComponent,
    DashboardTrackingComponent,
    AttributesRedorderComponent,
    TicketAllListComponent,
    PreprojectGenerateComponent,
    PrepojectStrategyAddComponent,
    PreProjectsDocsAddFileComponent,
    ProjectsTotalsAddComponent,
    PreProjectsTotalAddFileComponent,
    ProjectsPDFsAddComponent,
    PreProjectsPDFsAddFileComponent,
    StoreChangeLogsComponent,
    ProjectConfigComponent,
    ProjectsVoboAddComponent,
    ProjectPopConfigComponent,
    ProjectMediaComponent,
    NewMediaComponent,
    PopMaterialsComponent,
    NewPopMaterialComponent,
    EditPopMaterialComponent,
    ProjectDiagramationComponent,
    ProjectDiagramationAddComponent,
    ProjectDiagramationVobosComponent,
    ProjectDiagramationVobosConfigComponent,
    ProjectsArtUploadComponent,
    ProjectsDiagramationInventoryComponent,
    ProjectsDiagramationDistributionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxFileDragDropModule,
    NgxQRCodeModule,
    GoogleMapsModule,
    NgApexchartsModule,
    NgbCarouselModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
