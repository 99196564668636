<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">{{phase.name}}</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body" style="min-height: 580px;">       
        <mat-divider></mat-divider>
        <div>
            <div class="row mt-2">
                <div class="col-md-6">
                    <h6>
                        <mat-icon class="align-middle">update</mat-icon>
                        Última actualización: <strong  *ngIf="archivos.length>0">{{ archivos[0].created_at  | date : "dd/MM/yyyy" }} - {{ archivos[0].usuario }} </strong>
                    </h6>
                </div>
                <div class="col-md-6">
                    <div class="float-end align-middle">
                        <button mat-flat-button color="primary" class="ms-4" (click)="openDialogUploadCampaignFile()">Cargar nuevo archivo
                        <!-- <button mat-flat-button color="primary" class="ms-4" (click)="openDialogUploadCampaignFile()">Cargar nuevo archivo -->
                            <mat-icon>upload</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="col-md-12">
                    <span class="align-middle">Histórico de Archivos de Diagramación</span>
                    <div class="table-responsive text-nowrap">
                        <table class="table table-hover overflow-auto">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Archivo</th>
                                    <th>Cargado por</th>
                                    <th>Fecha de Registro</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr *ngFor="let p of archivos">
                                    <td>
                                        {{ p.id }}
                                    </td>
                                    <td>
                                        <a [href]="p.file" target="_blank">
                                           {{ p.nombre }}
                                        </a>
                                    </td>
                                    <td>
                                        {{ p.usuario }}
                                    </td>
                                    <td>
                                        {{ p.created_at | date : "dd/MM/yyyy HH:mma": "-0000"}}
                                    </td>
                                </tr>
                                <tr *ngIf="archivos.length == 0">
                                    <td colspan="6">
                                        <i>No se ha cargado ningún archivo</i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</mat-dialog-content>