import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { NotifEditComponent } from 'src/app/components/shared/dialogs/notif-edit/notif-edit.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() menuEvent = new EventEmitter<boolean>();

  userObj!: UserModel;
  roleName!: any;
  isLoading = true;  

  constructor(
    private auth: AuthService,
    private router: Router,
    private userS: UsersService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    if(this.userObj.id_provider_member == null){
      this.roleName = environment.roles.find(el => el.id === this.userObj.id_rol)?.rol;
    }else{
      this.roleName = "Proveedor"
    }

  }

  replaceSpaces(name : string){
    return name.replace(/ /g, "_"); 
  }


  menuToggle() {
    this.menuEvent.emit(true);
  }

  dialogNotif() {
    this.matDialog.open(NotifEditComponent, {
      panelClass: 'dialogs-sm'
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;       
      }
    );
  }

  logOut() {
    this.auth.logout();
    this.router.navigateByUrl('/login');
  }

}
