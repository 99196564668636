import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { POPMaterial, ProjectMedia } from 'src/app/models/project.model';
import { CutoffService } from 'src/app/services/cutoff.service';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-new-pop-material',
  templateUrl: './new-pop-material.component.html',
  styleUrls: ['./new-pop-material.component.scss']
})
export class NewPopMaterialComponent implements OnInit {
  dataR!: any;
  mattypes!: Array<string>;
  media!: any;
  reglasAlcance: any[] = [];
  alcance: string = '';
  atributosSucursales = ['DANGLER', 'MATERIAL', 'ATRIBUTO X'];
  comparadores = ['Igual a', 'Mayor o igual que', 'Menor o igual que'];
  atributos : any[] = [];

  idProyecto : number = 0;
  sucursalesCount = 0;
  isEdit : boolean = false;
  idMedio : number = 0;
  idPOP : number = 0;
  hasPosicion : boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data : any, public dref: MatDialogRef<NewPopMaterialComponent>,
    private _main :MainService, private _cutoff : CutoffService, private _projectService : ProjectsService
  ) { }

  ngOnInit(): void {
    this.media = this.data.medio;
    this.idMedio = this.data.medio.id;
    this.isEdit = this.data.isEdit;

    if(!this.isEdit){
    
      this.dataR = {
        nombre: '',
        posiciones: 0,
        prioridad: null,
      };
    }else{
      this.idPOP = this.data.pop.id;
      this.dataR = {
        nombre: this.data.pop.nombre,
        posiciones: this.data.pop.posiciones,
        prioridad: this.data.pop.prioridad,
      };
        if(this.dataR.posiciones && this.dataR.posiciones!= 0){
          this.hasPosicion = true;
        }
    }

    // { nombre, id_proyecto_medio, prioridad, posiciones, sucursales_filtro } 
    this.getAtributos();
    this.calculateStores();
  }

  close(): void { this.dref.close(); }


  invalidInput(): boolean {
    return (this.dataR.name === '' );
  }


  mirror(event: KeyboardEvent): void {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    
    if (event.key === 'ArrowUp') {
      event.preventDefault(); 
      input.value = Math.max(1, (parseInt(input.value, 10) || 0) - 1).toString(); // Arrow up subtracts
    } else if (event.key === 'ArrowDown') {
      event.preventDefault(); // Prevent default scrolling behavior.
      input.value = ((parseInt(input.value, 10) || 0) + 1).toString(); // Arrow down adds
    }

    this.dataR.prioridad = parseInt(input.value, 10);
  }


  agregarRegla() {
    this.reglasAlcance.push({
      nombre: null,
      id: null,
      operador: 1,
      valor: null
    });
  }

  eliminarRegla(index: number) {
    this.reglasAlcance.splice(index, 1);
  }

  getRanges(rangeSelect : any){
    return rangeSelect.split("-");
  }

  converToObject(obj: string){
    try{
      return JSON.parse(obj);
    }catch(ex){
      return [];
    }
  }

  setItemName(value : any, rule : any){
    const selectedAtributo = this.atributos.find(x => x.id === value);
    if (selectedAtributo) {
        rule.nombre = selectedAtributo.name;
        if(rule.operador){
          rule.operador = 1;
        } 
    }
  }

  getItem(atribute_id : any) : any{
    return this.atributos.filter(x=>  x.id === atribute_id)[0];
  }

  calculateStores(){
    let sucursalesFilter = this.reglasAlcance.concat(this.converToObject(this.media.sucursales_filtro));
    console.log(sucursalesFilter);
    this.sucursalesCount = this._cutoff.filtrarSucursales(sucursalesFilter).length;
  }

  getAtributos(){
    this._main.ShowLoading();7
    this._cutoff.getAtributos().subscribe((x: any) => {
      this._main.HideLoading();

      this.atributos = x.data.filter((x:any)=> x.type_id == 4 || x.type_id == 6 );

    });
  }

  trackByFn(index: number, item: any): number {
    return index;
  }

  register(): void {  
        // { nombre, id_proyecto_medio, prioridad, posiciones, sucursales_filtro } 
    let registerData = {id_proyecto_medio : this.idMedio, nombre : this.dataR.nombre, prioridad : this.dataR.prioridad, posiciones : this.dataR.posiciones, sucursales_filtro : JSON.stringify(this.reglasAlcance)}

      Swal.fire({
       icon: 'info',
       title: 'Guardando...',
       allowEscapeKey: false,
       allowOutsideClick: false,
       showConfirmButton: false,
       didOpen: () => {
         Swal.showLoading();
       }
     });

     this._projectService.createPOPs(registerData).subscribe(
       (result:any) => {
         if(result.message == "Success") {
           Swal.fire({
             icon: 'success',
             title: 'Guardado',
             html: 'POP guardado correctamente'
           }).then(() => {            
             this.dref.close(this.dataR);
           });
         }
       },
       (err:any) => {
         console.log(err);
         Swal.fire({
           icon: 'error',
           title: 'Error al guardar',
           text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
         });
       }
     );
    

    this.dref.close(this.dataR); 
}


edit(): void {  
  // { nombre, id_proyecto_medio, prioridad, posiciones, sucursales_filtro } 
let registerData = {id: this.idPOP, id_proyecto_medio : this.idMedio, nombre : this.dataR.nombre, prioridad : this.dataR.prioridad, posiciones : this.dataR.posiciones, sucursales_filtro : JSON.stringify(this.reglasAlcance)}

Swal.fire({
 icon: 'info',
 title: 'Guardando...',
 allowEscapeKey: false,
 allowOutsideClick: false,
 showConfirmButton: false,
 didOpen: () => {
   Swal.showLoading();
 }
});

this._projectService.editPOPs(registerData).subscribe(
 (result:any) => {
   if(result.message == "Success") {
     Swal.fire({
       icon: 'success',
       title: 'Guardado',
       html: 'POP guardado correctamente'
     }).then(() => {            
       this.dref.close(this.dataR);
     });
   }
 },
 (err:any) => {
   console.log(err);
   Swal.fire({
     icon: 'error',
     title: 'Error al guardar',
     text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
   });
 }
);


this.dref.close(this.dataR); 
}

  
}
