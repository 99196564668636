import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CutoffService } from 'src/app/services/cutoff.service';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-new-pop-config',
  templateUrl: './new-pop-config.component.html',
  styleUrls: ['./new-pop-config.component.scss']
})
export class NewPopConfigComponent implements OnInit {
  dataR!: any;

  comparadores = ['Igual a', 'Mayor o igual que', 'Menor o igual que'];
  atributos : any[] = [];
  newPOPConfig = {id: 0, id_elemento_pop : 0 , nombre: "", isQuantity: false,  precio:null, cantidad : null, reglas : []}
  popid = 0;
  mediaActual : any;
  showPrecio = true;
  showFilters = true;
  isEdit : boolean = false;

  hasPosicion : boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data : any, public dref: MatDialogRef<NewPopConfigComponent>,
    private _main :MainService, private _cutoff : CutoffService, private _projectService : ProjectsService, private _storesServices : StoreService
  ) { }

  ngOnInit(): void {

    this.isEdit = this.data.isEdit;
    this.mediaActual = this.data.mediaActual;

    if(!this.isEdit){
      this.popid = this.data.popid;
      this.dataR = this.newPOPConfig;
      this.dataR.id_elemento_pop = this.popid;
  
    }else{
      this.dataR =  this.data.config;
    }

    this.getAtributos();

  }

  close(): void { this.dref.close(); }


  invalidInput(): boolean {
    return (this.dataR.name === '' );
  }

  agregarRegla(list : any) {
    if(!list){
      list = [];
    }
    list.push({
      id: 0,
      nombre:  '',
      multiplicador: 1
    });
  }

  eliminarRegla(index: number, list :any) {
    list.splice(index, 1);
  }

  converToObject(obj: string){
    try{
      return JSON.parse(obj);
    }catch(ex){
      return [];
    }
  }

  setItemName(value : any, rule : any){
    const selectedAtributo = this.atributos.find((x:any) => x.id === value);
    if (selectedAtributo) {
        rule.nombre = selectedAtributo.name;
    }
  }

  getItem(atribute_id : any) : any{
    return this.atributos.filter(x=>  x.id === atribute_id)[0];
  }

  

  getAtributos(){
    this._main.ShowLoading();

    this._cutoff.getAtributos().subscribe((x: any) => {
      this._main.HideLoading();
      this.atributos = x.data.filter((x:any)=> x.type_id == 4 || x.type_id == 6);
    });
  }

  trackByFn(index: number, item: any): number {
    return index;
  }

    save(elemento : any){
      let registerData = { nombre : elemento.nombre, id_elemento_pop : elemento.id_elemento_pop, 
        
        reglas_distribucion : elemento.isQuantity? null : JSON.stringify(elemento.reglas), cantidad: elemento.isQuantity? elemento.cantidad : 0, precio : elemento.precio }
      
      Swal.fire({
       icon: 'info',
       title: 'Guardando...',
       allowEscapeKey: false,
       allowOutsideClick: false,
       showConfirmButton: false,
       didOpen: () => {
         Swal.showLoading();
       }
     });

     this._projectService.createPOPConfig(registerData).subscribe(
       (result:any) => {
         if(result.message == "Success") {
           Swal.fire({
             icon: 'success',
             title: 'Guardado',
             html: 'POP guardado correctamente'
           }).then(() => {            
           
           });
         }
       },
       (err:any) => {
         console.log(err);
         Swal.fire({
           icon: 'error',
           title: 'Error al guardar',
           text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
         });
       }
     );

    }

    edit(elemento : any){
      
      let registerData = {id: elemento.id, nombre : elemento.nombre, id_elemento_pop : elemento.id_elemento_pop,
        reglas_distribucion : elemento.isQuantity? null : JSON.stringify(elemento.reglas), cantidad: elemento.isQuantity? elemento.cantidad : 0, precio : elemento.precio }
      
      Swal.fire({
       icon: 'info',
       title: 'Guardando...',
       allowEscapeKey: false,
       allowOutsideClick: false,
       showConfirmButton: false,
       didOpen: () => {
         Swal.showLoading();
       }
     });

     this._projectService.editPOPConfig(registerData).subscribe(
       (result:any) => {
         if(result.message == "Success") {
           Swal.fire({
             icon: 'success',
             title: 'Guardado',
             html: 'Configuracion de POP guardada correctamente'
           }).then(() => {            
            this.close();
           });
         }
       },
       (err:any) => {
         console.log(err);
         Swal.fire({
           icon: 'error',
           title: 'Error al guardar',
           text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
         });
       }
     );

    }
    totales : Array<any> = [];
    loadedInventorys = false;

    getInventory(){
      this.loadedInventorys = false;
      this._projectService.getProjectInvTotals(this.mediaActual.id_campana_proyecto).subscribe(
        (result:any) => {
          this.loadedInventorys = true;
         if(result.message == "Success") {
  
              let columnas_sum:any = {};
              //let columnas_total:any = {};
              let arr_reglas:any = [];
              let arr_cant:any = [];
              const def_fields_stores:any = ["id", "Plaza", "FarmID", "Filial", "Nombre"];
              
              result.totales.map((config:any)=> {
                  if(config.formato == 2) {
                      const r = JSON.parse(config.prioridad_filtro)[0];
                      r.cant_type = 3;
                      r.id_pop_config = config.id_pc;
                      r.pop_config = config.pop_config;
                      arr_reglas.push(r);
                  }
                  else if(!config.reglas_distribucion) {
                      const r = {cant_type: 1, multiplicador: 1, id_pop_config: config.id_pc, pop_config: config.pop_config};
                      arr_reglas.push(r);
                  }
                  else  {
                      const r = JSON.parse(config.reglas_distribucion)[0];
                      r.cant_type = 2;
                      r.id_pop_config = config.id_pc;
                      r.pop_config = config.pop_config;
                      arr_reglas.push(r);
                  }
              });
          
              let arr_fields_stores:any = [];
              arr_reglas.map((e:any)=> {
                  if(e.nombre) {
                    arr_fields_stores.push(e.nombre);
                    if(!arr_cant.some((ac:any)=> ac.atributo==e.nombre)) {
                      arr_cant.push({
                          id : e.id,
                          pop_config: e.pop_config,
                          atributo: e.nombre,
                          cantidad: parseInt(e.multiplicador),
                          tipo: e.cant_type
                      });
                    }
                  }
              });
              //const arr_fields_totals = arr_fields_stores;
              arr_fields_stores = def_fields_stores.concat(arr_fields_stores);
              
              let filtros:any = [];
              if(result.totales.pop_sucursales_filtro) filtros = JSON.parse(result.totales.pop_sucursales_filtro);
              if(result.totales.sucursales_filtro) filtros = [ ...filtros, ...JSON.parse(result.totales.sucursales_filtro)];
              
              const storeFile:any = this._cutoff.filtrarSucursales(filtros).map((e:any)=>{ 
                  let obj_store:any = {};
                  arr_fields_stores.map((key:string)=> {
                    obj_store[key]= e[key];
                  });
                  return obj_store;
              });
  
              arr_fields_stores.map((key:string)=> {
                columnas_sum[key] = this._cutoff.project_cutoff_file_data.map((e:any)=>e[key]).filter((e:any)=>e).map((e:any)=>parseInt(e)).reduce((a:any, b:any) => a + b, 0);
              });
              
              const dt_totales:any = [];
              arr_cant.map((e:any)=> { 
                  let cantidad = 0;
                  let sel_data = result.data.find((rd:any)=>rd.pop==e.pop_config);
                  if(e.cant_type == 1) {
                    cantidad = storeFile.length*e.cantidad;
                  }
                  else {
                    cantidad = columnas_sum[e.atributo]?columnas_sum[e.atributo]*e.cantidad:0;
                  }
                  const sel = sel_data?sel_data.cantidad:0;
                  const disp = cantidad-sel;
                  dt_totales.push({
                    id_atributo : e.id,
                    atributo: e.atributo,
                    inventario: cantidad,
                    disponible: disp,
                    seleccionado: sel
                  }) 
              });
              console.log(dt_totales);
              this.totales = dt_totales;
            }
        },
        (err:any) => {
          this.loadedInventorys = true;
          console.log(err);
        }
      );
    }

    findInventoryItem(id: number){
      if(this.loadedInventorys){
        try{
          return this.totales.filter((x:any)=> x.id_atributo == id )[0].inventario;
        }catch(ex){
          return "N/A"
        }

      }else{
        return 0;
      }
    }

  
}
