import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectsCampaignFileAddComponent } from '../../dialogs/projects-campaign-file-add/projects-campaign-file-add.component';
import { MainService } from 'src/app/services/main.service';
import { ProjectsCampaignCommentsAddComponent } from '../projects-campaign-comments-add/projects-campaign-comments-add.component';
import { ProjectModel, ProjectPhase } from 'src/app/models/project.model';
import { ProjectsService } from 'src/app/services/projects.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-projects-art-upload',
  templateUrl: './projects-art-upload.component.html',
  styleUrls: ['./projects-art-upload.component.scss']
})
export class ProjectsArtUploadComponent implements OnInit {
  curr_diagram!: any;
  phase!: ProjectPhase;
  project: ProjectModel = new ProjectModel();
  archivos: any = [];
  next_id: number = 0;
  projectConf : any;
  arr_order : any = [];
  next_vobo : any;
  usersUpload : any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    public dialogRef: MatDialogRef<ProjectsArtUploadComponent>,
    private projectsS: ProjectsService,
    private matDialog: MatDialog,
    private main : MainService,
    private users : UsersService) { 
      this.curr_diagram = data.diagram;
      this.phase = data.phase;
      this.project = data.project;
      this.arr_order = data.arr_order;
      this.projectConf = data.projectConf[0];
    }

  ngOnInit() {
    const next = this.curr_diagram.config_vobos.find((e:any)=>e.vobos.some((v:any)=>(v.status?v.status:0)==0));
    if(next){
      this.next_id = next.id;
    }
    this.getArchivosByPhase();
    this.calculateNextVobo();
    try{
      this.usersUpload = JSON.parse(this.projectConf.users_upload)
    }catch(ex){}
  }

  hasPermisions() : boolean{
    return this.users.isBuhoUsr() || this.usersUpload.includes(this.users.getUser().id);
  }

  currentAreaVobo : number = 0;

  calculateNextVobo(){
    let indexActual = this.arr_order
    .map((item:any, index:number) => ({ item, index }))
    .filter(({ item } : any) => item.id_fase === this.phase.id)
    .map(({ index } : any) => index);
    console.log("index actual", indexActual, this.arr_order.length)
    if((indexActual[0] + 1) < this.arr_order.length){
      let next_ax = this.arr_order[indexActual[0] + 1];
      let usr_aux : any[] = [];
      let arts_users : any = [];
      switch (next_ax.tipo) {
        case "art":
          try{
            usr_aux = JSON.parse(this.projectConf.users_upload)
          }catch(ex){}
            this.next_vobo = {tipo : "art", users : usr_aux, arts_users: usr_aux };
          break;
        case "vobo":
          try{
            arts_users = JSON.parse(this.projectConf.users_upload)
            this.currentAreaVobo =  next_ax.id_vobo;
            usr_aux = this.curr_diagram.config_vobos.filter((f:any)=> f.id == next_ax.id_fase)[0].vobos.filter((v:any)=> v.id == next_ax.id_vobo)[0].users;
          }catch(ex){}
            this.next_vobo = {tipo : "vobo", users : usr_aux, arts_users : arts_users};
          break;
        case "prov":
          try{
            this.currentAreaVobo =  next_ax.id_vobo;
            usr_aux = this.curr_diagram.config_vobos.filter((f:any)=> f.id == next_ax.id_fase)[0].vobos.filter((v:any)=> v.id == next_ax.id_vobo)[0].users;
          }catch(ex){}
            this.next_vobo = {tipo : "prov", users : usr_aux, arts_users : arts_users};
          break;
        default:
          break;
      }
      console.log("next vobo", this.next_vobo);
    }
  }

  openDialogUploadCampaignFile() {
    this.matDialog.open(ProjectsCampaignFileAddComponent, {
      panelClass: ['dialogs-main'],
      data: {
        phase_id : this.phase.id,
        diagram : this.curr_diagram,
        folder : this.project.folder,
        next_vobo : this.next_vobo,
        vobo_id : this.currentAreaVobo
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.dialogRef.close({ reload: true });
        }
      }
    );
  }


  openDialogCommentNoAuth(phase_id : number, rol_id : number) {
    this.matDialog.open(ProjectsCampaignCommentsAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
          phase_id : phase_id,
          rol_id : rol_id,
          camp_id : 1,
          nombre : "DANGLERS VERSION 1 - FASE 1"
        }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  getArchivosByPhase() {
    this.main.ShowLoading();
    const data = {
        id_diagrama: this.curr_diagram.id,
        id_fase: this.phase.id
    }
    this.projectsS.getdiagramFiles(data).subscribe((x: any) => {
      this.archivos = x.data;
      this.main.HideLoading();
    },
    (err:any) => {
      console.log(err);
          this.main.HideLoading();
    })
  }
}
