import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectDiagramationVobosConfigComponent } from '../project-diagramation-vobos-config/project-diagramation-vobos-config.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import { CutoffService } from 'src/app/services/cutoff.service';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectVobo } from 'src/app/models/project.model';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-project-diagramation-add',
  templateUrl: './project-diagramation-add.component.html',
  styleUrls: ['./project-diagramation-add.component.scss']
})
export class ProjectDiagramationAddComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild("paginatorSuc") paginatorSuc!: MatPaginator;
  @ViewChild("sortSuc") sortSuc!: MatSort;
  @ViewChild('stepper') myStepper!: MatStepper;
  
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  displayedColumns: string[] = [];
  allColumns: string[] = [];
  displayedColumnsStore: string[] = [];
  allColumnsStore: string[] = [];
  id_proy: number = 0;
  id_camp: number = 0;
  medios: any = [];
  elementos: any = [];
  materiales_dist: any = [];
  hasPositions: boolean = false;
  min_pos: number = 1;
  max_pos: number = 1;
  promotionFile: any = [];
  dspromotionFile: any = [];
  dsstoreFile: any = [];
  storeFile: any = [];
  stores_sel: any = [];
  pop_sel!: any;
  medio_sel!: any;
  config_sel!: any;
  cant_sel: number = 0;
  position_sel: number = 1;
  def_fields_stores: any = ["id", "Plaza", "FarmID", "Filial", "Nombre"];
  attr_cant!: string;
  arr_cant: any = [];
  cantidad_total: number = 0;
  columnas_total: any = {};
  reglas: any = {};
  arr_reglas: any = [];
  cant_regla: number = 1;
  cant_type!: number; //1 - cantidad fija, 2 - atributo, 3 - prioridad,   
  phases!: any;
  vobos_sel: any = [];
  curr_diagram!: any;
  curr_diagram_sim!: any;
  diagram_sim_pop!: any;
  same_stores: boolean = false;
  edit: boolean = false;
  config_positions: any = [];
  projectDiagramations: any= new Array<ProjectVobo>();
  segmentable: boolean = true;
  dt_totales:any = [];
  loaded : boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    public dialogRef: MatDialogRef<ProjectDiagramationAddComponent>,
    private fb: FormBuilder,  
    private _router: Router,  
    private projectsS: ProjectsService,
    private main: MainService,
    private _cutoffS : CutoffService,
    private matDialog: MatDialog,) { 
      this.projectDiagramations = data.data;
      this.id_proy = data.id_proy;
      this.id_camp = data.projectData.id_proy_camp;
      this.phases = data.phases;
      this.config_positions = data.config_positions;
      this.curr_diagram = data.curr_diagram;
      this.curr_diagram_sim = this.projectDiagramations.filter((e:any)=>e.id_medio==this.curr_diagram.id_medio&&e.id_pop==this.curr_diagram.id_pop&&e.posicion==this.curr_diagram.posicion);
      if(this.curr_diagram) this.edit = true;
      this._cutoffS.checkIfSameProjectThenReplace(this.id_camp).then(x=>{
        this.loaded = true;
      });
      console.log("diagramacion",this.curr_diagram_sim, this.config_positions );
    }

  poplist: Array<any> = [
    { id: 1, media_id: 1, title: 'Dangler', info: [['Posiciones', '-'], ['Prioridad', '-']], rules: []},
  ];

  selectedPromotions: string[] = [];  // Lista de códigos seleccionados
  selectedStores: any[] = [];  // Lista de sucursales seleccionados

  ngOnInit(): void {
    this.firstFormGroup = this.fb.group({
      medio: ['', Validators.required],
      elemento_pop : ['', Validators.required],
      position: ['1', Validators.required],//pendiente agregar validacion de position con customValidPosition
    });
    this.secondFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required],
    });
    this.getMedios();
    this.getPromotions();
  }

  getMedios() {
    this.main.ShowLoading();
    //this.hasPositions = false;
    this.projectsS.getMedios(this.id_proy).subscribe((x: any) => {
      this.medios = x.data.medios;
      this.main.HideLoading();
      if(this.edit) { 
        this.medioSelected(this.curr_diagram.id_medio);
      }
      //this.loaded = true;
    },
    (err:any) => {
      console.log(err);
          this.main.HideLoading();
    })
  }

  getPops(id_medio: number) {
    this.main.ShowLoading();
    //this.hasPositions = false;
    this.projectsS.getPOPs(id_medio).subscribe((x: any) => {
      this.elementos = x.data.elementos_pop;
      this.main.HideLoading();
      if(this.edit) { 
        this.popSelected(this.curr_diagram.id_pop);
      }
      //this.loaded = true;
    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }


  
  getPromotions() {
    this.main.ShowLoading();
    this.projectsS.getPromotionData(this.id_camp).subscribe((x: any) => {
      this.promotionFile = x.data;
      this.dspromotionFile = new MatTableDataSource(x.data);
      this.dspromotionFile.paginator = this.paginator;
      this.dspromotionFile.sort = this.sort;
      this.displayedColumns = Object.keys(this.promotionFile[0]).filter(key => key && key.trim() !== '');
      this.allColumns = ['select', ...this.displayedColumns];
      this.main.HideLoading(); 
      this.getMundos()
      this.setPromotionsFilter();
    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }

  getInventory(){
    this.main.ShowLoading();
    this.projectsS.getProjectInvTotals(this.id_proy).subscribe(
      (result:any) => {
       this.main.HideLoading();
       if(result.message == "Success") {

            let columnas_sum:any = {};
            let columnas_total:any = {};
            let arr_reglas:any = [];
            let arr_cant:any = [];
            const def_fields_stores:any = ["id", "Plaza", "FarmID", "Filial", "Nombre"];
            console.log("pure data",result);
            result.totales.filter((e:any)=>e.id_medio==this.medio_sel.id).map((config:any)=> {
                if(config.formato == 2) {
                    const r = JSON.parse(config.prioridad_filtro)[0];
                    r.cant_type = 3;
                    r.id_pop_config = config.id_pc;
                    r.pop_config = config.pop_config;
                    arr_reglas.push(r);
                }
                else if(!config.reglas_distribucion) {
                    const r = {cant_type: 1, multiplicador: 1, id_pop_config: config.id_pc, pop_config: config.pop_config};
                    arr_reglas.push(r);
                }
                else  {
                    const r = JSON.parse(config.reglas_distribucion)[0];
                    r.cant_type = 2;
                    r.id_pop_config = config.id_pc;
                    r.pop_config = config.pop_config;
                    arr_reglas.push(r);
                }
            });
        
            let arr_fields_stores:any = [];
            arr_reglas.map((e:any)=> {
                if(e.nombre) {
                  arr_fields_stores.push(e.nombre);
                  if(!arr_cant.some((ac:any)=> ac.atributo==e.nombre)) {
                    arr_cant.push({
                        pop_config: e.pop_config,
                        atributo: e.nombre,
                        cantidad: parseInt(e.multiplicador),
                        tipo: e.cant_type
                    });
                  }
                }
            });
            const arr_fields_totals = arr_fields_stores;
            arr_fields_stores = def_fields_stores.concat(arr_fields_stores);
            
            let filtros:any = [];
            if(result.totales.pop_sucursales_filtro) filtros = JSON.parse(result.totales.pop_sucursales_filtro);
            if(result.totales.sucursales_filtro) filtros = [ ...filtros, ...JSON.parse(result.totales.sucursales_filtro)];
            
            const storeFile:any = this._cutoffS.filtrarSucursales(filtros).map((e:any)=>{ 
                let obj_store:any = {};
                arr_fields_stores.map((key:string)=> {
                  obj_store[key]= e[key];
                });
                return obj_store;
            });

            arr_fields_stores.map((key:string)=> {
              columnas_sum[key] = this._cutoffS.project_cutoff_file_data.map((e:any)=>e[key]).filter((e:any)=>e).map((e:any)=>parseInt(e)).reduce((a:any, b:any) => a + b, 0);
            });
        
            arr_cant.map((e:any)=> { 
                let cantidad = 0;
                let sel_data = result.data.find((rd:any)=>rd.pop==e.pop_config);
                if(e.cant_type == 1) {
                  cantidad = storeFile.length*e.cantidad;
                }
                else {
                  cantidad = columnas_sum[e.atributo]?columnas_sum[e.atributo]*e.cantidad:0;
                }
                const sel = sel_data?sel_data.cantidad:0;
                const disp = cantidad-sel;
                this.dt_totales.push({
                  atributo: e.atributo,
                  inventario: cantidad,
                  disponible: disp,
                  seleccionado: sel
                }) 
            });

            this.dt_totales = Array.from(
              new Map(this.dt_totales.map((item: any) => [item.atributo, item])).values()
            );

            //sumo para poder hacer el juego de calcular "en vivo" estos totales
             this.dt_totales.forEach((total : any) => {
              total.disponible = total.disponible + this.findMaterialTotal(total.atributo);
            });

          
          }
      },
      (err:any) => {
        this.main.HideLoading();
        console.log(err);
      }
    );
  }

  getPositions(el_pops: any) {
    const sel_pops = this.elementos.find((e:any)=>e.id == el_pops);

    if(sel_pops?.posiciones) {
      this.max_pos = sel_pops.posiciones;
      this.hasPositions = true;
    }
    else {
      this.max_pos = 1;
      this.hasPositions = false;
    }
  }
  
  medioSelected(id_media: number) {
    this.materiales_dist = [];
    this.medio_sel = this.medios.find((e:any)=>e.id==id_media);
    this.getPops(id_media);
  }

  popSelected(el_pops: any) {
    this.main.ShowLoading();
    this.pop_sel = this.elementos.find((e:any)=>e.id==el_pops);
    this.segmentable = this.medio_sel.segmentable==1;
    this.diagram_sim_pop = this.projectDiagramations.filter((e:any)=>e.id_medio==this.medio_sel.id&&e.id_pop==el_pops);
    if(this.diagram_sim_pop.length > 0) this.same_stores = true;
    else this.same_stores = false;
    this.getPositions(el_pops);
    this.projectsS.getPOPConfigs(el_pops).subscribe((x: any) => {
      this.materiales_dist = x.data.configs;
      if(this.edit) { 
        this.setData();
      }
      this.main.HideLoading(); 
    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }

  positionSelected(pos: any) {
    this.position_sel = parseInt(pos.target.value);
  }

  configSelected() {
    const columnas_sum:any = {};
    this.arr_reglas = [];
    
      this.materiales_dist.map((config:any)=> {
        const config_sel = config;
        if(this.medio_sel.formato == 2) {
          const r = JSON.parse(this.medio_sel.prioridad_filtro)[0];
          r.cant_type = 3;
          r.id_pop_config = config_sel.id;
          this.arr_reglas.push(r);
        }
        else if(!config_sel.reglas_distribucion) {
          const r = {cant_type: 1, multiplicador: this.cant_regla, id_pop_config: config_sel.id};
          this.arr_reglas.push(r);
        }
        else  {
          const r = JSON.parse(config_sel.reglas_distribucion)[0];
          r.cant_type = 2;
          r.id_pop_config = config_sel.id;
          this.arr_reglas.push(r);
        }
      });
    

    let arr_fields_stores:any = [];
    this.arr_reglas.map((e:any)=> {
      if(e.nombre) {
        arr_fields_stores.push(e.nombre);
        this.arr_cant.push({
          id_pc: e.id_pop_config,
          atributo: e.nombre,
          cantidad: e.multiplicador,
          tipo: e.cant_type
        });
      }
    });
    const arr_fields_totals = arr_fields_stores;
    arr_fields_stores = this.def_fields_stores.concat(arr_fields_stores);
    
    let filtros: any = [];
    if(this.pop_sel.sucursales_filtro) filtros = JSON.parse(this.pop_sel.sucursales_filtro);
    if(this.medio_sel.sucursales_filtro) filtros = [ ...filtros, ...JSON.parse(this.medio_sel.sucursales_filtro)];
    this.storeFile = this._cutoffS.filtrarSucursales(filtros).map((e:any)=>{ 
      let obj_store:any = {};
      arr_fields_stores.map((key:string)=> {
        if(arr_fields_totals.includes(key)) {
          columnas_sum[key] = (columnas_sum[key]?columnas_sum[key]:0) + (e[key]?parseInt(e[key]):0);
        }
        obj_store[key]= e[key];
      });
      return obj_store;
    });

    

    if (this.storeFile.length > 0) {
      this.dsstoreFile = new MatTableDataSource(this.storeFile);
      this.dsstoreFile.paginator = this.paginatorSuc;
      this.dsstoreFile.sort = this.sortSuc;

      this.setSucursalFilter();
      this.getPlazas();

      this.displayedColumnsStore = Object.keys(this.storeFile[0]).filter(key => key && !key.trim().includes("id"));
      this.allColumnsStore = ['select', ...this.displayedColumnsStore];
    }

    this.arr_cant.map((e:any)=> { 
      let cantidad = 0;
      if(e.cant_type == 1) {
        this.columnas_total[e.atributo] = this.storeFile.length*e.cantidad;
      }
      else { 
        this.columnas_total[e.atributo] = columnas_sum[e.atributo]?columnas_sum[e.atributo]*e.cantidad:0;
      }
    });
  }
  
  setData() {
      const curr_data = { 
        medio: this.curr_diagram.id_medio, 
        elemento_pop: this.curr_diagram.id_pop,
        position: this.curr_diagram.posicion
      };

      this.firstFormGroup.setValue(curr_data);
      this.selectedPromotions = this.curr_diagram.promociones;
  }

  customValidPosition(control: FormControl): void {
    const positions = this.diagram_sim_pop.map((e:any)=>e.posicion);
    if(positions.includes(this.position_sel)) {
      setTimeout(() => {
          control.setErrors({ myCustomError: true });
      });
    }
  }

  setSecondData() {
    this.getInventory();
    const positions = this.diagram_sim_pop.map((e:any)=>e.posicion);
    if(this.pop_sel.posiciones>0&&!this.edit&&positions.includes(this.position_sel)) {
      Swal.fire({
        icon: 'warning',
        title: 'Datos incorrectos',
        text: 'No se puede continuar con los datos seleccionados.'
      });
      this.myStepper.selectedIndex = 0;
    }
    else {
      this.configSelected();
      if(!this.segmentable) {
        this.toggleSelectionStore(0,{ target: { checked: true }});
      }
      else if(this.same_stores) {
        this.stores_sel = this.diagram_sim_pop[0].pop_config.map((e:any)=> Object.assign({ id_pc: e.id_pop_config, sucursales: e.sucursales, total: e.cantidad }));
        this.selectedStores = this.diagram_sim_pop[0].pop_config.map((e:any)=>e.sucursales.map((s:any)=>s.id)).flat().filter(this.main.onlyUnique);
      }
      else {
        this.stores_sel = [];
        this.selectedStores = [];
      }
      if(this.edit) { 
        this.vobos_sel = this.curr_diagram.config_vobos;
        this.phases.map((e:any)=> {
          const obj_vobo = this.vobos_sel.some((v:any)=>v.id==e.id);
          if(obj_vobo) e.selected = true;
        });
        
        this.stores_sel = this.curr_diagram_sim[0].pop_config.map((e:any)=> Object.assign({ id_diagrama: e.id, id_pc: e.id_pop_config, sucursales: e.sucursales, total: e.cantidad }));
        this.selectedStores = this.curr_diagram_sim[0].pop_config.map((e:any)=>e.sucursales.map((s:any)=>s.id)).flat().filter(this.main.onlyUnique);
        this.preLoadMaterialInfo();
      }
    }
  }


  codigoFilter : string = "";
  mundoFilter : any = "";
  plazasFilter : any = "";

  mundosList : Array<any> = [];
  plazasList : Array<any> = [];

  getMundos(){
    let mundos = this.promotionFile.map((item : any) => item.MUNDO);
    this.mundosList = [...new Set(mundos)];
  }

  getPlazas(){
    let plaza = this.storeFile.map((item : any) => item.Plaza);
    this.plazasList = [...new Set(plaza)];
  }

  setPromotionsFilter(){
    this.dspromotionFile = new MatTableDataSource(this.promotionFile);
    this.dspromotionFile.paginator = this.paginator;
    this.dspromotionFile.sort = this.sort;
  }

  setSucursalFilter(){
    this.dsstoreFile = new MatTableDataSource(this.storeFile);
    this.dsstoreFile.paginator = this.paginatorSuc;
    this.dsstoreFile.sort = this.sortSuc;
  }

  filterPlaza(event : any){
    this.setSucursalFilter()
    this.plazasFilter = event;
    if(event != ""){
      let storeFileFiltered = [];
      storeFileFiltered = this.storeFile.filter((x:any)=> x.Plaza.toString() ==  this.plazasFilter);
      this.dsstoreFile = new MatTableDataSource(storeFileFiltered);
      this.dsstoreFile.paginator = this.paginatorSuc;
      this.dsstoreFile.sort = this.sortSuc;
    }
  }
  
  filtrarPromotions(){
      this.setPromotionsFilter();
      //let promotionFileFiltered = [];
      //promotionFileFiltered = this.promotionFile.filter((x:any)=> x["CODIGO PRINCIPAL"].toString().includes(this.codigoFilter));
      //this.dspromotionFile = new MatTableDataSource(promotionFileFiltered);
  }

  toggleSelection(codigo: string): void {
    const index = this.selectedPromotions.indexOf(codigo);
    if (index > -1) {
      this.selectedPromotions.splice(index, 1);  
    } else {
      this.selectedPromotions.push(codigo); 
    }
  }

  selectAll(event: any): void {
    if (event.target.checked) {
      this.selectedPromotions = this.dspromotionFile.data.map((promo:any) => promo['CODIGO PRINCIPAL']);  
    } else {
      this.selectedPromotions = []; 
    }
  }

  toggleSelectionStore(id: number, event: any): void {
    let index = 0;
    this.stores_sel = [];
    if(id>0) {
      index = this.selectedStores.indexOf(id);
      if (index > -1) {
        this.selectedStores.splice(index, 1);
      } else {
        this.selectedStores.push(id); 
      }
    }
    else {
        if (event.target.checked) {
          if(this.plazasFilter != ""){
            let nuevosIds = this.storeFile
          .filter((x: any) => x.Plaza.toString() === this.plazasFilter) 
          .map((store: any) => store['id']); 
          nuevosIds.forEach((id: any) => {
            if (!this.selectedStores.includes(id)) {
            this.selectedStores.push(id); 
           }
          });
          }else{
             this.selectedStores = this.storeFile.map((store:any) => store['id']);
          }
         
        } else {
          if(this.plazasFilter!= ""){
            let idUnChecked = this.storeFile
            .filter((x: any) => x.Plaza.toString() === this.plazasFilter) 
            .map((store: any) => store['id']); 
             this.selectedStores = this.selectedStores.filter((id: any) => !idUnChecked.includes(id));
          }else{
                  this.selectedStores = [];
          }
    
        }
    }

    let columnas_total_sel:any = {};
    const sel_sf = this.storeFile.filter((e:any)=> this.selectedStores.includes(e.id));
    this.arr_cant.map((e:any)=> {
      const ob_pc: any = { id_pc: e.id_pc, sucursales: [], total: 0, atributo: e.atributo }
      const get_diagram = this.curr_diagram_sim.find((d:any)=>d.id_pop_config==e.id_pc);
      if(get_diagram) {
        ob_pc.id_diagrama = get_diagram.id;
      }
      
      sel_sf.map((sf:any)=> {
        let cantidad = 0;
        if(e.tipo == 1) {
          cantidad = e.cantidad;
        }
        else if(e.tipo == 2) { 
          cantidad = (sf[e.atributo]?parseInt(sf[e.atributo]):0)*e.cantidad;
        }
        else if(e.tipo == 3) { 
          const cant_pops = this.elementos.length;
          const cant_total_suc = (sf[e.atributo]?parseInt(sf[e.atributo]):0)*e.cantidad;
          const modulo = cant_total_suc%cant_pops;
          const max = Math.ceil(cant_total_suc/cant_pops);
          const min = Math.floor(cant_total_suc/cant_pops);
          const prioridad = this.pop_sel.prioridad;
          cantidad = prioridad<=modulo?max:min;
        }
        //this.cant_sel += cantidad;
        columnas_total_sel[e.attributo] += cantidad;

        if(cantidad > 0) {
          ob_pc.sucursales.push({id: sf.id, cantidad});
          ob_pc.total += cantidad;
        }
      });

      if(ob_pc.sucursales.length > 0) {
        this.stores_sel.push(ob_pc);
      }
    });
    if (id>0) {
      index = this.selectedStores.indexOf(id);
      if(!this.stores_sel.some((s:any)=>s.sucursales.some((suc:any)=>suc.id==id))) {
        this.selectedStores.splice(index, 1);
      }
    }
    this.stores_sel = Array.from(
      new Map(this.stores_sel.map((item: any) => [item.atributo, item])).values()
    );
    this.selectedStores = this.stores_sel.map((e:any)=>e.sucursales.map((s:any)=>s.id)).flat().filter(this.main.onlyUnique);

    console.log(this.columnas_total);//inventario total
    console.log(this.stores_sel);//seleccionado
  }
  preloaded = false;
  preLoadMaterialInfo(){
      this.stores_sel = [];
      let columnas_total_sel:any = {};
      const sel_sf = this.storeFile.filter((e:any)=> this.selectedStores.includes(e.id));
      this.arr_cant.map((e:any)=> {
        const ob_pc: any = { id_pc: e.id_pc, sucursales: [], total: 0, atributo: e.atributo }
        const get_diagram = this.curr_diagram_sim.find((d:any)=>d.id_pop_config==e.id_pc);
        if(get_diagram) {
          ob_pc.id_diagrama = get_diagram.id;
        }
        
        sel_sf.map((sf:any)=> {
          let cantidad = 0;
          if(e.tipo == 1) {
            cantidad = e.cantidad;
          }
          else if(e.tipo == 2) { 
            cantidad = (sf[e.atributo]?parseInt(sf[e.atributo]):0)*e.cantidad;
          }
          else if(e.tipo == 3) { 
            const cant_pops = this.elementos.length;
            const cant_total_suc = (sf[e.atributo]?parseInt(sf[e.atributo]):0)*e.cantidad;
            const modulo = cant_total_suc%cant_pops;
            const max = Math.ceil(cant_total_suc/cant_pops);
            const min = Math.floor(cant_total_suc/cant_pops);
            const prioridad = this.pop_sel.prioridad;
            cantidad = prioridad<=modulo?max:min;
          }
          //this.cant_sel += cantidad;
          columnas_total_sel[e.attributo] += cantidad;
  
          if(cantidad > 0) {
            ob_pc.sucursales.push({id: sf.id, cantidad});
            ob_pc.total += cantidad;
          }
        });
  
        if(ob_pc.sucursales.length > 0) {
          this.stores_sel.push(ob_pc);
        }
      });
      this.selectedStores = this.stores_sel.map((e:any)=>e.sucursales.map((s:any)=>s.id)).flat().filter(this.main.onlyUnique);
      this.stores_sel = Array.from(
        new Map(this.stores_sel.map((item: any) => [item.atributo, item])).values()
      );
      console.log("totales",this.columnas_total);//inventario total
      console.log("seleccionado", this.stores_sel);//seleccionado
      this.preloaded = true;
  }

  setColumnasTotalArray(): { material: string; total: number }[] {
    return Object.entries(this.columnas_total).map(([key, value]) => ({
      material: key,
      total: Number(value)
    }));
  }


  findMaterialTotal(material_key : any) : number{
    return this.stores_sel.filter((x:any)=> x.atributo === material_key ).length > 0 ? this.stores_sel.filter((x:any)=> x.atributo === material_key )[0].total : 0;
  }

  openVobosDiagramConfigDialog(phase: any) {
    const obj_vobo = this.vobos_sel.find((e:any)=>e.id==phase.id);
    this.matDialog.open(ProjectDiagramationVobosConfigComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        edit : true,
        phase,
        sel_vobos: obj_vobo 
      }
    }).afterClosed().subscribe(
      (resp) => {
        if(resp) {
          this.setVobo(phase.id, resp);
        }
      }
    );
  }

  setVobo(id: number,v: any) {
    let res_obj:any = {};
    res_obj.id = id;
    res_obj.vobos = [];
    const obj_phase = this.phases.find((e:any)=> e.id==id);
    for (const key in v) {
      if (Object.prototype.hasOwnProperty.call(v, key)) {
        const ov = obj_phase.vobos.find((e:any)=> e.area==key);
        res_obj.vobos.push({id: ov.id, users: v[key], area: ov.area});
      }
    }
    obj_phase.selected = true;
    const obj_vobo = this.vobos_sel.find((e:any)=>e.id==id);
    if(obj_vobo) obj_vobo.vobos = res_obj.vobos;
    else this.vobos_sel.push(res_obj);
  }

  goToConfig() {
    const url = '/admin/projects/config/' + this.id_proy;
   window.open(url, '_blank');
  }

  guardarDiagrama() {
    if (this.firstFormGroup.valid 
      && this.selectedPromotions.length > 0 
      && this.stores_sel.length > 0
      && (this.vobos_sel.length == this.phases.length)
    ) {
      const ins_data = { 
        ...this.firstFormGroup.value,
        id_proyecto: this.id_proy,
        promociones: this.selectedPromotions,
        fases: this.vobos_sel,
        sucursales: this.stores_sel
      };

      if(this.edit) {
        ins_data.id_diagrama = this.curr_diagram.id;
        this.projectsS.editProjectDiagram(ins_data).subscribe(
          (result:any) => {
            this.main.HideLoading();
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Creado',
                text: 'Diagrama creado correctamente'          
              }).then(() => {            
                this.dialogRef.close({ reload: true });
              });
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
              });
            }
          },
          (err:any) => {
            this.main.HideLoading();
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        );
      }
      else {
        this.projectsS.newProjectDiagram(ins_data).subscribe(
          (result:any) => {
            this.main.HideLoading();
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Creado',
                text: 'Diagrama creado correctamente'          
              }).then(() => {            
                this.dialogRef.close({ reload: true });
              });
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
              });
            }
          },
          (err:any) => {
            this.main.HideLoading();
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        );
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
  }

  applyFilter(event: Event, ds: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    ds.filter = filterValue.trim().toLowerCase();
    if (ds.paginator) ds.paginator.firstPage();
  }
}