import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AttributeStoreModel } from 'src/app/models/attribute.model';
import { StoreService } from 'src/app/services/store.service';
import { StoresAttributesValuesListUploadComponent } from '../stores-attributes-values-list-upload/stores-attributes-values-list-upload.component';
import { StoresAttributesValuesListSetallComponent } from '../stores-attributes-values-list-setall/stores-attributes-values-list-setall.component';
import { NewAttributeRuleComponent } from '../new-attribute-rule/new-attribute-rule.component';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-attributes-rules-list',
  templateUrl: './attributes-rules-list.component.html',
  styleUrls: ['./attributes-rules-list.component.scss']
})
export class AttributesRulesListComponent implements OnInit {
  
  attributeStoreList = new Array<AttributeStoreModel>();
  isLoading = true;  

  displayedColumns: string[] = ['id', 'nombre', 'tipo', 'creado',  'actions'];
  dataSource!: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  atribute : any;

  constructor(
    private storeS: StoreService,
    private matDialog: MatDialog,
    private _titleService: Title,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data : any,
    private main : MainService
  ) {
   // this.getAttributesStoreList();
   this.atribute = this.data.atributo;
   this.getAtributeRules();
 
  }

  ngOnInit(): void {
    console.log(this.atribute);
  }

  getAttributesStoreList() {
    this.attributeStoreList = this.storeS.getAttributesStoreList();
    this.dataSource = new MatTableDataSource(this.attributeStoreList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
  }

  getRuleTypeByid(id: number){
    return this.storeS.tipo_reglas.filter((x:any)=> x.id == id)[0]?.nombre;
  }

  atributos : any = [];


  getAtributeRules(){
    this.main.ShowLoading();
    this.storeS.getRulesByAtributo(this.atribute.id).subscribe(
      (result : any) => {
        
      this.main.HideLoading();

        this.atributos = result.data;

        this.dataSource = new MatTableDataSource(this.atributos);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        console.log(result.data);
      },
      (err : any) => {
        this.main.HideLoading();
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  getStores(){
    this.main.ShowLoading();
    this.storeS.getStoresView().subscribe(
      (result : any) => {
        
      this.main.HideLoading();

        this.atributos = result.data.viewData;

        this.dataSource = new MatTableDataSource(this.atributos);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        console.log(result.data);
      },
      (err : any) => {
        this.main.HideLoading();
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialogValuesUpload() {
    this.matDialog.open(StoresAttributesValuesListUploadComponent, {panelClass: 'dialogs-sm'}).afterClosed().subscribe(
      resp => {
      }
    );
  }


  openDialogDelete(deleterow : any){
    Swal.fire({
      title: 'Advertencia',
      text: '¿Está seguro que desea eliminar esta regla?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.main.ShowLoading();
        this.storeS.removeRuleAtributo(deleterow).subscribe(
          (result : any) => {
            
          this.main.HideLoading();
           Swal.fire({
             icon: 'success',
             title: 'Eliminada',
             text: 'Regla eliminada correctamente'          
           }).then(() => {            
             this.getAtributeRules();
           });
          },
          (err : any) => {
            this.main.HideLoading();
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Ocurrió un error al eliminar, por favor intente mas tarde'          
            }).then(() => {            
              this.getAtributeRules();
            });
            console.log(err);
            this.isLoading = false;
          }
        );
      }
    });
  }

  

  openDialogNewRule() {
        this.matDialog.open(NewAttributeRuleComponent, {
          panelClass: 'dialogs-lg',
          data: {
            atributo : this.atribute,
            isEdit : false,
          }
        }).afterClosed().subscribe(
          resp => {
            this.getAtributeRules();
          }
        );
  }
  
  openDialogEditRule(row : any) {
    this.matDialog.open(NewAttributeRuleComponent, {
      panelClass: 'dialogs-lg',
      data: {
        atributo : this.atribute,
        isEdit : true,
        atributo_regla : row
      }
    }).afterClosed().subscribe(
      resp => {
        this.getAtributeRules();
      }
    );
}

  openDialogValuesSetAll(setAll:boolean, suc_id : number = 0, nombre : string = "") {
    Swal.fire({
      title: 'Advertencia',
      text: 'Si cambia los valores de un atributo ANULARÁ todos los cambios pendientes si existe alguno en cola, por lo que tenga cuidado al asignar un valor',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.matDialog.open(StoresAttributesValuesListSetallComponent, {
          panelClass: 'dialogs-sm',
          data: {
            setAll: setAll,
            atributo : this.atribute,
            sucursal_id : suc_id,
            nombre : nombre
          }
        }).afterClosed().subscribe(
          resp => {
            this.getAtributeRules();
          }
        );
      }
    });

  }

}
