<div class="modal-header">
    <h4 class="modal-title">Respuestas de Formulario de aprobación - {{ nombre_proveedor }}</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <ng-container  *ngFor="let response of filterCurrent()">
            <h6>
                <span> <mat-icon class="align-middle">update</mat-icon> Respuestas del formulario: <strong>{{ response.created_at | date : "dd/MM/yyyy"  }}-  {{ nombre_proveedor }}</strong></span>            
            </h6>
            <div class="container">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Concepto</th>
                                    <th>Respuesta</th>
                                    <th>Comentarios</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr *ngFor="let resp of getRespuestas(response.respuesta)">
                                    <td>
                                        {{resp.nombre}}
                                    </td>
                                    <td>
                                        <span *ngIf="resp.respuesta" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                        <span *ngIf="!resp.respuesta" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                    </td>
                                    <td *ngIf="resp.respuesta">
                                        AUTORIZADO
                                    </td>
                                    <td *ngIf="!resp.respuesta">
                                        {{ resp.comentario }}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <span><b>Comentario:</b> {{response.comentario}}</span>
                <button (click)="getFiles(response.id)" mat-button>
                    <mat-icon>file_download</mat-icon> Descargar archivos
                </button>
   
            </div>
    
        </ng-container>

        <mat-divider></mat-divider>
        <h5 class="mt-3">
            <span> <mat-icon class="align-middle">youtube_searched_for</mat-icon> Historial de Respuestas del formulario</span>
        </h5>
        <div class="container">
            <div class="col-md-12 mb-3">
                <mat-accordion multi>
                    <mat-expansion-panel *ngFor="let response of filterOld()">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <mat-icon class="aling-middle text-muted me-2">event</mat-icon> {{ response.created_at | date : "dd/MM/yyyy"  }} - {{ nombre_proveedor }}
                          </mat-panel-title>
                          <mat-panel-description>
                            <!--<span class="badge bg-label-info">{{  filterFilesCount(response.id) }} archivos adjuntos</span>-->
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Concepto</th>
                                            <th>Respuesta</th>
                                            <th>Comentarios</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-border-bottom-0">
                                        <tr *ngFor="let resp of getRespuestas(response.respuesta)">
                                            <td>
                                                {{resp.nombre}}
                                            </td>
                                            <td>
                                                <span *ngIf="resp.respuesta" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!resp.respuesta" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="resp.respuesta">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!resp.respuesta">
                                                {{ resp.comentario }}
                                            </td>
                                        </tr>
                                      
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <span><b>Comentario:</b> {{response.comentario || "Sin comentarios"}}</span>
                        <button (click)="getFiles(response.id)" mat-button>
                            <mat-icon>file_download</mat-icon> Descargar archivos
                        </button>
                      </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</mat-dialog-content>