 import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';
  
  @Component({
    selector: 'app-project-media-stock-config',
    templateUrl: './project-media-stock-config.component.html',
    styleUrls: ['./project-media-stock-config.component.scss']
  })
  export class ProjectMediaStockConfig implements OnInit {
  

    id_medio : number = 0;
    stock_config : any = {};
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data:any,
      private fb: FormBuilder,
      private userS: UsersService,
      private projectS : ProjectsService,
      private dialogRef: MatDialogRef<ProjectMediaStockConfig>,
      private matDialog: MatDialog
    ) {

      this.id_medio = this.data.id_medio;
      this.getMediaStockConfigData();

    }
  
    ngOnInit(): void {
      
    }

    getMediaStockConfigData(){
      if(this.data.stock_config != ""){
        try{
          this.stock_config = JSON.parse(this.data.stock_config);
        }catch(ex){
          this.stock_config = { tipo : 0, top : 1, cantidad : 0};
        }
      }else{
        this.stock_config = { tipo : 0, top : 1, cantidad : 0};
      }

    }


    ruSure() {
      Swal.fire({
        title: '¿Estás seguro que deseas guardar este registro?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.saveConfig();
        }
      });
    }
  
    saveConfig() {
      if(this.stock_config.tipo != 0){
        Swal.fire({
          icon: 'info',
          title: 'Guardando...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
       
        this.projectS.setMediosStockConfig({id_medio : this.id_medio, stock_config : JSON.stringify(this.stock_config)}).subscribe(
          (result:any) => {
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                html: 'Registro guardado de manera correcta!'
              }).then(() => {            
                this.dialogRef.close({ reload: false });
              });
            }
          },
          (err:any) => {
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
            });
          }
        );
      }
    }

  
  }
  