import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProjectsProvidersAddComponent } from '../projects-providers-add/projects-providers-add.component';
import { CampaignLogsComponent } from '../campaign-logs/campaign-logs.component';
import { ProjectsProvidersFormHistoryComponent } from '../projects-providers-form-history/projects-providers-form-history.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { ProjectsProvidersReloadComponent } from '../projects-providers-reload/projects-providers-reload.component';
import { ProvidersSurveysLogsComponent } from '../providers_surveys_logs/providers_surveys_logs.component';
import { ProjectsProvidersReSendComponent } from '../projects-providers-resend/projects-providers-resend.component';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-projects-providers-list',
  templateUrl: './projects-providers-list.component.html',
  styleUrls: ['./projects-providers-list.component.scss']
})
export class ProjectsProvidersListComponent implements OnInit {

  dataSent : any;
  hostName: string;
  phase: any;
  form_filled : Array<any> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,    
    private matDialog: MatDialog,
    private projectService : ProjectsService,
    private main : MainService,
    private user : UsersService
  ) {
      this.dataSent = this.data;
      this.form_filled = this.data.form_vb.filter((x:any)=>  this.data.vobo.vobos.includes(x.id));
      console.log( this.form_filled);
      this.hostName = window.location.protocol + '//' + window.location.host + "/providers-preview/";
    }

  folder : any;
  id_campana_proyecto : any;
  id_proy_dia : any;
  id_phase : any;
  id_vobo : any;
  providerSurveyList : any = [];
  vobos : any;
  
  ngOnInit(): void {
    this.getProvidersSurv();

    this.vobos = this.dataSent.phase.vobos.filter((x:any)=> x.is_provider_vobo != 1 );
  }
  hasPermissions() : boolean{
    let currentPermisos = this.dataSent.diagrama.config_vobos.filter((f:any)=> f.id == this.dataSent.phase.id)[0].vobos.filter((v:any)=> v.id == this.dataSent.id_vobo)[0].users;
    return this.user.isBuhoUsr() || currentPermisos.includes(this.user.getUser().id);
   }

  selectedVobo : any;
  selected = false;

  changeArea(value:any){
    this.selected = true;
    this.selectedVobo = value;
  }

  openDialogAddProvidersFile() {
    this.matDialog.open(ProjectsProvidersAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        camp_id : this.dataSent.camp_id,
        folder : this.dataSent.folder,
        nombre : this.nombreDisplay,
        id_proy_dia : this.dataSent.id_proy_dia,
        id_fase: this.dataSent.id_phase,
        id_proy_vobo : this.dataSent.id_vobo,
        listprovsurv : this.providerSurveyList,
        form_filled : this.form_filled
      }
    }).afterClosed().subscribe(
      resp => {
        this.getProvidersSurv();
      }
    );
  }
  
  urSureResetVobi(){
    Swal.fire({
      title: 'Inserte comentario para la delegación',
      input: 'text',  
      inputPlaceholder: 'Escriba aqui el comentario',
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`,
      inputValidator: (value :string) => {
          if (!value) {
              return 'El comentario es necesario'
          }else{
            return "";
          }
      }
    }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: '¿Está seguro que desea enviar a resear este VoBo?',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
          })
        .then((willDelete : any) => {
          console.log(this.selectedVobo);
          if (willDelete.isConfirmed) {
            console.log({id_diagrama : this.dataSent.id_proy_dia, id_fase : this.selectedVobo.phase_id, id_proy_vobo : this.selectedVobo.id, comentario:  result.value});
            this.main.ShowLoading();

            this.projectService.getdiagramFiles({ id_diagrama : this.dataSent.id_proy_dia, id_fase : this.selectedVobo.phase_id}).subscribe((a:any)=>{
              let id_arte = a.data[0].id;
              this.projectService.resetVoboByProvider({id_diagrama : this.dataSent.id_proy_dia, id_fase : this.selectedVobo.phase_id, id_proy_vobo : this.selectedVobo.id, comentario: result.value, users_reset :  this.selectedVobo.users.map((x:any)=> x.id), id_arte}).subscribe(
                (result:any) => {
                  this.main.HideLoading();
                  if(result?.message == 'Success'){
                    Swal.fire({
                      icon: 'success',
                      title: 'Completado',
                      text: 'Vobo reseteado correctamente'          
                    }).then(s=>{
                      this. getProvidersSurv();
                    });
                  }
                 },
                 (err:any) => {
                  this.main.HideLoading();
                  console.log(err);
                  if(err.error.message != "No valid provider surveys were found in the request")
                  {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error al eliminar',
                      text: 'Ocurrió un error al eliminar.'
                    });
                  }
                }
              );
            })


          }});
         
        }
    });
  }

  openDialogReloadProvidersFile(id_provider_survey : number, id_provider : number) {
    this.matDialog.open(ProjectsProvidersReloadComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        id_provider_survey : id_provider_survey,
        folder : this.dataSent.folder,
        nombre : this.nombreDisplay,
        id_provider : id_provider,
        id_campana_proyecto : this.dataSent.camp_id,
        id_proy_dia : this.dataSent.id_proy_dia,
        id_fase: this.dataSent.id_phase,
        form_filled : this.form_filled
      }
    }).afterClosed().subscribe(
      resp => {
        this.getProvidersSurv();
      }
    );
  }

  
  openDialogReSendProvidersFile(id_provider_survey : number, id_provider : number) {
    this.matDialog.open(ProjectsProvidersReSendComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        id_provider_survey : id_provider_survey,
        folder : this.dataSent.folder,
        nombre : this.dataSent.nombre,
        id_provider : id_provider,
        id_campana_proyecto : this.dataSent.camp_id,
        id_proy_dia : this.dataSent.id_proy_dia,
        form_filled : this.form_filled
      }
    }).afterClosed().subscribe(
      resp => {
        this.getProvidersSurv();
      }
    );
  }

  openDialogFileLogs(id_provider_survey : number) {
    this.matDialog.open(ProvidersSurveysLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
        id_provider_survey : id_provider_survey 
      }
    }).afterClosed().subscribe(
      resp => {
        this.getProvidersSurv();
      }
    );
  }
  
  openDialogFormHistory(id_provider_survey : number, nombre : string) {
    this.matDialog.open(ProjectsProvidersFormHistoryComponent, {
      panelClass: ['dialogs-lg'],
      data: {        
        responses : this.responses.filter((x : any )=>{ return x.id_provider_survey == id_provider_survey }),
        files : this.files,
        nombre_proveedor : nombre,
        folder : ""
      }
    }).afterClosed().subscribe(
      resp => {
        
      }
    );
  }

  responses : any  = [];
  files :  any = [];
  nombreDisplay : string = "";

  getProvidersSurv(){
    this.projectService.getProvidersSurveys(this.dataSent.id_proy_dia,).subscribe((result:any)=>{
      if(result.message = 'Success'){
       
        this.providerSurveyList = result.data.surveys;
        this.responses = result.data.responses;
        this.files = result.data.files;
        this.nombreDisplay = this.dataSent.diagrama.medio +  "  " + this.dataSent.diagrama.pop;

        console.log("prosurv", this.providerSurveyList);
      }
     },
     (err:any)=>{
      console.log(err);
     });

  }

  DownloadElement(url : string){
    window.open(url, "_blank");
  }


  deleteProvSurv(id: number){
    Swal.fire({
      title: '¿Está seguro que desea eliminar este registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  .then((willDelete : any) => {
    if (willDelete.isConfirmed) {
      this.main.ShowLoading();
      this.projectService.deleteProviderSurvey(id).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result?.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Reseteada',
              text: 'Area reseteada correctamente'          
            }).then(s=>{
              this. getProvidersSurv();
            });
          }
         },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          if(err.error.message != "No valid provider surveys were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al resetear',
              text: 'Ocurrió un error al resetear.'
            });
          }
        }
      );
    } else {
    
    }
  });
  }

  lockProviderSurv(id_provider_survey: number, lock : number){
    let lockeunlocked = ""
    let sucesslock = ""
    if(lock == 0){
      lockeunlocked = "desbloquear"
      sucesslock = "desbloqueado"
    }else{
      lockeunlocked = "bloquear"
      sucesslock = "bloqueado"
    }
    Swal.fire({
      title: '¿Está seguro que desea '+ lockeunlocked +' este registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
   .then((willDelete : any) => {
    if (willDelete.isConfirmed) {
      this.main.ShowLoading();
      this.projectService.setProvidersSurvLock({ id_provider_survey : id_provider_survey, lock : lock }).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result?.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Exito',
              text: 'Registro '+ sucesslock +' correctamente'          
            }).then(s=>{
              this. getProvidersSurv();
            });
          }
         },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          if(err.error.message != "No valid provider surveys were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al '+ lockeunlocked +'.',
              text: 'Ocurrió un error al '+ lockeunlocked +'.'
            });
          }
        }
      );
    } else {
    
    }
  });
  }

  goToPreview(id: number){
    let urlSend = this.hostName + id + '/0';
    window.open(urlSend, "_blank");
  }

  checkIfCanUploadFile(){
    this.dataSent.vobos;
  }

}
