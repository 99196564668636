import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CutoffService } from 'src/app/services/cutoff.service';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-new-attribute-rule',
  templateUrl: './new-attribute-rule.component.html',
  styleUrls: ['./new-attribute-rule.component.scss']
})
export class NewAttributeRuleComponent implements OnInit {
  dataR!: any;

  comparadores = ['Igual a', 'Mayor o igual que', 'Menor o igual que'];
  atributos : any[] = [];
  nombreAtributo = "";

  isEdit : boolean = false;

  hasPosicion : boolean = false;
  //1
  sumatoriaObj : any = { attributes : [] };
  //2 //subtipo 1 Mayo que, subtipo 2 menor que
  mayormenorObj : any = { attribute : 0, operador : 1, valor_comparar : 0, valor_atributo : 0 }
  //3
  rangoObj : any = { attribute : 0, subtipo : 1, valor_min : 0, valor_max : 0,  valor_atributo : 0 }

  constructor(@Inject(MAT_DIALOG_DATA) public data : any, public dref: MatDialogRef<NewAttributeRuleComponent>,
    private _main :MainService, private _cutoff : CutoffService, private _projectService : ProjectsService, private _storesServices : StoreService
  ) { }

  ngOnInit(): void {

    this.isEdit = this.data.isEdit;
    this.nombreAtributo = this.data.atributo.name;
    console.log(this.data);
    if(!this.isEdit){
      this.dataR = {
        nombre : '',
        tipo : 1,
        data_regla : JSON.parse(JSON.stringify(this.sumatoriaObj)),
        id_atributo : this.data.atributo.id
      };
    }else{
      this.dataR =  this.data.atributo_regla;
      this.dataR.data_regla = JSON.parse(this.dataR.data_regla);
    }

    this.getAtributos();

  }

  close(): void { this.dref.close(); }


  invalidInput(): boolean {
    return (this.dataR.name === '' );
  }

  onTipoCambio(event: any) {
    console.log('Nuevo valor seleccionado:', event.value);
    
    if(event.value == 1){
      this.dataR.data_regla = JSON.parse(JSON.stringify(this.sumatoriaObj));
    }
    if(event.value == 2){
      this.dataR.data_regla = JSON.parse(JSON.stringify(this.mayormenorObj));
    }
    if(event.value == 3){
      this.dataR.data_regla = JSON.parse(JSON.stringify(this.rangoObj));
    }

  }

  mirror(event: KeyboardEvent): void {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    
    if (event.key === 'ArrowUp') {
      event.preventDefault(); 
      input.value = Math.max(1, (parseInt(input.value, 10) || 0) - 1).toString(); // Arrow up subtracts
    } else if (event.key === 'ArrowDown') {
      event.preventDefault(); // Prevent default scrolling behavior.
      input.value = ((parseInt(input.value, 10) || 0) + 1).toString(); // Arrow down adds
    }

    this.dataR.prioridad = parseInt(input.value, 10);
  }


  agregarRegla() {

  }

  eliminarRegla(index: number) {
    
  }

  getRanges(rangeSelect : any){
    return rangeSelect.split("-");
  }

  converToObject(obj: string){
    try{
      return JSON.parse(obj);
    }catch(ex){
      return [];
    }
  }

  setItemName(value : any, rule : any){

  }

  getItem(atribute_id : any) : any{
    return this.atributos.filter(x=>  x.id === atribute_id)[0];
  }


  getAtributos(){
    this._main.ShowLoading();7
    this._cutoff.getAtributos().subscribe((x: any) => {
      this._main.HideLoading();

      this.atributos = x.data.filter((x:any)=> (x.type_id == 4 || x.type_id == 6) && x.id != this.data.atributo.id);

    });
  }

  trackByFn(index: number, item: any): number {
    return index;
  }

  register(): void {  
    this.dataR.data_regla = JSON.stringify(this.dataR.data_regla);

      Swal.fire({
       icon: 'info',
       title: 'Guardando...',
       allowEscapeKey: false,
       allowOutsideClick: false,
       showConfirmButton: false,
       didOpen: () => {
         Swal.showLoading();
       }
     });

     this._storesServices.createRuleAtributo(this.dataR).subscribe(
       (result:any) => {
         if(result.message == "Success") {
           Swal.fire({
             icon: 'success',
             title: 'Guardado',
             html: 'POP guardado correctamente'
           }).then(() => {            
             this.dref.close(this.dataR);
           });
         }
       },
       (err:any) => {
         console.log(err);
         Swal.fire({
           icon: 'error',
           title: 'Error al guardar',
           text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
         });
       }
     );
    

    this.dref.close(this.dataR); 
}


edit(): void {  
this.dataR.data_regla = JSON.stringify(this.dataR.data_regla);
Swal.fire({
 icon: 'info',
 title: 'Guardando...',
 allowEscapeKey: false,
 allowOutsideClick: false,
 showConfirmButton: false,
 didOpen: () => {
   Swal.showLoading();
 }
});

this._storesServices.editRuleAtributo(this.dataR).subscribe(
 (result:any) => {
   if(result.message == "Success") {
     Swal.fire({
       icon: 'success',
       title: 'Guardado',
       html: 'Guardado existosamente'
     }).then(() => {            
       this.dref.close(this.dataR);
     });
   }
 },
 (err:any) => {
   console.log(err);
   Swal.fire({
     icon: 'error',
     title: 'Error al guardar',
     text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
   });
 }
);


this.dref.close(this.dataR); 
}

  
}
