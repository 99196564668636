<div class="modal-header">
    <h4 class="modal-title" *ngIf="isnew" id="modalEditNotifTitle"><mat-icon>receipt</mat-icon>Crear nuevo documento de pago</h4>
    <h4 class="modal-title" *ngIf="!isnew" id="modalEditNotifTitle"><mat-icon>receipt</mat-icon>Datos de documento de pago</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body modal-notif">
    <div class="row mt-2">
        
        <div class="col-sm-12 row">

            <div class="row mt-2">
                <div class="col-sm-4 pt-4">
                    <span class="me-3 h5"><b>Razón social: </b></span>
                </div>
                <div class="col-sm-8 pt-3">
                    <mat-form-field class="w-100 no-space-bottom" appearance="outline">
                        <mat-label *ngIf="isnew">Ingresa razón social</mat-label>
                        <input name="razon_social" [(ngModel)]="factura_data.razon_social" matInput value="" [disabled]="!isnew"  style="color:black">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-sm-4 pt-4">
                    <span class="me-3 h5"><b>Empresa recuperadora: </b></span>
                </div>
                <div class="col-sm-8 pt-2">
                    <mat-form-field  class="no-space-bottom" appearance="outline">
                        <mat-label *ngIf="isnew">Selecciona una opción</mat-label>
                        <mat-select *ngIf="isnew" [(ngModel)]="factura_data.empresa_recuperadora" name="empresa_recuperadora" [disabled]="!isnew">
                            <mat-option value="SPT">SPT</mat-option>
                            <mat-option value="COFACH">COFACH</mat-option>
                            <mat-option value="MAYORISTA">MAYORISTA</mat-option>
                        </mat-select>

                        <input *ngIf="!isnew"  name="empresa_recuperadora" [(ngModel)]="factura_data.empresa_recuperadora" matInput value="" [disabled]="!isnew"  style="color:black">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <div  class="col-sm-4 pt-4">
                    <span class="me-3 h5"><mat-icon *ngIf="isnew" class="align-middle" matTooltip="Describe el concepto como se requiere el documento fiscal" [matTooltipPosition]="'above'">error_outline</mat-icon><b> Concepto (descripción detallada):</b></span>
                </div>
                <div class="col-sm-6 pt-2">
                    <mat-form-field class="w-100 no-space-bottom" appearance="outline">
                        <mat-label *ngIf="isnew">Detalle del concepto</mat-label>
                        <textarea style="color:black" [(ngModel)]="factura_data.concepto" name="concepto" [disabled]="!isnew" matInput></textarea>
                    </mat-form-field>
                </div>
              
            </div>
            <div class="row mt-2">
                <div class="col-sm-4 pt-4">
                    <span class="me-3 h5"><b>Monto: </b></span>
                </div>
                <div class="col-sm-8 pt-2">
                    <mat-form-field class="no-space-bottom" appearance="outline">
                        <mat-label *ngIf="isnew">Ingresa monto</mat-label>    
                        <input style="color:black" [(ngModel)]="factura_data.monto" name="monto" matInput value="" [disabled]="true">
                        <span matPrefix class="align-middle"><mat-icon>attach_money</mat-icon></span>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-sm-4 pt-4">
                    <span class="me-3 h5"><b>Método de pago: </b></span>
                </div> 
                <div class="col-sm-8 pt-2">
                    <mat-form-field class="no-space-bottom" appearance="outline">
                        <mat-label *ngIf="isnew">Selecciona una opción</mat-label>
                        <mat-select *ngIf="isnew" [(ngModel)]="factura_data.metodo_pago" name="metodo_pago" [disabled]="!isnew"  style="color:black">
                            <mat-option value="NOTA DE CRÉDITO">NOTA DE CRÉDITO</mat-option>
                            <mat-option value="FACTURA">FACTURA</mat-option>        
                        </mat-select>
                        <input *ngIf="!isnew" name="metodo_pago" [(ngModel)]="factura_data.metodo_pago" matInput value="" [disabled]="!isnew"  style="color:black">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="isnew" class="row mt-2">
            <div class="col-sm-2 pt-2">
                    <button mat-flat-button color="accent" (click)="openUploadFileComentario()">Adjuntar archivos <mat-icon>upload</mat-icon> </button>
            </div>
        
            <span *ngIf="fileComentario.length != 0">Archivos:</span>
            <div *ngFor="let file of fileComentario; let i = index">
                <b>{{ file.name }}</b>
                <span (click)="removeFileComentario(i)">
                  <mat-icon style="font-size: 20px; cursor: pointer">close</mat-icon>
                </span>
            </div>
            </div>

            <div *ngIf="!isnew" class="row mt-2">
                <div class="col-sm-2 pt-2">
                        <button mat-flat-button color="accent" (click)="getFiles(factura_data.id)">Descargar archivos adjuntos<mat-icon>file_download</mat-icon> </button>
                </div>
                </div>
        </div>
    </div>
</div>
<br>
<br>
<div class="modal-footer">
    <button *ngIf="isnew" mat-flat-button class="bg-success text-white" (click)="ruSure();" type="submit">Guardar <mat-icon>save</mat-icon></button>
</div>