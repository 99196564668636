<div class="modal-header">
    <h4 class="modal-title"><mat-icon color="accent" class="align-middle">store</mat-icon> Reglas de <strong>{{atribute.name}}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <div class="row g-2">
            <div class="col mb-0">
                <div class="col-md-12">
                    <div class="overflow-auto w-100">
                        <div class="float-end">                            
                            <button mat-flat-button color="accent" (click)="openDialogNewRule()">Nueva regla<mat-icon>add</mat-icon></button>
                        </div>
                        <mat-form-field>
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej.Botadero" #input>
                        </mat-form-field>
                    </div>
                
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="w-100">
                
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="nombre">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                <td mat-cell *matCellDef="let row"> <strong>{{row.nombre}}</strong> </td>
                            </ng-container>
                
                            <ng-container matColumnDef="tipo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                                <td mat-cell *matCellDef="let row"> {{getRuleTypeByid(row.tipo)}} </td>
                            </ng-container>

                
                            <ng-container matColumnDef="creado">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Creado </th>
                                <td mat-cell *matCellDef="let row"> <strong>{{row.created_at}}</strong> </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                <td mat-cell *matCellDef="let row" class="text-center">

                                    <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" (click)="openDialogEditRule(row)" color="accent" >
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button matTooltip="Valores de Sucursales" [matTooltipPosition]="'above'" color="accent" (click)="openDialogDelete(row)">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </td>
                            </ng-container> 
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">No existen reglas</td>
                            </tr>
                        </table>
                        
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</mat-dialog-content>