<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">{{phase.name}}</h4>
    <div class="row centered-content-header-info">
        <h5 class="card-title  small-pad  fs-6 mb-1">Área: {{vobo.area}}</h5>
        <h6 class="card-subtitle text-muted small-pad  fs-7"> {{curr_diagram.pop}} {{curr_diagram.medio}}- Posición: {{curr_diagram.posicion}}</h6>
    </div>

    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body" style="min-height: 580px;">
        <mat-divider></mat-divider>
        <div>
            <div class="row mt-2">
           
                <div class="col-12">
                    <div class="card h-100">
                        <!--<div class="card-body text-center">
                            <h5 class="card-title">Medio: {{curr_diagram.medio}} - Area: {{vobo.area}}</h5>
                            <h6 class="card-subtitle text-muted">POP: {{curr_diagram.pop}} - Configuración: {{curr_diagram.pop_config}} - Posición: {{curr_diagram.posicion}}</h6>
                        </div>-->
                        <!--<div class="col-md-12 text-center">
                            <h6>
                                <mat-icon class="align-middle">update</mat-icon>
                                Última actualización: <strong  *ngIf="archivos.length>0">{{ archivos[0].created_at  | date : "dd/MM/yyyy" }} - {{ archivos[0].usuario }} </strong>
                            </h6>
                        </div>-->
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <!--<h6 class="floating-text-update">
                    <mat-icon class="align-middle">update</mat-icon>
                    Última actualización: 
                    <strong *ngIf="archivos.length>0">
                        {{ archivos[0].created_at | date : "dd/MM/yyyy" }} - {{ archivos[0].usuario }}
                    </strong>
                </h6>-->
                <div class="col-sm-8">
                    <div class="card h-100">
                        <div class="text-center" *ngIf="archivos.length>0">
                            <iframe id="pdf" name="pdf" width="95%" height="600px" [src]="getSourceURL()"></iframe>
                        </div>
                    </div>
                </div>

      
                <div class="col-sm-4">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="card-title">Formulario de aprobación</h4>
                            <mat-divider></mat-divider>
                            <form autocomplete="off" class="mt-3">
                                <small *ngIf="hasPermissions()">Todos los campos son obligatorios *</small>
                                <div class="row  mt-2" *ngFor="let q of form_vb">
                                    <div class="col-sm-8 pt-4">
                                        <span class="h5">{{q.nombre}}:</span>
                                    </div>
                                    <div class="col-sm-4">
                                        <mat-button-toggle-group [disabled]="!hasPermissions()" (change)="setAnswer(q,$event.value)" appearance="legacy" class="mt-3">
                                            <mat-button-toggle [value]="true">SI</mat-button-toggle>
                                            <mat-button-toggle [value]="false">NO</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-4 pt-4">
                                        <span class="me-3 h5">Comentarios:</span>
                                    </div>
                                    <div class="col-sm-8 pt-3">
                                        <mat-form-field class="w-100 no-space-bottom" appearance="outline">
                                            <mat-label>Ingrese comentarios</mat-label>
                                            <input [disabled]="!hasPermissions()" matInput [(ngModel)]="comentario" [ngModelOptions]="{standalone: true}" value="">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-sm-12 text-end">
                                        <button *ngIf="hasPermissions()" mat-flat-button mat-dialog-close>Limpiar <mat-icon>clear</mat-icon></button>
                                        <button *ngIf="archivos[0]?.aprobado==1" [disabled]="!hasPermissions()" (click)="guardarRespuestas()" mat-raised-button class="bg-success text-white">Guardar <mat-icon>save</mat-icon></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <mat-tab-group #tabGroup mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab><!-- promos -->
                    <ng-template mat-tab-label>
                        <mat-icon class="me-2 align-middle">campaign</mat-icon> Promociones
                    </ng-template>
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <div class="table-responsive text-nowrap">
                                <table mat-table [dataSource]="dspromotionFile" matSort #sort="matSort" class="mat-elevation-z8" style="width: 100%;">
                                    <ng-container *ngFor="let column of displayedColumns">
                                      <ng-container matColumnDef="{{column}}">
                                        <th mat-header-cell *matHeaderCellDef class="do-jump-line-make-bonito" style="min-width: 170px; margin-left: 20px;"> {{ column }} </th>
                                        <td mat-cell *matCellDef="let promo" style="min-width: 170px; margin-left: 20px;"> {{ promo[column] }} </td>
                                      </ng-container>
                                    </ng-container>
                                
                                    <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: allColumns;"></tr>
                                  </table>
                                  <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab><!-- archivos -->
                    <ng-template mat-tab-label>
                        <mat-icon class="me-2 align-middle">draft</mat-icon> Archivos
                    </ng-template>
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <div class="table-responsive text-nowrap">
                                <table class="table table-hover overflow-auto">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Archivo</th>
                                            <th>Chat</th>
                                            <th>Cargado por</th>
                                            <th>Fecha de Registro</th>
                                            <th>Equipo</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-border-bottom-0">
                                        <tr *ngFor="let p of archivos">
                                            <td>{{ p.id }}</td>
                                            <td>
                                                <a [href]="p.file" target="_blank">
                                                    {{ p.nombre }}
                                                </a>
                                            </td>
                                            <td>
                                                <button mat-icon-button>
                                                    <mat-icon *ngIf="p.msg_inc == 0">chat</mat-icon>
                                                    <mat-icon *ngIf="p.msg_inc > 0" [matBadge]="p.msg_inc">chat</mat-icon>
                                                </button>
                                            </td>
                                            <td>{{ p.usuario }}</td>
                                            <td>{{ p.created_at | date : 'dd/MM/yyyy HH:mma': '-0000' }}</td>
                                            <td><span class="badge bg-warning">FDA</span></td>
                                        </tr>
                                        <tr *ngIf="archivos.length == 0">
                                            <td colspan="6"><i>No se ha cargado ningún archivo</i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab><!-- Respuestas -->
                    <ng-template mat-tab-label>
                        <mat-icon class="me-2 align-middle">history</mat-icon> Historial de respuestas
                    </ng-template>
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <div class="table-responsive text-nowrap">
                                <table mat-table [dataSource]="dsResFile" matSort #sortRes="matSort" class="mat-elevation-z8" style="width: 100%;">
                                    <ng-container *ngFor="let column of displayedColumnsRes">
                                      <ng-container matColumnDef="{{column}}">
                                        <th mat-header-cell *matHeaderCellDef class="do-jump-line-make-bonito" style="min-width: 170px; margin-left: 14px;"> {{ column }} </th>
                                        <td mat-cell *matCellDef="let resp"  style="min-width: 170px; margin-left: 14px;"> {{ resp[column] }} </td>
                                      </ng-container>
                                    </ng-container>
                                
                                    <tr mat-header-row *matHeaderRowDef="allColumnsRes"></tr>
                                    <tr mat-row *matRowDef="let row; columns: allColumnsRes;"></tr>
                                  </table>
                                  <mat-paginator #paginatorRes [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</mat-dialog-content>
