import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectsPromotionsAddFileComponent } from '../projects-promotions-add-file/projects-promotions-add-file.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { ProjectModel } from 'src/app/models/project.model';

@Component({
  selector: 'app-projects-promotions-add',
  templateUrl: './projects-promotions-add.component.html',
  styleUrls: ['./projects-promotions-add.component.scss']
})
export class ProjectsPromotionsAddComponent implements OnInit {
  project: ProjectModel = new ProjectModel();
  isLoading = true;
  promotionFiles: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private projectService : ProjectsService,
    private main : MainService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<ProjectsPromotionsAddComponent>,
  ) {
    this.project = this.data.project;
    this.loadData();
   }

  ngOnInit(): void {
  }

  loadData(){
    this.projectService.getPromotionFiles(this.project.id).subscribe(
      (result:any) => {
        this.isLoading = false;
       this.promotionFiles = result.data;
      },
      (err:any) => {
        this.isLoading = false;
      if(err.error.message != "No valid art files were found in the request")
      {
        Swal.fire({
          icon: 'error',
          title: 'Error al obtener registros',
          text: 'Ocurrió un error al obtener los registros.'
        });
      }
      }
    );
  }

  openDialogUploadDistFile() {
    this.matDialog.open(ProjectsPromotionsAddFileComponent, {
      panelClass: ['dialogs-main'],
      data: {
        project: this.project
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.loadData();
        }
      }
    );
  }

}
