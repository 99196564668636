import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-vobo-add',
  templateUrl: './projects-vobo-add.component.html',
  styleUrls: ['./projects-vobo-add.component.scss']
})
export class ProjectsVoboAddComponent implements OnInit {
  newVobo!: any;
  id_proj : number = 0;
  isEdit : boolean = false;
  usersList : any[] = [];
  formsList : any[] = [];
  itemToAdd : string = "";
  hasProveedor : boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private main: MainService,
    private projectS: ProjectsService,
    private userS: UsersService,
    private dialogRef: MatDialogRef<ProjectsVoboAddComponent>,
  ) { 
    this.newVobo = this.data.vobo;
    this.usersList = this.data.users;
    this.formsList = this.data.forms;
    this.isEdit = this.data.vobo.id != 0;
    this.hasProveedor = this.data.hasProveedor;
    console.log(this.newVobo);
  }

  ngOnInit(): void {
    if(!this.isEdit) {
     
    }
  }

  setUsers(t: any) {
    this.newVobo.users = t;
  }

  setVobos(t: any) {
    this.newVobo.vobos = t;
  }

  isDupledNewVobo(){
   let isDupled = this.formsList.some(item => item.nombre.trim().toLowerCase() === this.itemToAdd.trim().toLowerCase());
    if(isDupled){
      Swal.fire({
        icon: 'error',
        title: 'Formulario Duplicado',
        text: 'El formulario se encuentra duplicado.'
      });
    }

    return isDupled;
  }

  loadFormularios(){
    this.projectS.getFormularios().subscribe((x:any)=>{
      this.formsList = x.data;
    });
 }


  saveFormulario(){
    if(!this.isDupledNewVobo() && this.itemToAdd != ""){
      this.projectS.createFormulario({nombre : this.itemToAdd}).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            let select_id = result.data;
            this.newVobo.vobos.push(select_id);
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              text: 'Formulario guardado correctamente'          
            }).then(() => {  
              this.itemToAdd = "";
              console.log("result vobo", result)          
              this.loadFormularios();
            });
          }
        },
        (err:any) => {
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }       
      );
    }
  }

}
