import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProjectDiagramationAddComponent } from '../../shared/dialogs/project-diagramation-add/project-diagramation-add.component';
import { ProjectDiagramationVobosComponent } from '../../shared/dialogs/project-diagramation-vobos/project-diagramation-vobos.component';
import { ProjectDiagramationVobosConfigComponent } from '../../shared/dialogs/project-diagramation-vobos-config/project-diagramation-vobos-config.component';
import { ProjectsArtUploadComponent } from '../../shared/dialogs/projects-art-upload/projects-art-upload.component';
import { ProjectsDiagramationInventoryComponent } from '../../shared/dialogs/projects-diagramation-inventory/projects-diagramation-inventory.component';
import { ProjectsDiagramationDistributionComponent } from '../../shared/dialogs/projects-diagramation-distribution/projects-diagramation-distribution.component';
import { ProjectModel, ProjectPhase, ProjectVobo, ProjectCampaignModel } from 'src/app/models/project.model';
import { CutoffService } from 'src/app/services/cutoff.service';
import { ProjectsProvidersListComponent } from '../../shared/dialogs/projects-providers-list/projects-providers-list.component';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-project-diagramation',
  templateUrl: './project-diagramation.component.html',
  styleUrls: ['./project-diagramation.component.scss']
})
export class ProjectDiagramationComponent implements OnInit {

  project: ProjectModel = new ProjectModel();
  id_proy: number = 0;
  projectConfig: any = new Array<ProjectPhase>();
  projectDiagramations: any= new Array<ProjectVobo>();
  projectPhases: any = new Array<ProjectPhase>();
  diagram_files!: any;
  campaign: ProjectCampaignModel = new ProjectCampaignModel();
  config_positions: any = [];
  form_vb: any = [];
  arr_order: any = [];
  projectResponse : any;
  userId : number = 0;
  constructor( private matDialog: MatDialog,
    private projectsS: ProjectsService,
    private _params: ActivatedRoute,
    private main: MainService,
    private _cutoffS : CutoffService,
    private userS : UsersService,
    private _router: Router) {
      this.project = new ProjectModel();
      this.userId = userS.getUser().id;
      this._params.params.subscribe(params => {
        if(params.projid){
          this.id_proy = params.projid;
          this.projectsS.checkIfSameThenReplace(this.id_proy).then((e:any)=>{
            this.campaign = this.projectsS.current_campaign;
            this.project = this.projectsS.current_project;
          }).catch(err=>{
            console.log(err);
            this._router.navigateByUrl('/admin/dashboard');  
          });
        }else{
          this._router.navigateByUrl('/admin/dashboard');
        } 
      });
    }

  ngOnInit(): void {
    this.loadProyectData();
  }


  allpromos : Array<any>= []

  loadProyectData() {
    this.main.ShowLoading();
    this.projectsS.getProjectConfig(this.id_proy).subscribe((x: any) => {
      this.main.HideLoading();
      this.projectPhases = x.data.data.map((e:ProjectPhase)=> {
        const t0 = x.data.project[0].t_zero?x.data.project[0].t_zero:new Date();

        e.vobos.map((v:ProjectVobo)=>{
          return Object.assign(v, {fecha_limite: this.main.addWorkingDays(t0,v.scheluded_time_days)});
        })
        return Object.assign(e, {fecha_limite: this.main.addWorkingDays(t0,e.scheluded_time_days)});
      });
      this.projectDiagramations = x.data.diagram;
      this.config_positions = x.data.config_positions;
      this.diagram_files = x.data.diagram_files;
      this.form_vb = x.data.form_vb;
      this.projectResponse = x.data.project;
      this.projectPhases.map((c:any)=> {
        this.arr_order.push({ tipo: "art", id_fase: c.id });
        c.vobos.map((vobo:any)=> {
          this.arr_order.push({ tipo: vobo.is_provider_vobo==1?"prov":"vobo", id_fase: c.id, id_vobo: vobo.id });
        })
      });

      this.allpromos = x.data.diagram.flatMap((item:any) => Object.values(item.promociones));
      console.log(this.projectPhases, this.arr_order, this.allpromos);

    },
    (err:any) => {
      console.log(err);
          this.main.HideLoading();
    });
  }

  findFile(id_diagrama: number, id_phase: number) {
    const arte = this.diagram_files.find((e:any)=>e.id_diagrama == id_diagrama && e.id_phase == id_phase);
    return arte?.aprobado;
  }

  openEditProjectDiagramDialog(curr_diagram: any) {
    this.matDialog.open(ProjectDiagramationAddComponent, {
      panelClass: ['dialogs-xxl'],
      data: {
        id_proy: this.id_proy,
        projectData: this.project,
        promos: this.projectsS.promos_file_data,
        phases: this.projectPhases,
        data: this.projectDiagramations,
        curr_diagram,
        config_positions: this.config_positions
      }
    }).afterClosed().subscribe(
      resp => {
        this.loadProyectData();
      }
    );
  }

  deleteProjectDiagramDialog(curr_diagram: any) {
    Swal.fire({
      title: '¿Está seguro que desea eliminar este Registro?. Esto no se puede deshacer.',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
    .then((willDelete) => {
      if (willDelete.isConfirmed) {
        this.projectsS.deleteProjectDiagram(curr_diagram.id).subscribe(
          (result:any) => {
            this.main.HideLoading();
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Eliminado',
                text: 'Diagrama eliminado correctamente'          
              }).then(x=>{
                this.loadProyectData();
              })
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'Ocurrió un error al eliminar el registro, por favor vuelve a intentarlo.'
              });
            }
          },
          (err:any) => {
            this.main.HideLoading();
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al eliminar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        );
      }
    });
  }


  openVobosDiagramDialog(diagram: any, phase: any, vobo: any) {
    this.matDialog.open(ProjectDiagramationVobosComponent, {
      panelClass: ['dialogs-xxl'],
      data: {
        phase,
        diagram,
        project: this.project,
        form_vb: this.form_vb,
        vobo,
        campaign: this.campaign,
        arr_order: this.arr_order,
        projectConf : this.projectResponse
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp.reload)
          this.loadProyectData();
      }
    );
  }

  openVobosDiagramConfigDialog() {
    this.matDialog.open(ProjectDiagramationVobosConfigComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        edit : true,
        data: this.projectDiagramations
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openUploadArtFIleDialog(diagram: any, phase: any){
    this.matDialog.open(ProjectsArtUploadComponent, {
      panelClass: ['dialogs-md'],
      data: {
        phase,
        diagram,
        project: this.project,
        arr_order: this.arr_order,
        projectConf : this.projectResponse
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp.reload)
          this.loadProyectData();
      }
    );
  }

  openInventoryDialog(){
    this.matDialog.open(ProjectsDiagramationInventoryComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        project: this.project
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  getPromoFactFile(){
    this.main.ShowLoading();
    this.projectsS.getPromotionData(this.campaign.id).subscribe((c: any) => {
      let proreturn =  this.projectDiagramations.map((pd:any)=> pd.promociones ).flat().map((pf:any)=>{

          let promotionFile = c.data.find((promo:any) => 
            promo['CODIGO PRINCIPAL'] == pf
           );

          return {
             "ID": pf,
            "REGISTROS" : "",
            "DIRECCION CORREO ELECTRONICO" : "",
            "ÁREA" : "",
            "MUNDO" : "",
            "DIVISIÓN" : "",
            "CATEGORÍA" : "",
            "PROVEEDOR" : promotionFile['PROVEEDOR'],
            "EMPRESA RECUPERADORA" : "",
            "LABORATORIO" : promotionFile['LABORATORIO'],
            "TIPO DE BONIFICACIÓN" : "",
            "FORMA DE RECUPERACIÓN" : "",
            "DESCRIPCIÓN" : "",
            "CORREO PROVEEDOR" : "",
            "CANAL DE VENTA" : "",
            "TIPO DE SOPORTE" : "",
            "CARGA TU SOPORTE" : "",
            "DESCRIPCIÓN O ETIQUETA DE LA NEGOCIACIÓN" : "",
            "MONTO" : "",
            "COMENTARIO ADICIONAL" : "",
            "FACTURA EMITIDA" : "",
          };
        });
        this.projectsS.getFacturasProject(this.project.id).subscribe((x:any)=>{
          this.main.HideLoading();
                 x.data.map((factura : any)=>{
                  const promosDiag = JSON.parse(factura.promociones);
  
                  let promotionFile = c.data.filter((promo:any) => 
                   promosDiag.includes(promo['CODIGO PRINCIPAL'])
                  );
                  proreturn.filter((pf:any)=> promosDiag.includes(pf.ID)).map((pf:any)=>{
                    Object.assign(pf,{
                      "REGISTROS" : factura.enviado,
                      "DIRECCION CORREO ELECTRONICO" : factura.email_usuario,
                      "EMPRESA RECUPERADORA" : factura.empresa_recuperadora,
                      "FORMA DE RECUPERACIÓN" : factura.metodo_pago,
                      "DESCRIPCIÓN" : factura.concepto,
                      "CORREO PROVEEDOR" : factura.email_proveedor,
                      "MONTO" : factura.monto,
                    })
                  })

                })
              
              this.main.generateExcel(proreturn, "Promo_FileDownload", "Data");
          
        });


    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }


  openDistributionDialog(){
    this.matDialog.open(ProjectsDiagramationDistributionComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        project: this.project,
        campaign: this.campaign,
        promos : this.allpromos
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openDialogProviders(diagrama : any, phase : any, vobo : any) {
    this.matDialog.open(ProjectsProvidersListComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        camp_id : this.project.id,
        folder : this.project.folder,
        nombre : diagrama.pop_config,
        id_proy_dia : diagrama.id,
        id_phase : phase.id,
        id_vobo : vobo.id,
        vobo: vobo,
        vobos : vobo.vobos,
        phase : phase,
        form_vb: this.form_vb,
        arr_order: this.arr_order,
        diagrama : diagrama
      }
    }).afterClosed().subscribe(
      resp => {
        this.loadProyectData();
      }
    );
  }

  onlyPick3Lett(str: string): string {
    return str.substring(0, 3);
  }

  getVoboObj(diagram_vobos: any, vobo: any) {
    const phase = diagram_vobos.find((e:any)=>e.id==vobo.phase_id);
    const v = phase?.vobos?.find((e:any)=>e.id==vobo.id);
    return v?.status;
  }

  hasPermisionToSee(diagrama : any) : boolean{
    if(this.userS.hasPermissions("diagram_super", this.userS.isBuhoUsr(), this.userS.isBuhoUsr())  || JSON.parse(this.projectResponse.users_upload).includes(this.userId) ){
      return true;
    }else{
      return this.isUserInDiagrama(diagrama);
    }
  }

   isUserInDiagrama(diagrama : any) : boolean {
    for (const config of diagrama.config_vobos) {
      for (const vobo of config.vobos) {
        if (vobo.users.includes(this.userId)) {
          return true;
        }
      }
    }
    return false;
  }
}
