<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            <!--<button (click)="testFiltro()">Hola</button>-->
            <mat-icon class="align-middle">campaign</mat-icon> {{ campaignsData!.project?campaignsData!.project.name:"" }}
            <!-- <small class="text-muted" *ngIf="campaignsData.progress.length > 0">- última actualización {{ campaignsData.progress[0].date | date : "dd/MM/yyyy hh:mma": "-0000" }} </small> -->
            <button mat-icon-button [matMenuTriggerFor]="menuCampana" matTooltip="Más acciones" [matTooltipPosition]="'above'" color="primary">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menuCampana="matMenu">
                <button (click)="openEditProjectDialog()" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                </button>
                <button (click)="deleteProjectConfirm()" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar</span>
                </button>
            </mat-menu>
            <div class="d-flex justify-content-end align-items-center mb-4">
                <!-- Dropdown de corte de sucursales -->
                <div class="me-4">
                  <mat-form-field appearance="outline" class="form-field same-height">
                    <mat-label>Selecciona Corte de Sucursales</mat-label>
                    <mat-select [(ngModel)]="selectedSucursalCorte" (selectionChange)="setSucursalesCorte($event.value)">
                      <mat-option *ngFor="let corte of sucursalesCorte" [value]="corte.id">{{ corte.nombre }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              
                <!-- Botón de promociones -->
                <button style="margin-top: -20px;" mat-flat-button color="primary" class="same-height" (click)="openUploadPromo()">
                  Promociones <mat-icon>insights</mat-icon>
                </button>
              </div>
        </h4>    

          <div style="padding-bottom: 25px;">
            <button mat-raised-button color="primary" class="same-height" [routerLink]="'/admin/projects'">
              <mat-icon>arrow_back</mat-icon><span class="nb-text">Atras</span>
            </button>
          </div>

                 <!-- Card after deadline strategies -->
                 <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-4">
                            <h5 class="card-header">
                                <mat-icon class="text-success align-middle">stop</mat-icon> Listado de proyectos activos
                                <!-- <span class="float-end"><strong><mat-icon class="align-middle" matTooltip="Al llegar la fecha límite, se otorga un VoBo a todos los Preproyectos" [matTooltipPosition]="'above'">warning</mat-icon> Fecha límite para cambios: 27/04/2023</strong></span> -->
                            </h5>
                            <div class="card-body">
                                <div class="table-responsive text-nowrap">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre</th>                                        
                                                <th>Status</th>
                                                <!-- <th>Tipo</th> -->
                                                <th>Fecha Inicio</th>
                                                <th>Creado por</th>
                                                <th>Acciones</th>                                        
                                            </tr>
                                        </thead>
                                        <tbody class="table-border-bottom-0">
                                            <tr *ngFor="let camp of campaignsData.progress; let i = index">
                                                <td role="button" (click)="openProjectMedia(camp.id)"><strong>{{i+1}}</strong></td>
                                                <td role="button" (click)="openProjectMedia(camp.id)"><strong>{{camp.name}}</strong></td>
                                                <td role="button" class="">
                                                    <button type="button" (click)="goToCampaign(camp.id)" class="ms-4 btn btn-warning d-flex align-items-center" aria-expanded="false">
                                                        <span class="me-2"> Configurado </span> 
                                                        <mat-icon>settings</mat-icon>
                                                    </button>
                                                </td>
                                                <td role="button" (click)="openProjectMedia(camp.id)"><i> {{camp.date  | date : "dd/MM/yyyy"}}</i></td>
                                                <td role="button" (click)="openProjectMedia(camp.id)">{{camp.owner}}</td>
                                                <td>
                                                    <button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="openCampaignLogs(camp)">
                                                        <mat-icon  matBadgeColor="warn">forum</mat-icon>
                                                    </button>
                                                    <button *ngIf="hasPermissions('upd_camp',true,true) || isBuho" mat-icon-button [matMenuTriggerFor]="menuCampana" matTooltip="Más acciones" [matTooltipPosition]="'above'" color="accent">
                                                        <mat-icon>more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menuCampana="matMenu">
                                                        <button *ngIf="hasPermissions('upd_camp',true,true)" (click)="openEditCampaignDialog(camp)" mat-menu-item>
                                                            <mat-icon>edit</mat-icon>
                                                            <span>Editar</span>
                                                        </button>
                                                        <button (click)="openProjectMedia(camp.id)" mat-menu-item>
                                                            <mat-icon>perm_media</mat-icon>
                                                            <span>Ver Medios</span>
                                                        </button>
                                                        <button (click)="openProjectDiagramation(camp.id)" mat-menu-item>
                                                            <mat-icon>table_border</mat-icon>
                                                            <span>Configurar Diagramación</span>
                                                        </button>
                                                    </mat-menu> 
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button mat-flat-button class="mt-4 bg-info text-white" (click)="openAddProjectDialog()">Generar nuevo proyecto <mat-icon>add</mat-icon></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Card active campaigns strategies-->      
                                 <!-- Card after deadline strategies -->
                                 <div class="row" *ngIf="false">
                                    <div class="col-md-12">
                                        <div class="card mb-4">
                                            <h5 class="card-header">
                                                <mat-icon class="text-success align-middle">stop</mat-icon> Listado de proyectos finalizados
                                                <!-- <span class="float-end"><strong><mat-icon class="align-middle" matTooltip="Al llegar la fecha límite, se otorga un VoBo a todos los Preproyectos" [matTooltipPosition]="'above'">warning</mat-icon> Fecha límite para cambios: 27/04/2023</strong></span> -->
                                            </h5>
                                            <div class="card-body">
                                                <div class="table-responsive text-nowrap">
                                                    <table class="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Nombre</th>                                        
                                                                <th>Status</th>
                                                                <!-- <th>Tipo</th> -->
                                                                <th>Fecha Inicio</th>
                                                                <th>Creado por</th>
                                                                <th>Acciones</th>                                        
                                                            </tr>
                                                        </thead>
                                                        <tbody class="table-border-bottom-0">
                                                            <tr *ngFor="let camp of campaignsData.finished; let i = index">
                                                                <td role="button"><strong>{{i+1}}</strong></td>
                                                                <td role="button" (click)="goToCampaign(camp.id)"><strong>{{camp.name}}</strong></td>
                                                                <td role="button" class="">
                                                                    <button type="button" class="btn btn-success disabled" aria-expanded="false">
                                                                        Finalizado
                                                                    </button>
                                                                </td>
                                                                <td role="button"><i> {{camp.date  | date : "dd/MM/yyyy"}}</i></td>
                                                                <td role="button">{{camp.owner}}</td>
                                                                <td>
                                                                    <button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="openCampaignLogs(camp)">
                                                                        <mat-icon  matBadgeColor="warn">forum</mat-icon>
                                                                    </button>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Card active campaigns strategies-->     

    </div>
</div>