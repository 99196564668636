import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router} from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { NewPopMaterialComponent } from '../../shared/dialogs/new-pop-material/new-pop-material.component';
import { EditPopMaterialComponent } from '../../shared/dialogs/edit-pop-material/edit-pop-material.component';
import { ProjectMediaStockConfig } from '../../shared/dialogs/project-media-stock-config/project-media-stock-config.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import { CutoffService } from 'src/app/services/cutoff.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pop-materials',
  templateUrl: './pop-materials.component.html',
  styleUrls: ['./pop-materials.component.scss']
})
export class PopMaterialsComponent implements OnInit {
  mediaID: number = 0;
  projectID: number = 0;
  // HTML View Controller Elements
  activepop: number = -1;


  poplistActual : Array<any> = [];

  currentMedia : any;

  constructor(private dialog: MatDialog, private route: ActivatedRoute, 
    private router: Router, private projService : ProjectsService,
    private _main : MainService, private _cutoffS : CutoffService) { }

  ngOnInit(): void {
    this.getData();
    this.getInventory();
  }


  /* async Promise<void> getData(0):
     Extracts data from URL. */
  async getData(): Promise<void> {
    try {
      // Extract ID
      const url: ParamMap = await firstValueFrom(this.route.paramMap);
      this.projectID = Number(url.get('pid'));
      this.mediaID = Number(url.get('mid'))

      this.getPopListActual(this.mediaID);

      if (!this.projectID)
        throw new Error('No se recibió ningún ID.');
    } catch (error) {
      console.error(error);
    }
  }


  getPopListActual(media_id:number){
   // return this.projService.poplist.filter(x=>( x.media_id == media_id));
    this.projService.getPOPs(media_id).subscribe((x: any) => {
      this._main.HideLoading();
      this.currentMedia = x.data.medio;
      this.poplistActual = x.data.elementos_pop;
     
      //ESTO DEBE DE ESTAR EN TODAS LOS COMPONENTESD DE PROYECTOS
      this._cutoffS.checkIfSameProjectThenReplace(this.currentMedia.id_proy_camp).then(x=>{
        this.getInventory();
      });

    },
    (err:any) => {
      console.log(err);
          this._main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener registros, vuelva a intentarlo más tarde.'
          });
    });
  }

  async newPOP(): Promise<void> {
    const npDialogRef: MatDialogRef<NewPopMaterialComponent, any> = this.dialog.open(NewPopMaterialComponent, {
      data: { medio: this.currentMedia, isEdit : false},
      panelClass: 'dialogs-main'
    });

    try {
      const result = await firstValueFrom(npDialogRef.afterClosed());

      if (result) {
        this.getPopListActual(this.mediaID);
      }
    } catch (error) {
      console.error('Error handling dialog result:', error);
    }
  }

  async showPOPDetails(POP : any): Promise<void> {
    const spDialogRef: MatDialogRef<NewPopMaterialComponent, any> = this.dialog.open(NewPopMaterialComponent, {
      data: { medio: this.currentMedia, isEdit : true, pop : POP},
      panelClass: 'dialogs-main'
    });

    try {
      const result = await firstValueFrom(spDialogRef.afterClosed());

      if (result) {
        this.getPopListActual(this.mediaID);
      }
    } catch (error) {
      console.error('Error handling dialog result:', error);
    }
  }

  converToObject(obj: string){
    try{
      return JSON.parse(obj);
    }catch(ex){
      return [];
    }
  }

  goToConfign(popid :  number ){
    this.router.navigateByUrl('/admin/projects/pop-config/'+ this.projectID +'/'+ this.mediaID +'/'+ popid)
  }

    openDialogClone() {
      this.dialog.open(ProjectMediaStockConfig, {
        panelClass: ['dialogs-sm'],
        data: {
          id_medio : this.mediaID,
          stock_config : this.currentMedia.stock_config
        }
      }).afterClosed().subscribe(
        resp => {
          this.getData();
        }
      );
    }

  loadedInventorys = false;
  getInventory(){
      this.loadedInventorys = false;
      console.log(this.projectID);
      this.projService.getProjectInvTotals(this.currentMedia.id_proyecto).subscribe(
        (result:any) => {
          this.loadedInventorys = true;
         if(result.message == "Success") {
  
              let columnas_sum:any = {};
              let columnas_total:any = {};
              let arr_reglas:any = [];
              let arr_cant:any = [];
              const def_fields_stores:any = ["id", "Plaza", "FarmID", "Filial", "Nombre"];
              
              result.totales.map((config:any)=> {
                  if(config.formato == 2) {
                      const r = JSON.parse(config.prioridad_filtro)[0];
                      r.cant_type = 3;
                      r.id_pop_config = config.id_pc;
                      r.pop_config = config.pop_config;
                      arr_reglas.push(r);
                  }
                  else if(!config.reglas_distribucion) {
                      const r = {cant_type: 1, multiplicador: 1, id_pop_config: config.id_pc, pop_config: config.pop_config};
                      arr_reglas.push(r);
                  }
                  else  {
                      const r = JSON.parse(config.reglas_distribucion)[0];
                      r.cant_type = 2;
                      r.id_pop_config = config.id_pc;
                      r.pop_config = config.pop_config;
                      arr_reglas.push(r);
                  }
              });
          
              let arr_fields_stores:any = [];
              arr_reglas.map((e:any)=> {
                  if(e.nombre) {
                    arr_fields_stores.push(e.nombre);
                    if(!arr_cant.some((ac:any)=> ac.atributo==e.nombre)) {
                      arr_cant.push({
                          id : e.id,
                          pop_config: e.pop_config,
                          atributo: e.nombre,
                          cantidad: parseInt(e.multiplicador),
                          tipo: e.cant_type
                      });
                    }
                  }
              });
              const arr_fields_totals = arr_fields_stores;
              arr_fields_stores = def_fields_stores.concat(arr_fields_stores);
              
              let filtros:any = [];
              if(result.totales.pop_sucursales_filtro) filtros = JSON.parse(result.totales.pop_sucursales_filtro);
              if(result.totales.sucursales_filtro) filtros = [ ...filtros, ...JSON.parse(result.totales.sucursales_filtro)];
              
              const storeFile:any = this._cutoffS.filtrarSucursales(filtros).map((e:any)=>{ 
                  let obj_store:any = {};
                  arr_fields_stores.map((key:string)=> {
                    obj_store[key]= e[key];
                  });
                  return obj_store;
              });
  
              arr_fields_stores.map((key:string)=> {
                columnas_sum[key] = this._cutoffS.project_cutoff_file_data.map((e:any)=>e[key]).filter((e:any)=>e).map((e:any)=>parseInt(e)).reduce((a:any, b:any) => a + b, 0);
              });
              
              const dt_totales:any = [];
              arr_cant.map((e:any)=> { 
                  let cantidad = 0;
                  let sel_data = result.data.find((rd:any)=>rd.pop==e.pop_config);
                  if(e.cant_type == 1) {
                    cantidad = storeFile.length*e.cantidad;
                  }
                  else {
                    cantidad = columnas_sum[e.atributo]?columnas_sum[e.atributo]*e.cantidad:0;
                  }
                  const sel = sel_data?sel_data.cantidad:0;
                  const disp = cantidad-sel;
                  dt_totales.push({
                    id_atributo : e.id,
                    atributo: e.atributo,
                    inventario: cantidad,
                    disponible: disp,
                    seleccionado: sel
                  }) 
              });
              console.log(dt_totales);
            }
        },
        (err:any) => {
          this.loadedInventorys = true;
          console.log(err);
        }
      );
    }
}
