<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <div class="project-config-container">
            <h2 class="fw-bold py-3 mb-4 text-primary">
                <mat-icon class="me-2 align-middle">edit</mat-icon>Configuración - {{project?.nombre}}
            </h2>
            <div class="button-group">
              <!--<button mat-raised-button color="primary">Configuración de Inventarios</button>-->
              <!--<button mat-raised-button color="accent">Diagramación</button>-->
            </div>
        
            <div>
                <h5 class="fw-bold py-3 mb-4 text-primary"><mat-icon class="me-2 align-middle"> edit_attributes</mat-icon>Configuración de Vistos Buenos:</h5>
            </div>
            <div class="button-group d-flex align-items-center mb-4">
             <button class="same-height" mat-raised-button color="accent" (click)="ruSure()">Guardar</button>

             <button class="same-height" mat-raised-button (click)="openCloneProyect()" color="primary">Clonar Configuración</button>
             <button class="same-height" mat-raised-button (click)="openReorderPhases()" color="primary">Reordenar fases</button> 
             <button class="same-height" mat-raised-button (click)="addPhase()" color="primary"><mat-icon>add</mat-icon> Añadir nueva fase</button> 
             </div>
             <div class="me-5">
              <mat-form-field appearance="outline" class="form-field same-height">
                <mat-label>Usuarios para carga de Artes</mat-label>
                <mat-select [(ngModel)]="user_selected" multiple>
                    <mat-option *ngFor="let user of usersList" name="users_name" [value]="user.id">{{ user.name }}</mat-option>
                </mat-select>
               </mat-form-field>
             </div>

              <div class="alert alert-danger" role="alert" *ngIf="haschanges">
                *Hay cambios pendientes sin guardar
              </div>
            <div class=" row">
              <div class="col-sm-4" *ngFor="let phase of dataPhases">
                <mat-card >
                  <div class="d-flex justify-content-between align-items-center">
                    <mat-card-title>{{ phase.nombre }}</mat-card-title>
           
                    <div class="text-secondary">
                        <span>Límite de arte: {{ phase.scheluded_time_days }} días</span>
                    </div>
                    <button mat-icon-button matTooltip="Editar" (click)="editPhase(phase)"><mat-icon mat-tooltip="Editar">edit</mat-icon></button>
                </div>
                  <mat-divider></mat-divider>
                  <div cdkDropList (cdkDropListDropped)="drop(phase.vobos, $event)">
                    <mat-list>
                      <mat-list-item *ngFor="let item of phase.vobos" [hidden]="item.deleted == 1" cdkDrag>
                          <button mat-icon-button matTooltip="Eliminar" (click)="removeItem(item)"><mat-icon >remove</mat-icon></button>
                          <button mat-icon-button matTooltip="Editar" (click)="openAddEditVobo(item)"><mat-icon mat-tooltip="Editar">edit</mat-icon></button>
                        <div mat-line>Área: {{ item.area }}</div>
                        <div mat-line>Días programados: {{ item.scheluded_time_days }}</div>
                        <div mat-line>Usuarios seleccionados: {{ item.users?.length }}</div>
                        <div mat-line>Validaciones en formulario: {{ item.vobos?.length }}</div>
                      </mat-list-item>
                    </mat-list>
                  </div>
                  <mat-list>
                      <mat-list-item>
                          <button mat-flat-button color="secundary" (click)="addItem(phase.vobos)"><mat-icon>add</mat-icon> Añadir nuevo VoBo </button>
                        </mat-list-item>
                  </mat-list>
                </mat-card>
              </div>

              <!--<div class="col-sm-4">
                <mat-card>
                  <mat-card-title>Fase de Artes</mat-card-title>
                  <mat-divider></mat-divider>
                  <div cdkDropList (cdkDropListDropped)="drop(artesItems, $event)">
                    <mat-list>
                      <mat-list-item *ngFor="let item of artesItems" [hidden]="item.deleted == 1" cdkDrag>
                      <button mat-icon-button matTooltip="Eliminar" (click)="removeItem(item)"><mat-icon >remove</mat-icon></button>
                      <button mat-icon-button matTooltip="Editar" (click)="openAddEditVobo(item)"><mat-icon mat-tooltip="Editar">edit</mat-icon></button>
                        <div mat-line>Responsable: {{ item.responsable }}</div>
                        <div mat-line>Días programados: {{ item.scheluded_time_days }}</div>
                      </mat-list-item>
                    </mat-list>
                  </div>
                  <mat-list>
                      <mat-list-item>
                          <button mat-flat-button color="secondary" (click)="addArtes()"><mat-icon>add</mat-icon> Añadir nuevo VoBo </button>
                        </mat-list-item>
                  </mat-list>
                </mat-card>
              </div>-->


              <!--<div class="col-sm-4">
                <mat-card>
                  <button mat-flat-button color="secondary" (click)="addPhase()"><mat-icon>add</mat-icon> Añadir nueva fase</button>
                </mat-card>
              </div>-->
            </div>
          </div>
    </div>
</div>
