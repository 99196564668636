import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

import * as Excel from 'exceljs';
const fs = require('file-saver');


@Injectable({
  providedIn: 'root'
})
export class MainService {

  private httpConfig: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    body: ""
  };

  private httpConfigDownload: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    body: ""
  };

  private httpConfigForm: any = {
    body: ""
  };

  private apiURL = environment.API_URL;
  constructor(private http: HttpClient) { }

  public postRequest = (model: any, api: string): any => {
    this.httpConfig.body = JSON.stringify(model);
    return this.http.request("POST", this.apiURL + api, this.httpConfig);
  }
  
  public postRequestDownload = (model: any, api: string): any => {
    this.httpConfigDownload.body = JSON.stringify(model);
	this.httpConfigDownload.responseType = 'blob'
    return this.http.request("POST", this.apiURL + api, this.httpConfigDownload);
  }
  

  public getRequest = (api: string): any => {  
    return this.http.get(this.apiURL + api);
  }

  public getRequest2 = (api: string): Observable<any> => {  
    return this.http.get(this.apiURL + api);
  }

  public deleteRequest = (api: string): any => {
    return this.http.delete(this.apiURL + api);
  }

  public postRequestFormData = (form: FormData, api: string): Observable<any> => {
    this.httpConfigForm.body = form;
    console.log("form sended", this.httpConfigForm.body, form);
    let url = this.apiURL + api;
    return this.http.request("POST", url, this.httpConfigForm);
  }

  private loadingStus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public getLoadingStatus(): Observable<boolean> {
    return this.loadingStus.asObservable();
  }
  public ShowLoading(){
    console.log("show load") 
       this.loadingStus.next(true);
   }

   public HideLoading(){
     console.log("hide load")
       this.loadingStus.next(false);
   }

   public cloneObj(obj : any){
    return JSON.parse(JSON.stringify(obj));;
   }

   async getFilefApiromUrl(fileUrl:string) : Promise<any>{
    return new Promise(async (resolve, reject) => {
      this.http.get(this.apiURL + fileUrl, { responseType: 'blob' })
        .subscribe((response) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const content = reader.result as ArrayBuffer;
            resolve(content);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsArrayBuffer(response);
        }, (error) => {
          reject(error);
        });
    });
  }

   async getFilefromUrl(fileUrl:string) : Promise<any>{
    return new Promise(async (resolve, reject) => {
      this.http.get(fileUrl, { responseType: 'blob' })
        .subscribe((response) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const content = reader.result as ArrayBuffer;
            resolve(content);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsArrayBuffer(response);
        }, (error) => {
          reject(error);
        });
    });
  }

  async getFilefromUrl_old(fileUrl:string) : Promise<any>{
    return new Promise(async (resolve, reject) => { 
      try{
        var res = await fetch(fileUrl)
        var blob = await res.blob();
  
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            resolve(reader.result);
        }, false);
    
        reader.onerror = () => {
          console.log("error! - no existe")
          return reject(this);
        };
           reader.readAsDataURL(blob);
      }catch(x){
        console.log("error! - no existe")
        return reject(this);
      }
    })
  }

  postDataUrlExt(data: any , url : any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(url, data, { headers: headers });
  }

  convertToDate(fechaString :  string){
  var partesFecha : string[] = fechaString.split('/');
  var fechaObjeto = new Date(parseInt(partesFecha[2]), parseInt(partesFecha[1]) - 1, parseInt(partesFecha[0]));
  return fechaObjeto;
  }

  public async generateExcel(json_data: any, filename: any, worksheetname: any = "HOJA 1", customheader: any = "", headersForced: Array<string> = []) {
		let workbook = new Excel.Workbook();
		let worksheet = workbook.addWorksheet(worksheetname);

		if (customheader != "") {
			var customheaderArray: string[] = [customheader, "", ""]
			var customHeaderRow = worksheet.addRow(customheaderArray);
			customHeaderRow.eachCell((cell, number) => {
				cell.name = "HEADER_NAME"
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: { argb: 'a8bdfc' },
					bgColor: { argb: '4E73DF' }
				}
				cell.font = {
					name: 'Calibri',
					family: 2,
					bold: true,
					size: 12,
				}
				cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
			});

			worksheet.mergeCells("A1:C1");
		}

		let header: string[] = [];
		if (headersForced.length == 0) {
			if (json_data.length != 0) {
				for (var key in json_data[0]) {
					header.push(key);
				}
			}
		} else {
			header = headersForced;
		}

		let headerRow = worksheet.addRow(header);
		headerRow.eachCell((cell, number) => {
			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: 'ff006eff' },
				bgColor: { argb: '4E73DF' }
			}
			cell.font = {
				name: 'Arial',
				family: 2,
				bold: true,
				size: 12,
			}
			cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
		});

		for (let x1 of json_data) {
			let x2 = Object.keys(x1);
			let temp = []
			for (let y of x2) {
				if (x1[y] == "" && x1[y] != 0) {
					x1[y] = "  "
				}
				temp.push(x1[y])
			}
			var temprow = worksheet.addRow(temp)
			temprow.font = {
				name: 'Arial',
				family: 2,
				bold: false,
				size: 11,
			}
			//temprow.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
			temprow.height = 22;
		}

		worksheet.columns.forEach(function (column, i) {
			var maxLength = 0;
			if (column["eachCell"]) {
				column["eachCell"]({ includeEmpty: true }, function (cell) {
					if (cell.name != "HEADER_NAME") {

						var columnLength = cell.value ? cell.value.toString().length : 10;
						if (columnLength > maxLength) {
							maxLength = columnLength;
						}
						if (cell.value != "") {
							cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
						}
						if (cell.value == 0) {
							cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
						}
					} else {
					}
				});
			}

			column.width = (maxLength * 0.8) + (maxLength * 0.3) + 3;
			column.alignment = { vertical: 'middle', horizontal: 'justify' };

			//column.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
		});
		if (customheader == "") {
			worksheet.views = [
				{ state: 'frozen', ySplit: 1 }
			];
		} else {
			worksheet.views = [
				{ state: 'frozen', ySplit: 2 }
			];
		}
		
		workbook.xlsx.writeBuffer().then(async (data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			await fs.saveAs(blob, filename + '-' + this.getUqNaming() + '.xlsx');
		});
	}

	downloadTxtFile(fileUrl :  string): Observable<any> {
		return this.http.get(fileUrl, { responseType: 'text' });
	}

    private getUqNaming(): string {
      let d = new Date();
      let uniqueName = d.getDate().toString() + (d.getMonth() + 1).toString() + d.getFullYear().toString() + "_" + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString();
      return uniqueName
    } 

	addWorkingDays(startDate : Date, daysToAdd : number) {
		var strDate = new Date(startDate);
		var dow = strDate.getDay();
		if (dow == 0)
			daysToAdd++;
		if (dow + daysToAdd >= 6) {
			var remainingWorkDays = daysToAdd - (5 - dow);
			daysToAdd += 2;
			if (remainingWorkDays > 5) {
				daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
				if (remainingWorkDays % 5 == 0)
					daysToAdd -= 2;
			}
		}
		let newDate = new Date();
		newDate.setDate(strDate.getDate() + daysToAdd);
		return newDate;
	}

	dowloadConvertFile(encodedData: string, mimeType: string, fileName: string) {
		const byteCharacters = atob(encodedData);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: mimeType });
		const url = window.URL.createObjectURL(blob);
	
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	  }

	  public addDays(date: Date, days: number): Date {
		let result = new Date(date);
		result.setDate(date.getDate() + days);
		return result;
	}

	public onlyUnique(value: any, index: number, array: any) {
		return array.indexOf(value) === index;
	}
}
