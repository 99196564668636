export class getProjectModel {
    active!: number;
}

export class ProjectListModel {
    progress : Array<ProjectModel> = [];
    finished : Array<ProjectModel> = [];
    inactive : Array<ProjectModel> = [];
    pastyears: any = {};
}

export class ProjectModel {
    id!:number;
    name!:string;
    nombre!:string;
    date!:string;
    folder!:string;
    locked!:number;
    notif!:number;
    active!:number;
    status!:number;
    owner!:string;
    selected_cutoff_id!:number;
}

export class editProjectModel{
    id_proj!: number;
    name! : string;
    date!: string;
    locked! : number;
    active! : number;
    id_compania!: number;
}

export class newProjectModel {
    name!: string;
    date!: string;
    id_compania!: number;
}

export class ProjectCampaignModel {
    id!:number;
    name!:string;
    status!:number;
    date!:string;
    owner!:string;
    selected_cutoff_id!:number;
}

export class ProjectCampListModel {
    project!: ProjectModel;
    progress : Array<ProjectCampaignModel> = [];
    finished : Array<ProjectCampaignModel> = [];
}

export class POPMaterial {
    name!: string;
    type!: string;
    priority!: number | null;
    placements!: number;
    branchFilter: boolean = false;
}

export class ProjectMedia {
    allowEmptyBranches!: boolean;
    allowMultipleItems!: boolean;
    name!: string;
    popMaterials: Array<POPMaterial> = [];
    segmentable!: boolean;
    formato! : string;
}

export class ProjectPhase {
    id!: number;
    id_campana_proyecto!: number;
    nombre!: string;
    name!: string;
    t0!: Date;
    scheluded_time_days!: number;
    vobos!: Array<ProjectVobo>;
    deleted!: number;
}

export class ProjectVobo {
    id!: number;
    id_campana_proyecto!: number;
    phase_id!: number;
    area!: string;
    is_provider_vobo! : boolean;
    scheluded_time_days!: number;
    vobos!: any;
    users!: any;
    deleted!: number;
}