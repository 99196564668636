import { Component, Inject, OnInit, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectDiagramationVobosConfigComponent } from '../project-diagramation-vobos-config/project-diagramation-vobos-config.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import { CutoffService } from 'src/app/services/cutoff.service';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectVobo } from 'src/app/models/project.model';
import { MatStepper } from '@angular/material/stepper';
import * as ExcelJS from 'exceljs';

@Component({
  selector: 'app-project-diagramation-add',
  templateUrl: './project-diagramation-add.component.html',
  styleUrls: ['./project-diagramation-add.component.scss']
})
export class ProjectDiagramationAddComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild("paginatorSuc") paginatorSuc!: MatPaginator;
  @ViewChild("sortSuc") sortSuc!: MatSort;
  @ViewChild('stepper') myStepper!: MatStepper;
  
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  displayedColumns: string[] = [];
  allColumns: string[] = [];
  displayedColumnsStore: string[] = [];
  allColumnsStore: string[] = [];
  id_proy: number = 0;
  id_camp: number = 0;
  medios: any = [];
  elementos: any = [];
  materiales_dist: any = [];
  hasPositions: boolean = false;
  min_pos: number = 1;
  max_pos: number = 1;
  promotionFile: any = [];
  dspromotionFile: any = [];
  dsstoreFile: any = [];
  storeFile: any = [];
  stores_sel: any = [];
  pop_sel!: any;
  medio_sel!: any;
  config_sel!: any;
  cant_sel: number = 0;
  position_sel: number = 1;
  def_fields_stores: any = ["id", "Plaza", "FarmID", "Filial", "Nombre"];
  attr_cant!: string;
  arr_cant: any = [];
  cantidad_total: number = 0;
  columnas_total: any = {};
  reglas: any = {};
  arr_reglas: any = [];
  cant_regla: number = 1;
  cant_type!: number; //1 - cantidad fija, 2 - atributo, 3 - prioridad,   
  phases!: any;
  vobos_sel: any = [];
  curr_diagram!: any;
  curr_diagram_sim!: any;
  diagram_sim_pop!: any;
  same_stores: boolean = false;
  edit: boolean = false;
  config_positions: any = [];
  projectDiagramations: any= new Array<ProjectVobo>();
  segmentable: boolean = true;
  sucursales_vacias : boolean = true;
  dt_totales:any = [];
  loaded : boolean = false;
  precio_sel: any = [];
  extra_stores_data: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    public dialogRef: MatDialogRef<ProjectDiagramationAddComponent>,
    private fb: FormBuilder,  
    private _router: Router,  
    private projectsS: ProjectsService,
    private main: MainService,
    private _cutoffS : CutoffService,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef) { 
      this.projectDiagramations = data.data;
      this.id_proy = data.id_proy;
      this.id_camp = data.projectData.id_proy_camp;
      this.phases = data.phases;
      this.config_positions = data.config_positions;
      this.curr_diagram = data.curr_diagram;
      this.curr_diagram_sim = this.projectDiagramations.filter((e:any)=>e.id_medio==this.curr_diagram.id_medio&&e.id_pop==this.curr_diagram.id_pop&&e.posicion==this.curr_diagram.posicion);
      if(this.curr_diagram) this.edit = true;
      this._cutoffS.checkIfSameProjectThenReplace(this.id_camp).then(x=>{
        this.loaded = true;
      });
      console.log("diagramacion",this.curr_diagram_sim, this.config_positions );
    }

  poplist: Array<any> = [
    { id: 1, media_id: 1, title: 'Dangler', info: [['Posiciones', '-'], ['Prioridad', '-']], rules: []},
  ];

  selectedPromotions: string[] = [];  // Lista de códigos seleccionados
  selectedStores: any[] = [];  // Lista de sucursales seleccionados

  ngOnInit(): void {
    this.firstFormGroup = this.fb.group({
      medio: ['', Validators.required],
      elemento_pop : ['', Validators.required],
      position: ['1', Validators.required],//pendiente agregar validacion de position con customValidPosition
    });
    this.secondFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required],
    });
    this.getMedios();
    this.getPromotions();
    this.getExtraStoreData();
  }

  getMedios() {
    this.main.ShowLoading();
    //this.hasPositions = false;
    this.projectsS.getMedios(this.id_proy).subscribe((x: any) => {
      this.medios = x.data.medios;
      this.main.HideLoading();
      if(this.edit) { 
        this.medioSelected(this.curr_diagram.id_medio);
      }
      //this.loaded = true;
    },
    (err:any) => {
      console.log(err);
          this.main.HideLoading();
    })
  }

  getPops(id_medio: number) {
    this.main.ShowLoading();
    //this.hasPositions = false;
    this.projectsS.getPOPs(id_medio).subscribe((x: any) => {
      const created_diagramations = this.projectDiagramations.filter((e:any)=>e.id_medio==this.medio_sel.id);
      const used_el = x.data.elementos_pop.map((e:any)=>{ const f = created_diagramations.filter((a:any)=> a.pop === e.nombre); return Object.assign({ pop : e.nombre, positions : f.length }) })
      if(this.edit){
        this.elementos = x.data.elementos_pop;
      }else{
        this.elementos = x.data.elementos_pop.filter((e:any)=>{ const f = used_el.find((a:any)=> a.pop == e.nombre); return (!e.posiciones || e.posiciones == 0) || (f.positions < e.posiciones)});
      }
      this.main.HideLoading();
      if( this.elementos.length == 0){
        Swal.fire({
          icon: 'warning',
          title: 'Aviso',
          text: 'No hay elementos disponibles para diagramar en este medio.'
        });
        const curr_data = { 
          medio: 0, 
          elemento_pop: 0,
          position: 0
        };
  
        this.firstFormGroup.setValue(curr_data);

      }
      if( this.elementos.length == 1){
        this.firstFormGroup.get('elemento_pop')?.setValue(this.elementos[0].id);
        this.popSelected(this.elementos[0].id);
      }

      if(this.edit) { 
        this.popSelected(this.curr_diagram.id_pop);
      }
      //this.loaded = true;
    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }

  addSpecialFiltersPromo(){
    this.dspromotionFile.filterPredicate = (data : any, filter :any) => {
      const filters = JSON.parse(filter); 

      for (const column in filters) {
        if (filters[column] && data[column]) {
          const columnValue = data[column].toString().toLowerCase();
          const filterValue = filters[column];
          if (!columnValue.includes(filterValue)) {
            return false; 
          }
        }
      }
      return true; 
    };
  }
  
  addSpecialFiltersStores(){
    this.dsstoreFile.filterPredicate = (data : any, filter :any) => {
      const filters = JSON.parse(filter); 

      for (const column in filters) {
        if (filters[column] && data[column]) {
          const columnValue = data[column].toString().toLowerCase();
          const filterValue = filters[column];
          if (!columnValue.includes(filterValue)) {
            return false; 
          }
        }
      }
      return true; 
    };
  }

  getPromotions() {
    this.main.ShowLoading();
    this.projectsS.getPromotionData(this.id_camp).subscribe((x: any) => {
      this.promotionFile = x.data;
      this.dspromotionFile = new MatTableDataSource(x.data);
      this.dspromotionFile.paginator = this.paginator;
      this.dspromotionFile.sort = this.sort;
      this.addSpecialFiltersPromo();

      this.displayedColumns = Object.keys(this.promotionFile[0]).filter(key => key && key.trim() !== '');
      this.allColumns = ['select', ...this.displayedColumns];
      this.main.HideLoading(); 
      this.getMundos()
      this.setPromotionsFilter();
    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }

  getExtraStoreData() {
    this.main.ShowLoading();
    this.projectsS.getExtraSucData(this.id_camp).subscribe(
      (result:any) => {
        this.main.HideLoading();
       if(result.message == "Success") { 
        this.extra_stores_data = result.data;
        console.log(this.extra_stores_data);
       }
      },
      (err:any) => {
        this.main.HideLoading();
        console.log(err);
      }
    )
  }  

  getInventory(){
    this.main.ShowLoading();
    this.projectsS.getProjectInvTotals(this.id_proy).subscribe(
      (result:any) => {
       this.main.HideLoading();
       if(result.message == "Success") {

            let columnas_sum:any = {};
            let columnas_total:any = {};
            let arr_reglas:any = [];
            let arr_cant:any = [];
            const def_fields_stores:any = ["id", "Plaza", "FarmID", "Filial", "Nombre"];
            console.log("pure data",result);
            result.totales.filter((e:any)=>e.id_medio==this.medio_sel.id).map((config:any)=> {
              if(config.formato == 3) {
                const r = JSON.parse(config.prioridad_filtro)[0];
                r.cant_type = 4; // de por maximos
                r.pop_config = config.pop_config;
                r.precio = config.precio;
                r.id_pop_config = config.id_pc;
                arr_reglas.push(r);
               }else if(config.formato == 2) {
                    const r = JSON.parse(config.prioridad_filtro)[0];
                    r.cant_type = 3;
                    r.pop_config = config.pop_config;
                    r.precio = config.precio;
                    r.id_pop_config = config.id_pc;
                    arr_reglas.push(r);
                }
                else if(!config.reglas_distribucion || config.reglas_distribucion.length == 0) {
                    const r = {cant_type: 1, multiplicador: 1, id_pop_config: config.id_pc, pop_config: config.pop_config, precio: config.precio};
                    arr_reglas.push(r);
                }
                else {
                    const r = JSON.parse(config.reglas_distribucion)[0];
                    r.cant_type = 2;
                    r.pop_config = config.pop_config;
                    r.precio = config.precio;
                    r.id_pop_config = config.id_pc;
                    arr_reglas.push(r);
                }
            });
            
            let arr_fields_stores:any = [];
            arr_reglas.map((e:any)=> {
              if(e.nombre) {
                arr_fields_stores.push(e.nombre);
                const obj_config_sel = result.totales.find((t:any)=> {
                  const rd = t.reglas_distribucion?JSON.parse(t.reglas_distribucion):[];
                  return t.id_pop==this.pop_sel.id && (rd.length==0||rd.some((r:any)=>r.nombre==e.nombre))
                });
                const obj_curr_precio = this.curr_diagram?this.curr_diagram.pop_config.find((pc:any)=> pc.id_pop_config==obj_config_sel.id_pc):null;
  
                if(!arr_cant.some((ac:any)=> ac.atributo==e.nombre)) {
                  arr_cant.push({
                    pop_config: obj_config_sel?.pop_config?obj_config_sel.pop_config:e.pop_config,
                    id_pop_config: obj_config_sel?.id_pc?obj_config_sel.id_pc:e.id_pop_config,
                    atributo: e.nombre,
                    cantidad: parseInt(e.multiplicador),
                    tipo: e.cant_type,
                    precio: obj_curr_precio&&obj_curr_precio.precio?obj_curr_precio.precio:obj_config_sel?.precio
                  });
                }
              }
            });
            const arr_fields_totals = arr_fields_stores;
            arr_fields_stores = def_fields_stores.concat(arr_fields_stores);
            
            let filtros:any = [];
            if(result.totales.pop_sucursales_filtro) filtros = JSON.parse(result.totales.pop_sucursales_filtro);
            if(result.totales.sucursales_filtro) filtros = [ ...filtros, ...JSON.parse(result.totales.sucursales_filtro)];
            
            const storeFile:any = this._cutoffS.filtrarSucursales(filtros).map((e:any)=>{ 
                let obj_store:any = {};
                arr_fields_stores.map((key:string)=> {
                
                  if(this.arr_reglas.some((reg:any) => reg.cant_type === 4)){
                    //reglas tipo 4
                    obj_store[key]= e[key] || 0;
                  }else{
                    obj_store[key]= e[key] || 0;
                  }

                });
                return obj_store;
            });

            arr_fields_stores.map((key:string)=> {
              columnas_sum[key] = this._cutoffS.project_cutoff_file_data.map((e:any)=>e[key]).filter((e:any)=>e).map((e:any)=>parseInt(e)).reduce((a:any, b:any) => a + b, 0);
            });
            arr_cant.map((e:any)=> { 
                let cantidad = 0;
                let sel_data = result.data.find((rd:any)=>rd.pop==e.pop_config);
                if(e.tipo == 1) {
                  cantidad = storeFile.length*e.cantidad;
                }else if (e.tipo == 4){
                 //Se supone que e.atributo es el maximo, por lo tanto sumo el valor que trae en el archivo 
                 cantidad = storeFile.reduce((total: number, sf: any) => {
                  return total + Number(sf[e.atributo]);
                  }, 0);
                } 
                else {
                  cantidad = columnas_sum[e.atributo]?columnas_sum[e.atributo]*e.cantidad:0;
                }
                const sel = sel_data?sel_data.cantidad:0;
                const disp = cantidad-sel;
                // const curr_config = result.totales.find((t:any)=> {
                //   const reglas = t.reglas_distribucion?JSON.parse(t.reglas_distribucion):[];
                //   t.id_pop==this.pop_sel.id
                // })
                this.dt_totales.push({
                  id_pop_config: e.id_pop_config,
                  atributo: e.atributo,
                  inventario: cantidad,
                  disponible: disp,
                  seleccionado: sel,
                  precio: e.precio
                }); 
            });

            this.dt_totales = Array.from(
              new Map(this.dt_totales.map((item: any) => [item.atributo, item])).values()
            );

            //sumo para poder hacer el juego de calcular "en vivo" estos totales
             this.dt_totales.forEach((total : any) => {
              total.disponible = total.disponible + this.findMaterialTotal(total.atributo);
            });
          
            console.log(this.dt_totales, "on get invotory")
          }
      },
      (err:any) => {
        this.main.HideLoading();
        console.log(err);
      }
    );
  }

  getPositions(el_pops: any) {
    const sel_pops = this.elementos.find((e:any)=>e.id == el_pops);

    if(sel_pops?.posiciones) {
      this.max_pos = sel_pops.posiciones;
      this.hasPositions = true;
    }
    else {
      this.max_pos = 1;
      this.hasPositions = false;
    }
  }
  
  medioSelected(id_media: number) {
    this.materiales_dist = [];
    this.medio_sel = this.medios.find((e:any)=>e.id==id_media);
    this.getPops(id_media);
  }



  popSelected(el_pops: any) {
    this.main.ShowLoading();
    this.pop_sel = this.elementos.find((e:any)=>e.id==el_pops);
    this.segmentable = this.medio_sel.segmentable==1;
    this.sucursales_vacias = this.medio_sel.sucursales_vacias;
  
    this.diagram_sim_pop = this.projectDiagramations.filter((e:any)=>e.id_medio==this.medio_sel.id&&e.id_pop==el_pops);
    if(this.diagram_sim_pop.length > 0) this.same_stores = true;
    else this.same_stores = false;
    this.getPositions(el_pops);
    this.projectsS.getPOPConfigs(el_pops).subscribe((x: any) => {
      this.materiales_dist = x.data.configs;
      if(this.edit) { 
        this.setData();
      }
      this.main.HideLoading(); 
    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }

  positionSelected(pos: any) {
    this.position_sel = parseInt(pos.target.value);
  }

  configSelected() {
    const columnas_sum:any = {};
    this.arr_reglas = [];
    
      this.materiales_dist.map((config:any)=> {
        const config_sel = config;
        if(this.medio_sel.formato == 3) {
          const r = JSON.parse(this.medio_sel.prioridad_filtro)[0];
          r.cant_type = 4; // de por maximos
          r.id_pop_config = config_sel.id;
          this.arr_reglas.push(r);
          // arr_reglas.push(r);
         }else if(this.medio_sel.formato == 2) {
          const r = JSON.parse(this.medio_sel.prioridad_filtro)[0];
          r.cant_type = 3;
          r.id_pop_config = config_sel.id;
          this.arr_reglas.push(r);
         }
          else if(!config_sel.reglas_distribucion || config_sel.reglas_distribucion.length == 0) {
          const r = {cant_type: 1, multiplicador: this.cant_regla, id_pop_config: config_sel.id};
          this.arr_reglas.push(r);
         }
        else {
          const r = JSON.parse(config_sel.reglas_distribucion)[0];
          r.cant_type = 2;
          r.id_pop_config = config_sel.id;
          this.arr_reglas.push(r);
        }
      });
    

    let arr_fields_stores:any = [];
    this.arr_reglas.map((e:any)=> {
      if(e.nombre) {
        arr_fields_stores.push(e.nombre);
        this.arr_cant.push({
          id_pc: e.id_pop_config,
          atributo: e.nombre,
          cantidad: e.multiplicador,
          tipo: e.cant_type
        });
      }
    });
    const arr_fields_totals = arr_fields_stores;
    arr_fields_stores = this.def_fields_stores.concat(arr_fields_stores);
    let filtros: any = [];
    if(this.pop_sel.sucursales_filtro) filtros = JSON.parse(this.pop_sel.sucursales_filtro);
    if(this.medio_sel.sucursales_filtro) filtros = [ ...filtros, ...JSON.parse(this.medio_sel.sucursales_filtro)];
    this.storeFile = this._cutoffS.filtrarSucursales(filtros).map((e:any)=>{ 
      let obj_store:any = {};
      arr_fields_stores.map((key:string)=> {
        if(arr_fields_totals.includes(key)) {
          if(this.arr_reglas.some((reg:any) => key == reg.nombre && reg.cant_type === 4)){
           // let valorStore = 0;
           // valorStore = parseInt(this.arr_reglas.filter((reg:any) => reg.nombre && reg.cant_type === 4)[0].multiplicador);
           // columnas_sum[key] = Number((columnas_sum[key]?columnas_sum[key]:0)) + Number(valorStore);
           // obj_store[key]= valorStore;
           columnas_sum[key] =  Number((columnas_sum[key]?columnas_sum[key]:0)) + Number((e[key] || 0));
           obj_store[key]= e[key] || 0;
          }else{
            columnas_sum[key] =  Number((columnas_sum[key]?columnas_sum[key]:0)) + Number((e[key] || 0));
            obj_store[key]= e[key] || 0;
          }
          //console.log(key,   columnas_sum[key]);
        }else{
          obj_store[key]= e[key] || '';
        }
      
      });
      return obj_store;
    });

    console.log(this.medio_sel.formato);
    if(this.medio_sel.formato === 3 && !this.edit){
      const file_suc_full = this._cutoffS.project_cutoff_file_data;                  
            const ids_suc_sel = this.projectDiagramations.filter((e:any)=>e.id_medio==this.medio_sel.id)
            .filter((e: any) => e.id_medio == this.medio_sel.id)
            .map((d: any) => d.pop_config
              .map((pc: any) => pc.sucursales.map((s: any) => s.id)).flat()
            ).flat();

            const sucursalCantidadMap: Record<number, number> = {};

            this.projectDiagramations.filter((e:any)=>e.id_medio==this.medio_sel.id).forEach((diagram: any) => {
            diagram.pop_config.forEach((pc: any) => {
              pc.sucursales.forEach((s: any) => {
                if (!sucursalCantidadMap[s.id]) {
                  sucursalCantidadMap[s.id] = 0;
                }
                sucursalCantidadMap[s.id] += s.cantidad; // suma cantidades de la misma sucursal
              });
            });
            });

            const sucursalesValidas = ids_suc_sel.filter((id: number) => {
                 const sucursal = file_suc_full.find((s: any) => s.id === id);
                 if (!sucursal) return false; // si no se encuentra en file_suc_full, se excluye
                 // encontrar el atributo con el formato de maximos
                 let atributoMax = Object.keys(sucursal).find(key => key.includes(this.arr_reglas.filter((reg:any) =>  reg.cant_type === 4)[0].nombre)); 
                 if (!atributoMax) return false; // sino tiene atributo max, se excluye
                 // obtener el valor máximo permitido
                 const maxPermitido = sucursal[atributoMax]; 
                 return sucursalCantidadMap[id] <= maxPermitido; // mantener solo sucursales dentro del limite
            });

       
              this.storeFile = this.storeFile.filter((e: any) => !sucursalesValidas.includes(e.id));
              this.storeFile = this.storeFile.filter((e:any) => e[this.arr_reglas.filter((reg:any) =>  reg.cant_type === 4)[0].nombre] > 0);
              console.log("holaa", this.storeFile);
            
    }
  
    if(this.medio_sel.multiples_elementos==0&&!this.edit) {
      const ids_suc_sel = this.projectDiagramations.filter((e:any)=>e.id_medio==this.medio_sel.id).map((d:any)=>d.pop_config.map((pc:any)=>pc.sucursales.map((s:any)=>s.id)).flat()).flat();
      if(ids_suc_sel.length>0) {
        this.storeFile = this.storeFile.filter((e:any)=>!ids_suc_sel.includes(e.id));
      }
    }
  

    this.storeFile = this.storeFile.map((e:any)=> {
      const extra_stores = this.extra_stores_data.find((x:any)=> x.id==e.id);
      return Object.assign(extra_stores?extra_stores:{}, e);
    })
    console.log("adios", this.storeFile);
    if (this.storeFile.length > 0) {
      this.dsstoreFile = new MatTableDataSource(this.storeFile);
      this.dsstoreFile.paginator = this.paginatorSuc;
      this.dsstoreFile.sort = this.sortSuc;
      this.setSucursalFilter();
      this.addSpecialFiltersStores();
      this.getPlazas();

      this.displayedColumnsStore = Object.keys(this.storeFile[0]).filter(key => key && !key.trim().includes("id"));
      this.allColumnsStore = ['select', ...this.displayedColumnsStore];
    }

    this.arr_cant.map((e:any)=> { 
      if(e.tipo == 1) {
        this.columnas_total[e.atributo] = this.storeFile.length*e.cantidad;
      }else if(e.tipo == 4) {
        this.columnas_total[e.atributo] = this.storeFile.length*e.cantidad;
      }
      else { 
        this.columnas_total[e.atributo] = columnas_sum[e.atributo]?columnas_sum[e.atributo]*e.cantidad:0;
      }
    });
    console.log(this.arr_cant, this.arr_reglas);
  }
  
  setData() {
      const curr_data = { 
        medio: this.curr_diagram.id_medio, 
        elemento_pop: this.curr_diagram.id_pop,
        position: this.curr_diagram.posicion
      };

      this.firstFormGroup.setValue(curr_data);
      this.selectedPromotions = this.curr_diagram.promociones;
  }

  customValidPosition(control: FormControl): void {
    const positions = this.diagram_sim_pop.map((e:any)=>e.posicion);
    if(positions.includes(this.position_sel)) {
      setTimeout(() => {
          control.setErrors({ myCustomError: true });
      });
    }
  }

  getMedioDescripcion(){
    return this.medios
    .find((x: any) => x.id == this.firstFormGroup.get('medio')?.value)
    ?.descripcion ?? '';
  }


  setSecondData() {
    this.getInventory();
    this.popSelected(this.firstFormGroup.get("elemento_pop")?.value);
    const positions = this.diagram_sim_pop.map((e:any)=>e.posicion);
    if(!this.edit &&( (positions.length == this.pop_sel.posiciones && (this.pop_sel.posiciones > 0)) || (this.pop_sel.posiciones>0&&!this.edit&&positions.includes(this.position_sel)))){
      Swal.fire({
        icon: 'warning',
        title: 'Datos incorrectos',
        text: 'Ya existe una diagramación con los datos seleccionados.'
      });
      this.myStepper.selectedIndex = 0;
    } else  {
      this.configSelected();
      if(!this.segmentable) {
        //aqui selecciona todos sino hace reglas
        this.toggleSelectionStore(0,{ target: { checked: true }});
      }
      else if(this.same_stores) {
        this.stores_sel = this.diagram_sim_pop[0].pop_config.map((e:any)=> Object.assign({ id_pc: e.id_pop_config, sucursales: e.sucursales, total: e.cantidad }));
        this.selectedStores = this.diagram_sim_pop[0].pop_config.map((e:any)=>e.sucursales.map((s:any)=>s.id)).flat().filter(this.main.onlyUnique);
      }
      else {
        this.stores_sel = [];
        this.selectedStores = [];
      }
      if(this.edit) { 
        this.vobos_sel = this.curr_diagram.config_vobos;
        this.phases.map((e:any)=> {
          const obj_vobo = this.vobos_sel.some((v:any)=>v.id==e.id);
          if(obj_vobo) e.selected = true;
        });
        
        this.stores_sel = this.curr_diagram_sim[0].pop_config.map((e:any)=> Object.assign({ id_diagrama: e.id, id_pc: e.id_pop_config, sucursales: e.sucursales, total: e.cantidad }));
        this.selectedStores = this.curr_diagram_sim[0].pop_config.map((e:any)=>e.sucursales.map((s:any)=>s.id)).flat().filter(this.main.onlyUnique);
        this.preLoadMaterialInfo();
      }
    }
  }


  codigoFilter : string = "";
  mundoFilter : any = "";
  plazasFilter : any = "";

  mundosList : Array<any> = [];
  plazasList : Array<any> = [];

  getMundos(){
    let mundos = this.promotionFile.map((item : any) => item.MUNDO);
    this.mundosList = [...new Set(mundos)];
  }

  getPlazas(){
    let plaza = this.storeFile.map((item : any) => item.Plaza);
    this.plazasList = [...new Set(plaza)].sort((a : any, b :any) => a.localeCompare(b));;
  }

  setPromotionsFilter(){
    this.dspromotionFile = new MatTableDataSource(this.promotionFile);
    this.dspromotionFile.paginator = this.paginator;
    this.dspromotionFile.sort = this.sort;
    this.addSpecialFiltersPromo();
  }

  setSucursalFilter(){
    this.dsstoreFile = new MatTableDataSource(this.storeFile);
    this.dsstoreFile.paginator = this.paginatorSuc;
    this.dsstoreFile.sort = this.sortSuc;
    this.addSpecialFiltersStores();
  }

  //filterPlaza(event : any){
  //  this.setSucursalFilter()
  //  this.plazasFilter = event;
  //  if(event != ""){
  //    let storeFileFiltered = [];
  //    storeFileFiltered = this.storeFile.filter((x:any)=> x.Plaza.toString() ==  this.plazasFilter);
  //    this.dsstoreFile = new MatTableDataSource(storeFileFiltered);
  //    this.dsstoreFile.paginator = this.paginatorSuc;
  //    this.dsstoreFile.sort = this.sortSuc;
  //    this.addSpecialFiltersStores();
  //  }
  //}
  
  filtrarPromotions(){
      this.setPromotionsFilter();
      //let promotionFileFiltered = [];
      //promotionFileFiltered = this.promotionFile.filter((x:any)=> x["CODIGO PRINCIPAL"].toString().includes(this.codigoFilter));
      //this.dspromotionFile = new MatTableDataSource(promotionFileFiltered);
  }

  toggleSelection(codigo: string): void {
    const index = this.selectedPromotions.indexOf(codigo);
    if (index > -1) {
      this.selectedPromotions.splice(index, 1);  
    } else {
      this.selectedPromotions.push(codigo); 
    }
  }

  selectAllValue : boolean = false;
  selectAll(event: any): void {
    if (event.target.checked) {
    let nuevosIds = this.dspromotionFile.filteredData.map((promo: any) => promo['CODIGO PRINCIPAL']);
    nuevosIds.forEach((id: any) => {
      if (!this.selectedPromotions.includes(id)) {
        this.selectedPromotions.push(id);
      }
    });
    } else {
      let idsFiltrados = this.dspromotionFile.filteredData.map((promo: any) => promo['CODIGO PRINCIPAL']);
      this.selectedPromotions = this.selectedPromotions.filter((id: any) => !idsFiltrados.includes(id));
    }
  }

  toggleValue : boolean = false;
  toggleSelectionStore(id: number, event: any): void {
    let index = 0;
    this.stores_sel = [];
    if(id>0){
      index = this.selectedStores.indexOf(id);
      if (index > -1) {
        this.selectedStores.splice(index, 1);
      } else {
        this.selectedStores.push(id); 
      }
    }
    else {
      if (event.target.checked) {
        let nuevosIds = this.dsstoreFile.filteredData.map((store: any) => store['id']);
        nuevosIds.forEach((id: any) => {
          if (!this.selectedStores.includes(id)) {
            this.selectedStores.push(id);
          }
        });
      } else {
        let idsFiltrados = this.dsstoreFile.filteredData.map((store: any) => store['id']);
        this.selectedStores = this.selectedStores.filter((id: any) => !idsFiltrados.includes(id));
      }
    }

    let columnas_total_sel:any = {};
    const sel_sf = this.storeFile.filter((e:any)=> this.selectedStores.includes(e.id));
    this.arr_cant.map((e:any)=> {
      const ob_pc: any = { id_pc: e.id_pc, sucursales: [], total: 0, atributo: e.atributo }
      const get_diagram = this.curr_diagram_sim.find((d:any)=>d.id_pop_config==e.id_pc);
      if(get_diagram) {
        ob_pc.id_diagrama = get_diagram.id;
      }
      
      sel_sf.map((sf:any)=> {
        let cantidad = 0;
        if(e.tipo == 1) {
          cantidad = e.cantidad;
        }
        else if(e.tipo == 2) { 
          cantidad = (sf[e.atributo]?parseInt(sf[e.atributo]):0)*e.cantidad;
        }
        else if(e.tipo == 3) { 
          const cant_pops = this.elementos.length;
          const cant_total_suc = (sf[e.atributo]?parseInt(sf[e.atributo]):0)*e.cantidad;
          const modulo = cant_total_suc%cant_pops;
          const max = Math.ceil(cant_total_suc/cant_pops);
          const min = Math.floor(cant_total_suc/cant_pops);
          const prioridad = this.pop_sel.prioridad;
          cantidad = prioridad<=modulo?max:min; 

        }else if(e.tipo == 4) { 
          
          let sumaSucursalTotal : number = 0;
          let maxValue = this.storeFile.filter((x:any) => x.id == sf.id)[0][this.arr_reglas.filter((reg:any) =>  reg.cant_type === 4)[0].nombre];
          this.projectDiagramations.filter((e:any)=>e.id_medio==this.medio_sel.id).forEach((diagram: any) => {
          diagram.pop_config.forEach((pc: any) => {
            pc.sucursales.filter((s:any)=> s.id == sf.id).forEach((s: any) => {
              sumaSucursalTotal += s.cantidad; // suma cantidades de la sucursal seleccionada
              });
            });
          });

          let valueToAdd = e.cantidad > maxValue ? maxValue : e.cantidad;

          if((sumaSucursalTotal + valueToAdd) > maxValue){
            cantidad = (sumaSucursalTotal + valueToAdd) - maxValue;
          }else{
            cantidad = valueToAdd;
          }
          //redo: reglas de tipo 4
        }
        //this.cant_sel += cantidad;
        columnas_total_sel[e.attributo] += cantidad;

        if(cantidad > 0) {
          ob_pc.sucursales.push({id: sf.id, cantidad});
          //ob_pc.total += cantidad;
       
          ob_pc.total = Number(ob_pc.total) + Number(cantidad); 
        }
      });

      if(ob_pc.sucursales.length > 0) {
        this.stores_sel.push(ob_pc);
      }
    });
    if (id>0) {
      index = this.selectedStores.indexOf(id);
      if(!this.stores_sel.some((s:any)=>s.sucursales.some((suc:any)=>suc.id==id))) {
        this.selectedStores.splice(index, 1);
      }
    }
    this.stores_sel = Array.from(
      new Map(this.stores_sel.map((item: any) => [item.atributo, item])).values()
    );
    this.selectedStores = this.stores_sel.map((e:any)=>e.sucursales.map((s:any)=>s.id)).flat().filter(this.main.onlyUnique);

    console.log(this.columnas_total);//inventario total
    console.log(this.stores_sel);//seleccionado
  }
  preloaded = false;
  preLoadMaterialInfo(){
      this.stores_sel = [];
      let columnas_total_sel:any = {};
      const sel_sf = this.storeFile.filter((e:any)=> this.selectedStores.includes(e.id));
      this.arr_cant.map((e:any)=> {
        const ob_pc: any = { id_pc: e.id_pc, sucursales: [], total: 0, atributo: e.atributo }
        const get_diagram = this.curr_diagram_sim.find((d:any)=>d.id_pop_config==e.id_pc);
        if(get_diagram) {
          ob_pc.id_diagrama = get_diagram.id;
        }
        
        sel_sf.map((sf:any)=> {
          let cantidad = 0;
          if(e.tipo == 1) {
            cantidad = e.cantidad;
          }
          else if(e.tipo == 2) { 
            cantidad = (sf[e.atributo]?parseInt(sf[e.atributo]):0)*e.cantidad;
          }
          else if(e.tipo == 3) { 
            const cant_pops = this.elementos.length;
            const cant_total_suc = (sf[e.atributo]?parseInt(sf[e.atributo]):0)*e.cantidad;
            const modulo = cant_total_suc%cant_pops;
            const max = Math.ceil(cant_total_suc/cant_pops);
            const min = Math.floor(cant_total_suc/cant_pops);
            const prioridad = this.pop_sel.prioridad;
            cantidad = prioridad<=modulo?max:min;
          }else if(e.tipo == 4) { 
            cantidad = e.cantidad;
            //añadir regla extra*?
          }
          //this.cant_sel += cantidad;
          columnas_total_sel[e.attributo] += cantidad;
  
          if(cantidad > 0) {
            ob_pc.sucursales.push({id: sf.id, cantidad});
            ob_pc.total += cantidad;
          }
        });
  
        if(ob_pc.sucursales.length > 0) {
          this.stores_sel.push(ob_pc);
        }
      });
      this.selectedStores = this.stores_sel.map((e:any)=>e.sucursales.map((s:any)=>s.id)).flat().filter(this.main.onlyUnique);
      this.stores_sel = Array.from(
        new Map(this.stores_sel.map((item: any) => [item.atributo, item])).values()
      );
      console.log("totales",this.columnas_total);//inventario total
      console.log("seleccionado", this.stores_sel);//seleccionado
      this.preloaded = true;
  }

  setColumnasTotalArray(): { material: string; total: number }[] {
    return Object.entries(this.columnas_total).map(([key, value]) => ({
      material: key,
      total: Number(value)
    }));
  }


  findMaterialTotal(material_key : any) : number{
    return this.stores_sel.filter((x:any)=> x.atributo === material_key ).length > 0 ? this.stores_sel.filter((x:any)=> x.atributo === material_key )[0].total : 0;
  }

  openVobosDiagramConfigDialog(phase: any) {
    const obj_vobo = this.vobos_sel.find((e:any)=>e.id==phase.id);
    this.matDialog.open(ProjectDiagramationVobosConfigComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        edit : true,
        phase,
        sel_vobos: obj_vobo 
      }
    }).afterClosed().subscribe(
      (resp) => {
        if(resp) {
          this.setVobo(phase.id, resp);
        }
      }
    );
  }

  setVobo(id: number,v: any) {
    let res_obj:any = {};
    res_obj.id = id;
    res_obj.vobos = [];
    const obj_phase = this.phases.find((e:any)=> e.id==id);
    for (const key in v) {
      if (Object.prototype.hasOwnProperty.call(v, key)) {
        const ov = obj_phase.vobos.find((e:any)=> e.area==key);
        res_obj.vobos.push({id: ov.id, users: v[key], area: ov.area});
      }
    }
    obj_phase.selected = true;
    const obj_vobo = this.vobos_sel.find((e:any)=>e.id==id);
    if(obj_vobo) obj_vobo.vobos = res_obj.vobos;
    else this.vobos_sel.push(res_obj);
  }

  setPrecio(id_pop_config: number, event: any) {
    const precio = event.target.value;
    const obj_precio_sel = this.precio_sel.find((e:any)=>e.id_pop_config==id_pop_config);
    if(obj_precio_sel) {
      obj_precio_sel.precio = precio;
    }
    else {
      this.precio_sel.push({id_pop_config, precio});
    }
  }

  goToConfig() {
    const url = '/admin/projects/config/' + this.id_proy;
   window.open(url, '_blank');
  }

  guardarDiagrama() {
    if (this.firstFormGroup.valid 
      && this.selectedPromotions.length > 0 
      && this.stores_sel.length > 0
      && (this.vobos_sel.length == this.phases.length)
    ) {
      const precios = this.dt_totales.map((e:any)=> {
        const obj_precio_sel = this.precio_sel.find((p:any)=>p.id_pop_config==e.id_pop_config);
        if(obj_precio_sel) e.precio = obj_precio_sel.precio;
        return e;
      })

      let sucs_diag = this.projectDiagramations.filter((e:any)=>e.id_medio==this.medio_sel.id).map((diagram: any) =>
          diagram.pop_config.map((pc: any) => pc.sucursales)).flat();
    
      let ins_data = { 
        ...this.firstFormGroup.value,
        id_proyecto: this.id_proy,
        promociones: this.selectedPromotions,
        fases: this.vobos_sel,
        sucursales: this.stores_sel,
        precios
      };

      if( !this.pop_sel.posiciones || this.pop_sel.posiciones  == 0){
        const positions = this.diagram_sim_pop.map((e:any)=>e.posicion);
        ins_data.position = positions.length + 1;
      }

      if(this.edit) {
        ins_data.id_diagrama = this.curr_diagram.id;
        this.projectsS.editProjectDiagram(ins_data).subscribe(
          (result:any) => {
            this.main.HideLoading();
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Creado',
                text: 'Diagrama creado correctamente'          
              }).then(() => {            
                this.dialogRef.close({ reload: true });
              });
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
              });
            }
          },
          (err:any) => {
            this.main.HideLoading();
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        );
      }
      else {
        this.projectsS.newProjectDiagram(ins_data).subscribe(
          (result:any) => {
            this.main.HideLoading();
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Creado',
                text: 'Diagrama creado correctamente'          
              }).then(() => {            
                this.dialogRef.close({ reload: true });
              });
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
              });
            }
          },
          (err:any) => {
            this.main.HideLoading();
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        );
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
  }

  applyFilter(event: Event, ds: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    ds.filter = filterValue.trim().toLowerCase();
    if (ds.paginator) ds.paginator.firstPage();
  }

  columnFiltersPromo: { [key: string]: string } = {};

  applyColumnFilterPromo(column: string, event: Event) {
    this.selectAllValue = false;
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.columnFiltersPromo[column] = filterValue;

    this.dspromotionFile.filter = JSON.stringify(this.columnFiltersPromo);
    if (this.dspromotionFile.paginator) {
      this.dspromotionFile.paginator.firstPage();
    }
    this.dspromotionFile._updateChangeSubscription();
  }

  columnFiltersStores: { [key: string]: string } = {};

  doToggleValue(){
    this.toggleValue = !this.toggleValue;
  }

  doSelectAllValue(){
    this.selectAllValue =  !this.selectAllValue;
  }

  applyColumnFilterStores(column: string, event: Event) {
    this.toggleValue = false;
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.columnFiltersStores[column] = filterValue;

    this.dsstoreFile.filter = JSON.stringify(this.columnFiltersStores);
    if (this.dsstoreFile.paginator) {
      this.dsstoreFile.paginator.firstPage();
    }
    this.dsstoreFile._updateChangeSubscription();
    this.cdr.detectChanges();
  }

  applyColumnFilterStoresSelect(column: string, event: any) {
    this.toggleValue = false;
    const filterValue = event.trim().toLowerCase();
    this.columnFiltersStores[column] = filterValue;

    this.dsstoreFile.filter = JSON.stringify(this.columnFiltersStores);
    if (this.dsstoreFile.paginator) {
      this.dsstoreFile.paginator.firstPage();
    }
    this.dsstoreFile._updateChangeSubscription();
    this.cdr.detectChanges();
  }
  
  farmidsInput : string = "";

  takeFarmIdsInput(){
    const regex = /^(\s*\w+\s*)(,\s*\w+\s*)*$/; 
    if(regex.test(this.farmidsInput)){
      let famIds : Array<any> = [];
      famIds = this.farmidsInput
      .split(',')
      .map(farmid => farmid.trim()) 
      .filter(farmid => farmid !== '');

      this.selectStoresByArray(famIds);
      
      this.farmidsInput = "";
    }else{
      Swal.fire({
        icon: 'warning',
        title: 'No se pudieron seleccionar',
        text: 'El formato indicado no es el correcto, deben ser IDs separados por comas pe."1243,1344,1342"'
      });
    }
  }

  selectStoresByArray(arrayIds : Array<any>){
    this.main.ShowLoading();
    let count = 0;
    arrayIds.forEach(idfarm=>{
      setTimeout(()=>{    
        let farm = this.storeFile.filter((x:any)=>  x.FarmID.toLowerCase() == idfarm.trim().toLowerCase())
         
        if(farm.length != 0){
          let index = this.selectedStores.indexOf(farm[0].id);
          if (index > -1) {
            //hacer algo
          } else {
            this.toggleSelectionStore(farm[0].id, { target: { checked: true }});
          }
        }    
          
        count ++;
        if(arrayIds.length == count){
          this.main.HideLoading();
        }
      }, 20);
    });
  }

  async onExcelSelected(event: any) {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);
      
      const worksheet = workbook.worksheets[0]; 

      this.extractFarmIDs(worksheet);
    };

    reader.readAsArrayBuffer(file);
    event.target.value = null;

  }

  extractFarmIDs(worksheet: ExcelJS.Worksheet) {
    const farmIdColumnName = 'FarmID'; 
    let columnIdx: number | undefined;

    worksheet.getRow(1).eachCell((cell, colNumber) => {
      if (cell.value === farmIdColumnName) {
        columnIdx = colNumber;
      }
    });

    if (!columnIdx) {
      Swal.fire({
        icon: 'warning',
        title: 'No se pudieron seleccionar las sucursales',
        text: 'El archivo no contiene la columna FarmID'
      });
      return;
    }

    let farmIdsArray: Array<any> = [];
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) { 
        const farmID = row.getCell(columnIdx!).value;
        if (farmID) {
          farmIdsArray.push(String(farmID));
        }
      }
    });

    this.selectStoresByArray(farmIdsArray);
    console.log('FarmIDs añadidos:', farmIdsArray);
  }

}