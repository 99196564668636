<div class="modal-header">
    <h4 class="modal-title">Archivos para proveedores - {{ nombreDisplay }}</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <h5 class="pb-4">
            <span class="align-middle">Lista de archivos para proveedores</span>
            <div *ngIf="hasPermissions()" class="float-end align-middle">
                <button mat-flat-button color="primary" class="ms-4" (click)="openDialogAddProvidersFile()">Cargar nuevo archivo
                    <mat-icon>upload</mat-icon>
                </button>
            </div>
        </h5>
        <div *ngIf="hasPermissions()" class="row">
            <div class="col-sm-2" >
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Seleccione Area a denegar</mat-label>
                    <mat-select  matInput  (selectionChange)="changeArea($event.value);" required name="phase">
                      <mat-option *ngFor="let m of vobos" [value]="m">{{ m.area }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-5" style="margin-top: 15px;">
                <button [disabled]="!selected" mat-flat-button color="primary" class="ms-4" (click)="urSureResetVobi()">Regresar Vobo
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </div>

        <div>
            <div class="row g-2">
                <div class="col mb-0">
                    <div class="table-responsive text-nowrap">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Proveedor</th>
                                    <th>Archivo</th>
                                    <th>Autorización</th>
                                    <th>Fecha de Registro</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr *ngFor="let provid of providerSurveyList">
                                    <td>
                                     {{ provid.id }}
                                    </td>
                                    <td>
                                     {{ provid.nombre_provider }}
                                    </td>
                                    <td>
                                        <a target="_blank" href="https://drive.google.com/file/d/{{ provid.archivo }}">
                                            {{ provid.nombre }}
                                         </a>
                                         <button *ngIf="provid.locked == 1" mat-icon-button class="mb-1" disabled="true"  matTooltip="Bloqueado" [matTooltipPosition]="'above'"><mat-icon>lock</mat-icon></button>
                                    </td>
                                    <td>
                                        <span *ngIf="provid.status == 1" class="badge bg-warning">PENDIENTE</span>
                                        <span *ngIf="provid.status == 3"  class="badge bg-danger">NO AUTORIZADO</span>
                                        <span *ngIf="provid.status == 2"  class="badge bg-success">AUTORIZADO</span>
                                    </td>
                                    <td>
                                        {{provid.created_at  | date : "dd/MM/yyyy hh:mma" : "-0000"}}
                                    </td>
                                    <td>
                                        <button mat-icon-button class="mb-1" (click)="openDialogFormHistory(provid.id, provid.nombre)">
                                            <mat-icon>fact_check</mat-icon>
                                        </button>
                                        <button mat-icon-button class="mb-1" (click)="openDialogFileLogs(provid.id)">
                                            <mat-icon>forum</mat-icon>
                                        </button>
                                        <button mat-icon-button *ngIf="provid.status != 2" (click)="openDialogReloadProvidersFile(provid.id, provid.id_provider)" matTooltip="Recargar">
                                            <mat-icon>refresh</mat-icon>
                                        </button>
                                        <button mat-icon-button class="mb-1" [matMenuTriggerFor]="menu1" >
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu1="matMenu">                                            
                                            <button *ngIf="provid.status != 2 && hasPermissions()" (click)="openDialogReSendProvidersFile(provid.id, provid.id_provider)" mat-menu-item>
                                                <mat-icon>send</mat-icon>
                                                <span>Reenviar</span>
                                            </button>
                                            <button (click)="goToPreview(provid.id)" mat-menu-item>
                                                <mat-icon>preview</mat-icon>
                                                <span>Ver</span>
                                            </button>
                                            <button (click)="DownloadElement('https://drive.google.com/file/d/'  +  provid.archivo )" mat-menu-item>
                                                <mat-icon>download</mat-icon>
                                                <span>Descargar</span>
                                            </button>
                                            <button *ngIf="provid.locked == 0 && hasPermissions()" (click)="lockProviderSurv(provid.id, 1)" mat-menu-item>
                                                <mat-icon>disabled_visible</mat-icon>
                                                <span>Bloquear</span>
                                            </button>
                                            <button *ngIf="provid.locked == 1 && hasPermissions()" (click)="lockProviderSurv(provid.id, 0)" mat-menu-item>
                                                <mat-icon>disabled_visible</mat-icon>
                                                <span>Desbloquear</span>
                                            </button>
                                            <button *ngIf="hasPermissions()" (click)="deleteProvSurv(provid.id)" mat-menu-item>
                                                <mat-icon>delete</mat-icon>
                                                <span>Eliminar</span>
                                            </button>
                                        </mat-menu>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer pt-4">
    <button mat-button mat-dialog-close>Cerrar<mat-icon>close</mat-icon></button>
    <!-- <button mat-flat-button class="btn bg-success text-white" form="" type="submit">Cargar Archivo <mat-icon>upload</mat-icon></button> -->
</div>