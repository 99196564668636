<div class="modal-header">
    <h4 class="modal-title" id="modalEditNotifTitle">Safetly Stocks</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body modal-notif">
    <div class="row mt-2">
        <div class="col-sm-12 row">
            <div class="col-sm-6">
                <mat-form-field appearance="fill" style="padding-right: 10px;" class="form-field">
                    <mat-label>Tipo</mat-label>
                    <mat-select  [(ngModel)]="stock_config.tipo"  required name="tipo">
                      <mat-option [value]="1">Enviar a Todos</mat-option>
                      <mat-option [value]="2">Enviar a Mejores</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6" *ngIf="stock_config.tipo == 2" >
                  <mat-form-field style="padding-right: 5px;" appearance="fill" class="form-field">
                      <mat-label>Top</mat-label>
                      <input  matInput placeholder="1" name="top" [(ngModel)]="stock_config.top" required>
                      <mat-icon matSuffix>format_list_numbered</mat-icon>
                  </mat-form-field>
            </div>
            <div class="col-sm-6"  *ngIf="stock_config.tipo != 0">
                <mat-form-field appearance="fill" class="form-field">
                    <mat-label>Cantidad</mat-label>
                    <input  matInput placeholder="1" name="cantidad" [(ngModel)]="stock_config.cantidad" required>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button mat-flat-button class="bg-success text-white" (click)="ruSure();" type="submit">Guardar <mat-icon>save</mat-icon></button>
</div>