import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectsCampaignFileAddComponent } from '../../dialogs/projects-campaign-file-add/projects-campaign-file-add.component';
import { MainService } from 'src/app/services/main.service';
import { ProjectsCampaignCommentsAddComponent } from '../projects-campaign-comments-add/projects-campaign-comments-add.component';
import { ProjectModel, ProjectPhase } from 'src/app/models/project.model';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-projects-art-upload',
  templateUrl: './projects-art-upload.component.html',
  styleUrls: ['./projects-art-upload.component.scss']
})
export class ProjectsArtUploadComponent implements OnInit {
  curr_diagram!: any;
  phase!: ProjectPhase;
  project: ProjectModel = new ProjectModel();
  archivos: any = [];
  next_id: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    public dialogRef: MatDialogRef<ProjectsArtUploadComponent>,
    private projectsS: ProjectsService,
    private matDialog: MatDialog,
    private main : MainService,) { 
      this.curr_diagram = data.diagram;
      this.phase = data.phase;
      this.project = data.project;
    }

  ngOnInit() {
    const next = this.curr_diagram.config_vobos.find((e:any)=>e.vobos.some((v:any)=>(v.status?v.status:0)==0));
    this.next_id = next.id;
    this.getArchivosByPhase();
  }


  openDialogUploadCampaignFile() {
    this.matDialog.open(ProjectsCampaignFileAddComponent, {
      panelClass: ['dialogs-main'],
      data: {
        phase_id : this.phase.id,
        diagram : this.curr_diagram,
        folder : this.project.folder
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.dialogRef.close({ reload: true });
        }
      }
    );
  }


  openDialogCommentNoAuth(phase_id : number, rol_id : number) {
    this.matDialog.open(ProjectsCampaignCommentsAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
          phase_id : phase_id,
          rol_id : rol_id,
          camp_id : 1,
          nombre : "DANGLERS VERSION 1 - FASE 1"
        }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  getArchivosByPhase() {
    this.main.ShowLoading();
    const data = {
        id_diagrama: this.curr_diagram.id,
        id_fase: this.phase.id
    }
    this.projectsS.getdiagramFiles(data).subscribe((x: any) => {
      this.archivos = x.data;
      this.main.HideLoading();
    },
    (err:any) => {
      console.log(err);
          this.main.HideLoading();
    })
  }
}
