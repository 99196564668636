import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectCampaignModel, ProjectModel } from 'src/app/models/project.model';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-diagramation-distribution',
  templateUrl: './projects-diagramation-distribution.component.html',
  styleUrls: ['./projects-diagramation-distribution.component.scss']
})
export class ProjectsDiagramationDistributionComponent implements OnInit {

  displayedColumns: string[] = [];
  allColumns: string[] = [];
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  project: ProjectModel = new ProjectModel();
  campaign: ProjectCampaignModel = new ProjectCampaignModel();
  dataSource: any = [];
  data_dist: any = [];
  metadata: any = [];
  promos : any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private projectService : ProjectsService,
    private main : MainService,
  ) {
    this.project = data.project;
    this.campaign = data.campaign;
    this.promos = data.promos;
   }

  ngOnInit(): void {
    
    this.getDistribution();
  }

  
  getDistribution(){
    this.main.ShowLoading();
    this.projectService.getProjectDistribution(this.project.id, this.campaign.selected_cutoff_id).subscribe(
      (result:any) => {
       this.main.HideLoading();
       if(result.message == "Success") {
          if(result.data.length != 0){
            this.displayedColumns = Object.keys(result.data[0]).filter(key => key && key.trim() !== '');
            this.allColumns = this.displayedColumns;

            this.data_dist = result.data;
            this.metadata = result.metadata;
            this.AgruparZonas();
            this.dataSource = new MatTableDataSource(this.data_dist);        
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

         
          }
        }
      },
      (err:any) => {
        this.main.HideLoading();
        console.log(err);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }





  private AgruparZonas(){
      let groupedData = this.data_dist.reduce((acc : any, curr : any) => {
      let { Zona, Filial, FarmID, Sucursal, ...materials } = curr;
      
      if (!acc[Zona]) {
          acc[Zona] = { Filial: "", FarmID: "", Zona,  Sucursal: Zona, ...Object.fromEntries(Object.keys(materials).map(k => [k, 0])) };
      }
  
      Object.keys(materials).forEach(key => {
          acc[Zona][key] += materials[key];
      });
  
      return acc;
     }, {});

      let result = Object.values(groupedData);

      let data_dist_ext = this.GenerarSafetlyStocks(result, this.metadata);
      this.data_dist = [...this.data_dist , ...data_dist_ext]
      console.log(this.data_dist);
  }


  GenerarSafetlyStocks(zonesDato : any, stockConfigData : any){
    let processedData = JSON.parse(JSON.stringify(zonesDato));
    
    processedData.forEach((zone:any) => {
      let stockRulesGruoup : any = {}
      stockConfigData.forEach((item:any) => {
        if (!stockRulesGruoup[item.id_medio]) {
          stockRulesGruoup[item.id_medio] = [];
        }
        stockRulesGruoup[item.id_medio].push(item);
      });
      let groupedStockData = Object.values(stockRulesGruoup);

      groupedStockData.forEach((stD:any) => {
        stD.forEach((configItem:any) => {
          let materialAnalized = stD.map((mtA : any)=> mtA.pop);
          try {
             
              let config = JSON.parse(configItem.stock_config);
              let materialKey = configItem.pop;
              
              if (zone.hasOwnProperty(materialKey)) {
                  if (config.tipo === 1) { //<-- Tooodos
                     
                      zone[materialKey] = parseInt(config.cantidad);
                  } else if (config.tipo === 2) { //<--- Top
                 
                    
                    let topN = parseInt(config.top);
                    let sortedMaterials = Object.keys(zone)
                        .filter(key => key !== "Zona" && key !== "Sucursal" && key !== "Filial" && key !== "FarmID" && materialAnalized.includes(key))
                        .map(key => ({ key, cantidad: zone[key] }))
                        .sort((a, b) => b.cantidad - a.cantidad)
                        .slice(0, topN);
                    
                   
                    Object.keys(zone).forEach(key => {
                      if (key !== "Zona" && key !== "Sucursal" && key !== "Filial" && key !== "FarmID" && materialAnalized.includes(key)) {
                          zone[key] = 0;
                      }
                     });

                    let assignedKeys = new Set();    
                    for (let mat of sortedMaterials) {
                            zone[mat.key] = parseInt(config.cantidad);
                            assignedKeys.add(mat.key);              
                    }
                   
                  }
              }
          } catch (error) {
          
              if (zone.hasOwnProperty(configItem.pop)) {
                  zone[configItem.pop] = 0;
              }
          }
      });
      });

    });
    
    return processedData;
};


  generateExcelResumen(){
    if(this.data_dist.length == 0){
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text: 'No hay datos de resumen actualmente'          
      });
    }else{
      this.main.generateExcel(this.data_dist, "Distribucion" + this.project.nombre, "CAMPAIGN");
    }
}
}