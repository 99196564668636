import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectsCampaignFileAddComponent } from '../../dialogs/projects-campaign-file-add/projects-campaign-file-add.component';
import { MainService } from 'src/app/services/main.service';
import { ProjectsCampaignCommentsAddComponent } from '../projects-campaign-comments-add/projects-campaign-comments-add.component';
import { ProjectCampaignModel, ProjectModel, ProjectPhase, ProjectVobo } from 'src/app/models/project.model';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';
import { BaseOverlayDispatcher } from '@angular/cdk/overlay/dispatchers/base-overlay-dispatcher';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-project-diagramation-vobos',
  templateUrl: './project-diagramation-vobos.component.html',
  styleUrls: ['./project-diagramation-vobos.component.scss']
})
export class ProjectDiagramationVobosComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = [];
  allColumns: string[] = [];
  dspromotionFile: any = [];
  promotionFile: any = [];

  @ViewChild("paginatorRes") paginatorRes!: MatPaginator;
  @ViewChild("sortRes") sortRes!: MatSort;
  displayedColumnsRes: string[] = [];
  allColumnsRes: string[] = [];
  dsResFile: any = [];
  resFile: any = [];

  curr_diagram!: any;
  phase!: ProjectPhase;
  vobo!: ProjectVobo;
  project: ProjectModel = new ProjectModel();
  campaign: ProjectCampaignModel = new ProjectCampaignModel();
  archivos: any = [];
  archivo_c: any = {};
  form_vb: any = [];
  form_ans: any = [];
  comentario: string = "";
  public sanitizedSource!: SafeResourceUrl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    public dialogRef: MatDialogRef<ProjectDiagramationVobosComponent>,
    private projectsS: ProjectsService,
    private matDialog: MatDialog,
    private main : MainService,
    public sanitizer: DomSanitizer
  ) {
    this.curr_diagram = data.diagram;
      this.phase = data.phase;
      this.vobo = data.vobo;
      this.project = data.project;
      this.form_vb = data.form_vb;
      this.campaign = data.campaign;
   }

  ngOnInit(): void {
    this.getArchivosByPhase();
    this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
    this.getPromotions();
    this.getRespuestas();
  }
  
  getSourceURL(): SafeResourceUrl {
    return this.sanitizedSource;
  }

  setAnswer(q: any, value: boolean) {
    if(this.form_ans.some((e:any)=>e.id==q.id)) {
      this.form_ans.find((e:any)=>e.id==q.id).value = value;
    }
    else {
      this.form_ans.push({id: q.id, value});
    }
  }

  urlSafe: SafeResourceUrl | undefined;

  setIframeUrl(url: string) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(url);
  }
  

  openDialogUploadCampaignFile(phase_id : number) {
    this.matDialog.open(ProjectsCampaignFileAddComponent, {
      panelClass: ['dialogs-main'],
      data: {
        phase_id : phase_id,
        id_campana_proyecto : 1,
        folder : 1,
        nombre : "DANGLER - VERSION 1"
      }
    }).afterClosed().subscribe(
      resp => {
       
      }
    );
  }


  openDialogCommentNoAuth(phase_id : number, rol_id : number) {
    this.matDialog.open(ProjectsCampaignCommentsAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
          phase_id : phase_id,
          rol_id : rol_id,
          camp_id : 1,
          nombre : "DANGLERS VERSION 1 - FASE 1"
        }
    }).afterClosed().subscribe(
      resp => {
   
      }
    );
  }

  getArchivosByPhase() {
    this.main.ShowLoading();
    const data = {
        id_diagrama: this.curr_diagram.id,
        id_fase: this.phase.id
    }
    this.projectsS.getdiagramFiles(data).subscribe((x: any) => {
      this.archivos = x.data;
      this.archivo_c = JSON.parse(JSON.stringify(this.archivos[0]));
      this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivo_c.file);
      this.main.HideLoading();
      this.setIframeUrl(this.archivos[0].file);
      console.log(this.urlSafe);
    },
    (err:any) => {
      console.log(err);
          this.main.HideLoading();
    })
  }

  getPromotions() {
    this.main.ShowLoading();
    this.projectsS.getPromotionData(this.campaign.id).subscribe((x: any) => {
      this.promotionFile = x.data.filter((e:any)=>this.curr_diagram.promociones.includes(e["CODIGO PRINCIPAL"]));
      this.dspromotionFile = new MatTableDataSource(this.promotionFile);
      this.dspromotionFile.paginator = this.paginator;
      this.dspromotionFile.sort = this.sort;
      this.displayedColumns = Object.keys(this.promotionFile[0]).filter(key => key && key.trim() !== '');
      this.allColumns = this.displayedColumns;
      this.main.HideLoading(); 
    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }

  getRespuestas() {
    this.main.ShowLoading();
    this.projectsS.getProjectRespVobos(this.curr_diagram.id, this.vobo.id).subscribe((x: any) => {
      this.resFile = x.data;
      this.dsResFile = new MatTableDataSource(this.resFile);
      this.dsResFile.paginator = this.paginatorRes;
      this.dsResFile.sort = this.sortRes;
      this.displayedColumnsRes = Object.keys(this.resFile[0]).filter(key => key && key.trim() !== '');
      this.allColumnsRes = this.displayedColumnsRes;
      this.main.HideLoading(); 
    },
    (err:any) => {
      console.log(err);
      this.main.HideLoading();
    })
  }

  guardarRespuestas() {
    let valid = true;
    this.form_vb.map((e:any)=> {
      if(!this.form_ans.some((v:any)=>v.id==e.id)) {
        valid = false;
      }
    });

    let aprobado = true;
    this.form_ans.map((e:any)=> {
      if(!e.value)
        aprobado = false;
    });

    if(!aprobado&&this.comentario=="") {
      valid = false;
    }

    if (valid) {
      
      const ins_data = { 
        id_diagrama: this.curr_diagram.id,
        id_proy_vobo: this.vobo.id, 
        id_fase: this.vobo.phase_id,
        id_arte: this.archivos[0].id,
        respuestas: this.form_ans,
        comentario: this.comentario,
        aprobado
      };

        this.projectsS.setProjectPhaseRespVobo(ins_data).subscribe(
          (result:any) => {
            this.main.HideLoading();
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                text: 'Respuestas guardadas correctamente'          
              }).then(() => {            
                this.dialogRef.close({ reload: true });
              });
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
              });
            }
          },
          (err:any) => {
            this.main.HideLoading();
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        );
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
  }
}
