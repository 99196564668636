import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectModel } from 'src/app/models/project.model';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-diagramation-inventory',
  templateUrl: './projects-diagramation-inventory.component.html',
  styleUrls: ['./projects-diagramation-inventory.component.scss']
})
export class ProjectsDiagramationInventoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  project: ProjectModel = new ProjectModel();
  displayedColumns: string[] = [];
  allColumns: string[] = [];
  dataSource: any = [];
  data_inv: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private projectService : ProjectsService,
    private main : MainService,
  ) {
    this.project = data.project;
   }

  ngOnInit(): void {
    this.getInventory();
  }

  
  getInventory(){
    this.main.ShowLoading();
    this.projectService.getProjectInvTotals(this.project.id).subscribe(
      (result:any) => {
       this.main.HideLoading();
       if(result.message == "Success") {
          if(result.data.length != 0){
            this.displayedColumns = Object.keys(result.data[0]).filter(key => key && key.trim() !== '');
            this.allColumns = this.displayedColumns;
            
            this.data_inv = result.data;
            this.dataSource = new MatTableDataSource(result.data);        
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      (err:any) => {
        this.main.HideLoading();
        console.log(err);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

   if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
   }
  }

  generateExcelResumen(){
    if(this.data_inv.length == 0){
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text: 'No hay datos de resumen actualmente'          
      });
    }else{
      this.main.generateExcel(this.data_inv, "Inventario" + this.project.nombre, "inv");
    }
  }

}
