import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { ProjectModel } from 'src/app/models/project.model';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-providers-view-img-file',
  templateUrl: './projects-providers-view-img-file.component.html',
  styleUrls: ['./projects-providers-view-img-file.component.scss']
})
export class ProjectsProvidersImgAddFileComponent implements OnInit {

  bodyForm : FormData = new FormData(); 
  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);  
  project: ProjectModel = new ProjectModel();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private main : MainService,
    private fb : FormBuilder,
    private projectService : ProjectsService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<ProjectsProvidersImgAddFileComponent>,
  ) { 
    this.project = this.data.project;
  }

  ngOnInit(): void {
    this.loadForm();
    this.preloadFormData();
  }

  loadForm() {
    this.form = this.fb.group({
      blob: [[], { validators: [Validators.required] }]
    });
  }

  preloadFormData(){
    this.bodyForm.append('folder', this.project.folder as string);      
  }

  onValueChange(files: any) { 
    const allowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf', '.xls', '.xlsx', '.csv'];
    
    if (!this.form.value.blob) {
      this.form.patchValue({ blob: [] });
    }
  
    let currentFiles = this.form.value.blob || [];
    let newFiles: File[] = [];
  
    for (const item of files) {
      const fileName = item.name.toLowerCase();
      const isValid = allowedExtensions.some(ext => fileName.endsWith(ext));
  
      if (!isValid) {
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: `Formato no permitido: ${item.name}. Solo se permiten imágenes, PDF, Excel y CSV.`
        });
      } else {
        newFiles.push(item);
      }
    }
  
   
    const updatedFiles = [...currentFiles, ...newFiles];
  
    if (updatedFiles.length > 3) {
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Solo puede adjuntar un máximo de 3 archivos'
      });
      updatedFiles.splice(3); 
    }
  
    this.form.controls['blob'].setValue(updatedFiles, { emitEvent: false });
  }

  uploadFile() {
    if (this.form.value.blob.length > 0) {
      for (const file of this.form.value.blob) {
        this.bodyForm.append("blob", file);
      }
  
      this.dialogRef.close({ reload: true, files: this.form.value.blob });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Debe adjuntar al menos un archivo'
      });
    }
  }

}
