<div class="modal-header">
    <h4 *ngIf="edit" class="modal-title" id="mondalAddCampaignTitle">Editar diagramacion</h4>
    <h4 *ngIf="!edit" class="modal-title" id="mondalAddCampaignTitle">Diagramar</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">       
        <mat-divider></mat-divider>
        <div>
            <mat-horizontal-stepper [linear]="true" #stepper>
                <!-- Paso 1 -->
                <mat-step [stepControl]="firstFormGroup">
                  <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Paso 1 - Selección POP</ng-template>
                    <!--<mat-form-field>
                      <mat-label>Campo 1</mat-label>
                      <input matInput formControlName="medio" required>
                      <mat-error *ngIf="firstFormGroup.get('medio')?.hasError('required')">
                        Este campo es obligatorio
                      </mat-error>
                    </mat-form-field>-->
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="row">
                                    <mat-form-field appearance="outline" class="form-field">
                                        <mat-label>Medio</mat-label>
                                        <mat-select [disabled]="edit" matInput formControlName="medio" (selectionChange)="medioSelected($event.value);" required name="medio">
                                          <mat-option *ngFor="let m of medios" [value]="m.id">{{ m.nombre }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="firstFormGroup.get('medio')?.hasError('required')">
                                           El medio es requerido.
                                        </mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field appearance="outline" class="form-field">
                                        <mat-label>Elemento POP</mat-label>
                                        <mat-select (selectionChange)="popSelected($event.value);"  [disabled]="(firstFormGroup.get('medio')?.value == '')||edit" matInput formControlName="elemento_pop" required name="elemento_pop">
                                          <mat-option *ngFor="let m of elementos" [value]="m.id">{{ m.nombre }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="firstFormGroup.get('elemento_pop')?.hasError('required')">
                                           El elemento POP es requerido.
                                        </mat-error>
                                    </mat-form-field>
    
                                    <mat-form-field appearance="outline" class="form-field" [hidden]="!hasPositions">
                                        <mat-label>Posición</mat-label>
                                        <input type="number" [min]="min_pos" [max]="max_pos" [readonly]="edit" (change)="positionSelected($event)"  matInput formControlName="position"  required>
                                        <mat-error *ngIf="firstFormGroup.get('position')?.hasError('required')">
                                          Este campo es obligatorio
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                      <mat-label>Descripción del Medio</mat-label>
                                      <textarea matInput style="color:black" [value]="getMedioDescripcion()" rows="4" disabled></textarea>
                                    </mat-form-field>
                
                                    <div *ngIf="loaded">
                                      <button mat-raised-button matStepperNext color="primary" [disabled]="(!(firstFormGroup.valid)||selectedPromotions.length==0)" (click)="setSecondData()">Siguiente</button>
                                    </div>
                            </div>
                        </div>
                        <div class="col-sm-9">
                              <div class="selected-codes">
                                <span><b>Promociones seleccionadas:</b> <span *ngIf="selectedPromotions.length == 0">Ninguno</span> <span *ngIf="selectedPromotions.length > 0">{{ selectedPromotions.length }}</span></span>
                              
                              </div>
                              <div class="overflow-auto w-200">
                                <!--<mat-form-field>
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event, dspromotionFile)" placeholder="Dangler" #input>
                                </mat-form-field>-->
                              <!--<mat-form-field>
                                  <mat-label>
                                      <mat-icon>numbers</mat-icon>Código</mat-label>
                                  <input matInput placeholder="8122121112" name="codigoFilter" [(ngModel)]="codigoFilter">
                              </mat-form-field>-->
                              <!--<mat-form-field>
                                <mat-label> <mat-icon>world</mat-icon> Mundo</mat-label>
                                <mat-select  matInput name="mundo">
                                  <mat-option *ngFor="let m of mundosList" [value]="m">{{ m }}</mat-option>
                                </mat-select>
                              </mat-form-field>-->

                            </div>
                              <div class="promo-container mat-elevation-z0 table-responsive text-nowrap" style="max-height: 600px; overflow-y: auto; padding: 10px; border: 1px solid #ccc;">
                                <table *ngIf="loaded" mat-table [dataSource]="dspromotionFile" matSort #sort="matSort" class="mat-elevation-z8" style="width: 100%;">
                                  
                                  <!-- Checkbox para seleccionar todos -->
                                  <ng-container matColumnDef="select">
                                    <th class="text-center do-jump-line-make-bonito" mat-header-cell *matHeaderCellDef sticky style="min-width: 80px; margin-left: 14px;"> 
                                      <input type="checkbox"  [checked]="selectAllValue" (click)="doSelectAllValue()" (change)="selectAll($event)">
                                    </th>
                                    <td  class="text-center" mat-cell sticky *matCellDef="let promo" style="min-width: 80px; margin-left: 14px;">
                                      <input type="checkbox" (change)="toggleSelection(promo['CODIGO PRINCIPAL'])" [checked]="selectedPromotions.includes(promo['CODIGO PRINCIPAL'])">
                                    </td>
                                  </ng-container>
                              
                                  
                                  <ng-container *ngFor="let column of displayedColumns">
                                    <ng-container matColumnDef="{{column}}">
                                      <th mat-header-cell *matHeaderCellDef class="do-jump-line-make-bonito" style="min-width: 170px; margin-left: 14px;"><span style="display:block; height: 33px;">{{ column }} </span> 
                                        <mat-form-field appearance="outline" class="filter-input">
                                          <mat-label><mat-icon>search</mat-icon></mat-label>
                                          <input matInput (keyup)="applyColumnFilterPromo(column, $event)">
                                        </mat-form-field>
                                      </th>
                                      <td mat-cell *matCellDef="let promo"  style="min-width: 170px; margin-left: 14px;"> {{ promo[column] }} </td>
                                    </ng-container>
                                  </ng-container>
                              
                                  <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: allColumns;"></tr>
                                </table>
                                <div *ngIf="!loaded" style="display: flex; justify-content: center; align-items: center; background: white;">
                                  <mat-progress-spinner color=" primary" mode="indeterminate">
                                  </mat-progress-spinner>
                                </div>
                                <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                              </div>
                        </div>
    
                    </div>
 

                  </form>
                </mat-step>
              
                <!-- Paso 2 -->
                <mat-step [stepControl]="secondFormGroup">
                  <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>Paso 2 - Inventarios y Sucursales</ng-template>

                    <div class="row">
                        <div class="col-sm-3">
                            <div class="row  text-center">
                                <button (click)="goToConfig()" mat-flat-button color="primary" class="same-height" style="width: 190px;">
                                    <mat-icon>edit</mat-icon>  Configuración VoBos
                                 </button>
                            </div>
                            <br>
                            <table class="table table-hover">
                                <thead>
                                  <tr>
                                    <th class="text-center" scope="col">Fases Configuradas</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngFor="let ph of phases">
                                    <tr>
                                      <td>{{ph.name}} 
                                        <p *ngIf="!ph.selected" (click)="openVobosDiagramConfigDialog(ph)" class="badge badge-info"  data-toggle="tooltip" data-placement="right" matTooltip="Selecciona los usuarios para el vobo por area." title="Cantidad de Vobos">Selecciona usuarios</p>
                                        <p *ngIf="ph.selected" (click)="openVobosDiagramConfigDialog(ph)" class="badge badge-success"  data-toggle="tooltip" data-placement="right" matTooltip="Edita los usuarios seleccionados para el vobo por area." title="Cantidad de Vobos">Edita usuarios</p>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </tbody>
                            </table>
                            <div>
                              <button mat-button matStepperPrevious>Anterior</button>
                              <button mat-raised-button color="primary" (click)="guardarDiagrama()" [disabled]="this.stores_sel.length==0||vobos_sel.length!=phases.length" ><mat-icon>save</mat-icon> Finalizar y guardar</button>
                            </div>

                        </div>
                        <div class="col-sm-9">
                          <mat-tab-group>
                            <mat-tab>
                              <ng-template mat-tab-label>
                                <mat-icon class="me-2">store</mat-icon> Sucursales
                               </ng-template>
                               <!-- sucursales part -->
                                <br>
                                <div class="selected-codes">
                                    <span><b>Sucursales seleccionadas: </b> <span *ngIf="selectedStores.length == 0">Ninguna</span> <span *ngIf="selectedStores.length > 0">{{ selectedStores.length }}</span></span>
                                </div>

                                <div class="overflow-auto w-200">
                                    <div style="display: flex; align-items: center; gap: 10px;">
                                      <mat-form-field style="width: 80%;" [hidden]="(same_stores||!segmentable)&&!edit">
                                        <mat-label>
                                            Selección masiva por FarmIDs</mat-label>
                                        <input matInput [(ngModel)]="farmidsInput" placeholder="1111,2222,3333" [ngModelOptions]="{standalone: true}" > 
                                      </mat-form-field>
                                      <button mat-raised-button (click)="takeFarmIdsInput()" color="accent">Seleccionar</button>
                                     
                                      <button mat-raised-button color="accent"  (click)="fileInput.click()">Excel</button>
                                      <input type="file" #fileInput (change)="onExcelSelected($event)" accept=".xlsx, .xls" hidden>
                                </div>

                                </div>
                                <div class="promo-container mat-elevation-z0 table-responsive text-nowrap" style="max-height: 520px; overflow-y: auto; padding: 10px; border: 1px solid #ccc;">
                                    <table mat-table [dataSource]="dsstoreFile" matSort #sortSuc="matSort" class="mat-elevation-z8" style="width: 100%;">
                                      
                                      <!-- Checkbox para seleccionar todos -->
                                      <ng-container matColumnDef="select">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef sticky style="min-width: 80px; margin-left: 14px;"> 
                                          <input type="checkbox" [checked]="toggleValue" (click)="doToggleValue()" [hidden]="(same_stores||!segmentable)&&!edit" (change)="toggleSelectionStore(0, $event)">
                                        </th>
                                        <td  class="text-center" mat-cell *matCellDef="let store" style="min-width: 80px; margin-left: 14px;">
                                         <input type="checkbox" [hidden]="(same_stores||!segmentable)&&!edit" (change)="toggleSelectionStore(store['id'], null)" [checked]="selectedStores.includes(store['id'])">
                                        </td>
                                      </ng-container>
                                  
                                  
                                      <ng-container *ngFor="let column of displayedColumnsStore">
                                        <ng-container matColumnDef="{{column}}">
                                          <th mat-header-cell *matHeaderCellDef class="do-jump-line-make-bonito" sticky style="min-width: 80px; height: 15px; margin-left: 14px;"> <span style="display:block; height: 33px;">{{ column }} </span> 
                                            <mat-form-field appearance="outline" class="filter-input">
                                              <mat-label><mat-icon>search</mat-icon></mat-label>
                                               <input *ngIf="column != 'Plaza'" matInput (keyup)="applyColumnFilterStores(column, $event)">

                                               <mat-select *ngIf="column === 'Plaza'" (selectionChange)="applyColumnFilterStoresSelect(column, $event.value);" matInput name="plaza">
                                                <mat-option [value]="''">Todas</mat-option>
                                                <mat-option *ngFor="let p of plazasList" [value]="p">{{ p }}</mat-option>
                                               </mat-select>
                                              </mat-form-field>
                                          </th>
                                          <td mat-cell *matCellDef="let store"  style="min-width: 80px; margin-left: 14px;"> {{ store[column] }} </td>
                                        </ng-container>
                                      </ng-container>
                                  
                                      <tr mat-header-row *matHeaderRowDef="allColumnsStore"></tr>
                                      <tr mat-row *matRowDef="let row; columns: allColumnsStore;"></tr>
                                    </table>
                                    <mat-paginator #paginatorSuc [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                  </div>
                               <!--end sucursales part-->
                            </mat-tab>
                            <mat-tab>
                              <ng-template mat-tab-label>
                                <mat-icon class="me-2">list_alt</mat-icon> Inventario
                              </ng-template>
                              <br>                                                          
                              <table class="table">
                                <thead>
                                  <tr class="text-center">
                                    <!-- <th scope="col">Inventario Inicial</th>
                                    <th scope="col">Inventario Disponible</th> -->
                                    <!-- <th scope="col">Inventario Selecionado</th> -->
                                    <th scope="col">Material</th>
                                    <th scope="col">Inventario Inicial</th>
                                    <th scope="col" *ngIf="cant_type != 1">Inventario Disponible</th>
                                    <th *ngIf="!same_stores || edit" scope="col">Inventario Selecionado</th>
                                    <th scope="col">Precio Individual</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let material of dt_totales" class="text-center">
                                    <td>{{ material.atributo }}</td>
                                    <td *ngIf="cant_type != 1">{{ material.inventario }}</td>
                                    <td *ngIf="cant_type != 1">{{ material.disponible - findMaterialTotal(material.atributo) }}</td>
                                    <td *ngIf="!same_stores || edit">{{ findMaterialTotal(material.atributo) }}</td>
                                    <td><input type="number" matInput (change)="setPrecio(material.id_pop_config, $event)" [value]="material.precio" #input>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </mat-tab>
                          </mat-tab-group>

                        </div>
                    </div>

                  </form>
                </mat-step>
              </mat-horizontal-stepper>
        </div>
    </div>
</mat-dialog-content>