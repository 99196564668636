<div class="content-wrapper">
    <!-- Content -->
    <div class="container-fluid flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary"><mat-icon class="align-middle">extension</mat-icon> Proyectos
        </h4>

        <mat-tab-group>
            <mat-tab label="Formularios">
                <div style="width: 98.5%;">
                <div>
                    <br>
                    <!-- Card active projects -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card mb-4">
                                <h5 class="card-header">
                                    <mat-icon class="text-success align-middle">stop</mat-icon> Aprobados
                                </h5>
                                <div class="card-body">
                                    <mat-accordion *ngFor="let project of proyectosAprobados()" multi>
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                        <mat-icon class="aling-middle text-success me-2">list_alt</mat-icon> {{ project.nombre }}
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    <!--<span class="badge bg-label-warning">{{ getAprobacionesPendientesByProyecto(project.id) }}</span>-->
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <mat-divider></mat-divider>
                                            <mat-accordion multi>
                                                <mat-expansion-panel *ngFor="let campana of getCampanasByProyecto(project.id)" class="mat-elevation-z0">
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                           <ng-container *ngIf="checkIfCampanaAprobada(campana.id)"><mat-icon class="aling-middle text-warning me-2">campaign</mat-icon> {{ campana.nombre }}</ng-container> 
                                                           <ng-container *ngIf="!checkIfCampanaAprobada(campana.id)"><mat-icon class="aling-middle text-success me-2">campaign</mat-icon> {{ campana.nombre }}</ng-container>
                                                        </mat-panel-title>
                                                        <mat-panel-description>
                                                            <span class="badge bg-label-primary">{{ campana.nombre_provider }}</span>
                                                        </mat-panel-description>
                                                    </mat-expansion-panel-header>

                                                    <div class="table-responsive text-nowrap">
                                                        <div class="float-end">
                                                            <button (click)="openFacturaCreate(campana.id_provider, campana.nombre, campana.id)" [disabled]="!hasFacturasSelected(campana.id, campana.id_provider)" mat-flat-button color="primary" class="ms-4">Añadir Documentos de Pago<mat-icon>library_books</mat-icon></button>
                                                        </div>
                                                        <table class="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Archivo</th>
                                                                    <th>Autorización</th>
                                                                    <th>Facturado</th>
                                                                    <th>Fecha de Registro</th>
                                                                    <th>Acciones</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="table-border-bottom-0">
                                                                <tr *ngFor="let surveys of getSurveysByCampana(campana.id, campana.id_provider)">
                                                                    <td>
                                                                        {{ surveys.id }}
                                                                    </td>
                                                                    <td>
                                                                        {{ surveys.nombre_archivo }}
                                                                    </td>
                                                                    <td>
                                                                        <span *ngIf="surveys.status == 1" class="badge bg-warning">PENDIENTE</span>
                                                                        <span *ngIf="surveys.status == 2" class="badge bg-success">AUTORIZADO</span><button mat-icon-button class="mb-1" (click)="getPDF(surveys.id,surveys.archivo ,campana.nombre_provider)" *ngIf="surveys.status == 2" matTooltip="Descargar respuesta" [matTooltipPosition]="'above'"><mat-icon>get_app</mat-icon></button>
                                                                        <span *ngIf="surveys.status == 3" class="badge bg-danger">RECHAZADO</span>
                                                                    </td>
                                                                    <td>
                                                                        <span *ngIf="surveys.facturado == 0" class="badge bg-warning">POR DOCUMENTAR</span>
                                                                        <span *ngIf="surveys.facturado == 1" class="badge bg-success">DOCUMENTADO</span><button mat-icon-button style="cursor: pointer;" (click)="getPDF(surveys.id,surveys.archivo ,campana.nombre_provider)" *ngIf="false"><mat-icon>drop_eye</mat-icon></button>                                                                    
                                                                    </td>
                                                                    <td>
                                                                        {{ surveys.created_at | date : "dd/MM/yyyy hh:mma" : "-0000" }}
                                                                    </td>
                                                                    <td>
                                                                        <ng-container *ngIf="surveys.locked == 0">
                                                                            <button (click)="DownloadElement(surveys.archivo )" mat-icon-button class="mb-1" matTooltip="Descarga archivo" [matTooltipPosition]="'above'">
                                                                                <mat-icon>download</mat-icon>
                                                                            </button>
                                                                            <button mat-icon-button class="mb-1" (click)="openDialogFormHistory(surveys.id, campana.nombre_provider)" matTooltip="Ver actividades" [matTooltipPosition]="'above'">
                                                                                <mat-icon>fact_check</mat-icon>
                                                                            </button>
                                                                            <button mat-icon-button class="mb-1" (click)="openDialogFileLogs(surveys.id)">
                                                                                <mat-icon>forum</mat-icon>
                                                                            </button>
                                                                            <button mat-icon-button class="mb-1" (click)="goToProviderMaterial(surveys.id)" matTooltip="Ir a material" [matTooltipPosition]="'above'">
                                                                                <mat-icon>preview</mat-icon>
                                                                            </button>

                                                                            
                                                                        </ng-container>
                                                                    </td>
                                                                    <td>
                                                                        <mat-checkbox *ngIf="surveys.facturado == 0" [(ngModel)]="surveys.selected" matTooltip="Seleccionar para factura"></mat-checkbox>
                                                                    </td>
                                                                </tr>                   
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </mat-expansion-panel>
                                            </mat-accordion>                                    
                                        </mat-expansion-panel>
                                    </mat-accordion>
        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Card active campaigns End-->
        
                <!-- Card inactive campaigns -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-4">
                            <h5 class="card-header">       
                                <mat-icon class="text-warning align-middle">stop</mat-icon> Pendientes por Aprobar
                            </h5>
                            <div class="card-body">
                                <mat-accordion *ngFor="let project of proyectosPendientes()" multi>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                    <mat-icon class="aling-middle text-success me-2">list_alt</mat-icon> {{ project.nombre }}
                                            </mat-panel-title>
                                            <mat-panel-description>
                                                <!--<span class="badge bg-label-success">2 de 2 aprobaciones completadas</span>-->
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <mat-divider></mat-divider>
                                        <mat-accordion multi>
                                            <mat-expansion-panel *ngFor="let campana of getCampanasByProyecto(project.id)"  class="mat-elevation-z0">
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        <mat-icon class="aling-middle text-success me-2">campaign</mat-icon> {{ campana.nombre }}
                                                    </mat-panel-title>
                                                    <mat-panel-description>
                                                        <span class="badge bg-label-primary">{{ campana.nombre_provider }}</span>
                                                    </mat-panel-description>
                                                </mat-expansion-panel-header>
                                                <div class="table-responsive text-nowrap">
                                                    <div class="float-end">
                                                        <button (click)="openFacturaCreate(campana.id_provider, campana.nombre, campana.id)" [disabled]="!hasFacturasSelected(campana.id, campana.id_provider)" mat-flat-button color="primary" class="ms-4">Añadir documentos de pago<mat-icon>library_books</mat-icon></button>
                                                    </div>
                                                    <table class="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Archivo</th>
                                                                <th>Autorización</th>
                                                                <th>Facturado</th>
                                                                <th>Fecha de Registro</th>
                                                                <th>Acciones</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="table-border-bottom-0">
                                                            <tr *ngFor="let surveys of getSurveysByCampana(campana.id, campana.id_provider)">
                                                                <td>
                                                                    {{ surveys.id }}
                                                                </td>
                                                                <td>
                                                                    {{ surveys.nombre_archivo }}
                                                                </td>
                                                                <td>
                                                                    <span *ngIf="surveys.status == 1" class="badge bg-warning">PENDIENTE</span>
                                                                    <span *ngIf="surveys.status == 2" class="badge bg-success">AUTORIZADO</span><button mat-icon-button style="cursor: pointer;" (click)="getPDF(surveys.id,surveys.archivo ,campana.nombre_provider)" *ngIf="surveys.status == 2"><mat-icon>get_app</mat-icon></button>
                                                                    <span *ngIf="surveys.status == 3" class="badge bg-danger">RECHAZADO</span>
                                                                </td>
                                                                <td>
                                                                    <span *ngIf="surveys.facturado == 0" class="badge bg-warning">POR DOCUMENTAR</span>
                                                                    <span *ngIf="surveys.facturado == 1" class="badge bg-success">DOCUMENTADO</span><button mat-icon-button style="cursor: pointer;" (click)="getPDF(surveys.id,surveys.archivo ,campana.nombre_provider)" *ngIf="false"><mat-icon>drop_eye</mat-icon></button>            
                                                                </td>
                                                                <td>
                                                                    {{ surveys.created_at | date : "dd/MM/yyyy hh:mma": "-0000"  }}
                                                                </td>
                                                                <td>
                                                                    <button (click)="DownloadElement(surveys.archivo)" mat-icon-button class="mb-1" matTooltip="Descarga archivo" [matTooltipPosition]="'above'">
                                                                        <mat-icon>download</mat-icon>
                                                                    </button>
                                                                    <button mat-icon-button class="mb-1" (click)="openDialogFormHistory(surveys.id, campana.nombre_provider )" matTooltip="Ver actividades" [matTooltipPosition]="'above'">
                                                                        <mat-icon>fact_check</mat-icon>
                                                                    </button>
                                                                    <button mat-icon-button class="mb-1" (click)="openDialogFileLogs(surveys.id)">
                                                                        <mat-icon>forum</mat-icon>
                                                                    </button>
                                                                    <button mat-icon-button class="mb-1" (click)="goToProviderMaterial(surveys.id)" matTooltip="Ir a material" [matTooltipPosition]="'above'">
                                                                        <mat-icon>preview</mat-icon>
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <mat-checkbox *ngIf="surveys.facturado == 0 && surveys.status == 2" [(ngModel)]="surveys.selected" matTooltip="Seleccionar para factura"></mat-checkbox>
                                                                </td>
                                                            </tr>
                                                            
                                                        </tbody>
                                                     
                                                    </table>
                                                </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>                                    
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </mat-tab>
            <mat-tab label="Listado de documentos de pago">
                <div class="col-md-12">
                    <div class="card mb-4">
                        <h5 class="card-header">       
                            <mat-icon>receipt</mat-icon> Listado
                        </h5>
                        <div class="card-body">
                <div class="table-responsive text-nowrap">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Campaña</th>
                                <th>Diagramaciones / Archivos</th>
                                <th>Creado</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                            <ng-container *ngFor="let factura of facturas"  >
                                <tr *ngIf="factura.surveys.length != 0">
                                    <td>
                                     <span>{{factura.nombre}} <button mat-icon-button class="mb-1" (click)="openFacturaView(factura)" matTooltip="Ver documento de pago" [matTooltipPosition]="'above'"><mat-icon>preview</mat-icon></button>
                                        <button mat-icon-button class="mb-1" (click)="getFilesPDF(factura.id)" matTooltip="Descargar datos de pago" [matTooltipPosition]="'above'"><mat-icon>picture_as_pdf</mat-icon></button></span>
                                    </td>
                                    <td>
                                        <mat-accordion>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                            <mat-icon class="aling-middle text-success me-2">list_alt</mat-icon> Ver Diagramaciones ({{ factura.surveys.length }})
                                                    </mat-panel-title>
                                                    <mat-panel-description>
                                                        <!--<span class="badge bg-label-success">2 de 2 aprobaciones completadas</span>-->
                                                    </mat-panel-description>
                                                </mat-expansion-panel-header>
                                                <span *ngFor="let survey of factura.surveys">{{ survey.nombre_archivo }}<button mat-icon-button class="mb-1"  (click)="goToProviderMaterial(survey.id)"  matTooltip="Ver fomulario" [matTooltipPosition]="'above'"><mat-icon>preview</mat-icon></button><br></span>   
                                            </mat-expansion-panel>
                                         </mat-accordion>
                                    </td>
                                    <td>
                                        {{factura.nombre_creador}}
                                    </td>
                                    <td>
                                        {{factura.created_at | date : "dd/MM/yyyy hh:mma": "-0000"  }}
                                    </td>
                                </tr>
                            </ng-container>

                            <div *ngIf="facturas.length == 0">No hay facturas dadas de alta actualmente</div>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
            </mat-tab>

          </mat-tab-group>


        <!--style="position: absolute;
        left: -9999px;"-->
        <div style="position: absolute;
        left: -9999px;">
            <div id="canvasToCheck" >
                <ng-container  *ngFor="let response of responsedataAux">
                    <div style="max-width: 600px; padding-top: 50px">
                    <div class="container">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <h6>
                                    <span> <!--<mat-icon class="align-middle">update</mat-icon>--> Respuestas del formulario: <strong>{{ response?.created_at | date : "dd/MM/yyyy" : "-0000" }}</strong></span>     
                                    <br>
                                    <br>
                                    <span><b>Respondió : </b>{{ response?.nombre_provider }}</span>  
                                    <br>
                                    <span><b>Puesto :</b> {{ response?.puesto }}</span>     
                                </h6>
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Concepto</th>
                                            <th>Respuesta</th>
                                            <th>Comentarios</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-border-bottom-0">
                                        <tr *ngFor="let resp of getRespuestas(response?.respuesta)">
                                            <td>
                                              {{resp.nombre}}
                                            </td>
                                            <td>
                                                <span *ngIf="resp?.respuesta" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!resp?.respuesta" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="resp?.respuesta">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!resp?.respuesta">
                                                {{ resp?.comentario }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>      
                </div>
                </ng-container>
            </div>
        </div>

    </div>
</div>