import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewMediaComponent } from '../../shared/dialogs/new-media/new-media.component';
import { first, firstValueFrom } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ProjectMedia } from 'src/app/models/project.model';
import { ProjectsService } from 'src/app/services/projects.service';
import { CutoffService } from 'src/app/services/cutoff.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-project-media',
  templateUrl: './project-media.component.html',
  styleUrls: ['./project-media.component.scss']
})
export class ProjectMediaComponent implements OnInit {
  //reglas Medios 
  //1 - reglas
  //2 - prioridad 

  // Data Handlers
  projectID: number | null = null;
  //projectMedia: Array<any> = [
  //  { id: 1, name: "DANGLER", allowEmptyBranches: false, allowMultipleItems: false, popMaterials: [], segmentable: false, formato : "Reglas" },
  //  { id: 2, name: "COPETE DE MESA PROMOCIONES", allowEmptyBranches: false, allowMultipleItems: true, popMaterials: [], segmentable: false, formato : "Por Prioridad" },
  //  { id: 3, name: "BANNER", allowEmptyBranches: false, allowMultipleItems: false, popMaterials: [], segmentable: true, formato : "Reglas" },
  //  { id: 4, name: "POSTER CHECKOUT", allowEmptyBranches: false, allowMultipleItems: true, popMaterials: [], segmentable: false, formato : "Por Prioridad" }
  //];
  projectMedia : Array<any> = [];
  projectData : any;

  constructor(private dialog: MatDialog,
     private route: ActivatedRoute, 
     private router: Router, 
     private projService : ProjectsService,
     private _cutoffS : CutoffService,
     private _main :  MainService) 
     {

      }

  ngOnInit(): void {
    this.getData();
  }


  /* void openMediaPOP(1):
      */
  openMediaPOP(id: number): void {
    this.router.navigateByUrl('/admin/projects/project/' + this.projectID + '/media/' + id);
  }


  /* async Promise<void> getData(0):
     Extracts data from URL. */
  async getData(): Promise<void> {
    try {
      // Extract ID
      const url: ParamMap = await firstValueFrom(this.route.paramMap);
      this.projectID = Number(url.get('id'));

      if (!this.projectID){
        Swal.fire({
          icon: 'error',
          title: 'Error al obtener registros',
          text: 'Ocurrió un error al obtener registros, no existe un id válido.'
        });
      }else{
        this._main.ShowLoading();
        this.projService.getMedios(this.projectID).subscribe((x: any) => {
          this._main.HideLoading();
          this.projectMedia = x.data.medios;
          this.projectData = x.data.proyecto;
          //ESTO DEBE DE ESTAR EN TODAS LOS COMPONENTESD DE PROYECTOS
          this._cutoffS.checkIfSameProjectThenReplace(x.data.proyecto.id_proy_camp);

        },
        (err:any) => {
          console.log(err);
              this._main.HideLoading();
              Swal.fire({
                icon: 'error',
                title: 'Error al obtener registros',
                text: 'Ocurrió un error al obtener registros, vuelva a intentarlo más tarde.'
              });
        });
      }
       
    } catch (error) {
      console.error(error);
    }
  }

  async newMedia(): Promise<void> {

    const nmDialogRef: MatDialogRef<NewMediaComponent, any> = this.dialog.open(NewMediaComponent, {
      data: { id_proyecto : this.projectID, isEdit: false},
      panelClass: 'dialogs-main'
    });

    try {
      // Use firstValueFrom to get the result from the dialog
      const result = await firstValueFrom(nmDialogRef.afterClosed());

      if (result) {
        console.log('Dialog result:', result);
      }
    } catch (error) {
      console.error('Error handling dialog result:', error);
    }
  }

  async editMedia( medio : any): Promise<void> {
    // Open data-setting dialog
    const nmDialogRef: MatDialogRef<NewMediaComponent, any> = this.dialog.open(NewMediaComponent, {
      data: { id_proyecto : this.projectID, isEdit: true, medio : medio},
      panelClass: 'dialogs-main'
    });

    try {
      // Use firstValueFrom to get the result from the dialog
      const result = await firstValueFrom(nmDialogRef.afterClosed());

      if (result) {
        console.log('Dialog result:', result);
      }
    } catch (error) {
      console.error('Error handling dialog result:', error);
    }
  }

  deleteConfirm(medio: any){
    Swal.fire({
      title: '¿Está seguro que desea eliminar este medio?. Esto no se puede deshacer.',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  .then((willDelete) => {
    if (willDelete.isConfirmed) {
      this._main.ShowLoading();
      this.projService.deleteMedios(medio.id).subscribe(
        (x: any) => {
          this._main.HideLoading();
         
          this.getData();
        },
        (err:any) => {
          this._main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al eliminar',
            text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
          });
          //this.loadCampaigns();
          this.getData();
        }
      );
    }
  });
  }
}
