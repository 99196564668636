<div style="display: flex; justify-content: flex-end;">
  <button type="button" class="btn-close" [mat-dialog-close]=""></button>
</div>
<h1 mat-dialog-title class="m-0">Configuración</h1>

<div class="modal-body-scroll">
  <div class="col-sm-12" >

        <h5 class="fw-bold text-primary" style="padding-top: 20px;"><b>Nombre</b>: <span *ngIf="isEdit">{{ dataR.nombre }}</span></h5>
        <div class="col-md-6" *ngIf="!isEdit">
          <mat-form-field appearance="outline" class="form-field">
            <input matInput name="nombre" [(ngModel)]="dataR.nombre" placeholder="Ej: Dangler" />
          </mat-form-field>
        </div>

        <!--<p *ngIf="mediaActual.formato != 2"><mat-slide-toggle [(ngModel)]="dataR.isQuantity">Cantidad fija</mat-slide-toggle></p>  -->             
        <div class="mb-4" *ngIf="mediaActual.formato != 2 && showFilters && !dataR.isQuantity">
          <h5 class="fw-bold text-primary">
            <mat-icon class="me-2 align-middle">settings</mat-icon>Reglas de distribución
          </h5>
          <div *ngFor="let regla of dataR.reglas; let i = index" class="mb-3">
            <div class="row align-items-center"> 
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="form-field">
                  <mat-label>Atributo</mat-label>
                  <mat-select [(ngModel)]="regla.id" name="atributo" (selectionChange)="setItemName($event.value, regla)">
                    <mat-option *ngFor="let atributo of atributos" [value]="atributo.id">{{ atributo.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div> 
              
              <div class="col-md-3 d-flex justify-content-center">
                <span class="x-label">Multiplicado por:</span> 
              </div>
          
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="form-field">
                  <mat-label>Valor</mat-label>
                  <input matInput name="multiplicador" [(ngModel)]="regla.multiplicador" placeholder="Ej: 10" />
                </mat-form-field>
              </div>
          
              <div class="col-md-2" style="margin-top: -20px;">
                <button mat-icon-button color="warn" (click)="eliminarRegla(i,dataR.reglas)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <button *ngIf="dataR.reglas?.length<1" mat-flat-button color="primary" (click)="agregarRegla(dataR.reglas)">
            <mat-icon>add</mat-icon> Añadir Regla
          </button>
        </div>

        <div class="mb-4"  *ngIf="mediaActual.formato != 2 && showFilters && dataR.isQuantity">
          <h5 class="fw-bold text-primary">
            <mat-icon class="me-2 align-middle">inventory</mat-icon>Definir Cantidad
          </h5>
          <div>
          <div class="row align-items-center"> 
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Cantidad</mat-label>
                  <input matInput type="number" min="1" [(ngModel)]="dataR.cantidad" name="cantidad" step="-1">
                </mat-form-field>
            </div> 
          </div>
        </div>
        </div>
        <div class="mb-4" *ngIf="showPrecio">
          <h5 class="fw-bold text-primary">
            <mat-icon class="me-2 align-middle">attach_money</mat-icon>Definir Precio
          </h5>
          <div>
          <div class="row align-items-center"> 
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Precio</mat-label>
                  <input matInput type="number" min="1" [(ngModel)]="dataR.precio" name="precio" step="-1">
                </mat-form-field>
            </div>
          </div>
        </div>
        </div>


        <p *ngIf="dataR.reglas?.length != 0">Inventario estimado: <span *ngIf="loadedInventorys"><b>{{findInventoryItem(dataR.reglas[0].id)}}</b></span><span *ngIf="!loadedInventorys">Cargando...</span></p>

        <div class="button-group mt-4" *ngIf="dataR.id != 0">
          <button mat-raised-button color="primary" (click)="edit(dataR)">Guardar Cambios</button>
        </div>
        <div class="button-group mt-4" *ngIf="dataR.id == 0">
          <button mat-raised-button color="primary" (click)="save(dataR)">Guardar Configuración</button>
        </div>

  </div>
</div>
<!--<div mat-dialog-actions class="button-container">
  <button mat-raised-button *ngIf="!isEdit" class="nest-button" (click)="register()" [disabled]="invalidInput()">Agregar</button>
  <button mat-raised-button *ngIf="isEdit" class="nest-button" (click)="edit()" [disabled]="invalidInput()">Editar</button>
</div>-->