<div style="display: flex; justify-content: flex-end;">
  <button type="button" class="btn-close" [mat-dialog-close]=""></button>
</div>
<h1 mat-dialog-title class="m-0">Nuevo elemento POP</h1>

<div class="modal-body-scroll">
  <form class="inputform">
    <mat-form-field appearance="outline">
      <mat-label>Nombre </mat-label>
      <input matInput [(ngModel)]="dataR.nombre" name="nombre" required>
    </mat-form-field>
    <!--<mat-form-field appearance="outline">
      <mat-label>Elemento</mat-label>
      <mat-select [(ngModel)]="data.type" name="type" required>
        <mat-option *ngFor="let x of mattypes" [value]="x">
          {{ x }}
        </mat-option>
      </mat-select>
    </mat-form-field>-->
    <!-- <div class="row" *ngIf="media.allowMultipleItems && media.segmentable"> -->
    <div class="row">
      <div *ngIf="media.formato == 2" class="col-sm-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Prioridad</mat-label>
          <input matInput type="number" min="1" [(ngModel)]="dataR.prioridad" (keydown)="mirror($event)" name="prioridad" step="-1">
          <mat-hint>1 es la prioridad más alta.</mat-hint>
        </mat-form-field>
      </div>
      <div *ngIf="media.formato == 1" class="col-sm-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Posiciones</mat-label>
          <input matInput type="number" min="1" [(ngModel)]="dataR.posiciones" name="posiciones" step="1">
        </mat-form-field>
      </div>
    </div>
    <!--<div *ngIf="media.allowMultipleItems">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Prioridad</mat-label>
          <input matInput type="number" min="1" [(ngModel)]="data.priority" (keydown)="mirror($event)" name="priority" step="-1">
          <mat-hint>1 es la prioridad más alta.</mat-hint>
        </mat-form-field>
    </div>
    <div *ngIf="media.segmentable">
      <div>
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Posiciones</mat-label>
          <input matInput type="number" min="1" [(ngModel)]="data.placements" step="1">
        </mat-form-field>
      </div>
    </div>-->
    <div class="mb-4">
      <h5 class="fw-bold text-primary">Filtro sucursales</h5>
      <p *ngIf="reglasAlcance.length>0"><span class="tinyfont">*Cantidad de sucursales filtradas : <b>{{ sucursalesCount }}</b></span></p>
      <div *ngFor="let regla of reglasAlcance; let i = index; trackBy: trackByFn" class="mb-3">
        <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Atributo</mat-label>
              <mat-select [(ngModel)]="regla.id" name="atributo-{{i}}" (selectionChange)="setItemName($event.value, regla)">
                <mat-option *ngFor="let atributo of atributos" [value]="atributo.id">{{ atributo.name }}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-md-3">
            <mat-form-field *ngIf="getItem(regla.id) != null" appearance="outline" class="form-field">
              <mat-label>Condición</mat-label>
              <mat-select [(ngModel)]="regla.operador" name="operador-{{i}}">
                <ng-container>
                  <mat-option *ngIf="getItem(regla.id).type_id == 4 || getItem(regla.id).type_id == 6 || getItem(regla.id).type_id == 5 || getItem(regla.id).type_id == 3 || getItem(regla.id).type_id == 1" [value]="1">Igual que</mat-option>
                  <mat-option *ngIf="getItem(regla.id).type_id == 4 || getItem(regla.id).type_id == 6 " [value]="2">Mayor que</mat-option>
                  <mat-option *ngIf="getItem(regla.id).type_id == 4 || getItem(regla.id).type_id == 6 " [value]="3">Menor que</mat-option>

                </ng-container>

              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field *ngIf="getItem(regla.id) != null" appearance="outline" class="form-field">
              <mat-label>Valor</mat-label>
              <input matInput [hidden]="getItem(regla.id).type_id != 4" [(ngModel)]="regla.valor" name="valor-{{i}}" (blur)="calculateStores()" type="number" placeholder="Valor de {{getRanges(getItem(regla.id).select_data)[0]}} a {{getRanges(getItem(regla.id).select_data)[1]}}" min="{{getRanges(getItem(regla.id).select_data)[0]}}" max="{{getRanges(getItem(regla.id).select_data)[1]}}">
              <input matInput [hidden]="getItem(regla.id).type_id != 6" [(ngModel)]="regla.valor" name="valor-{{i}}" (blur)="calculateStores()" type="number"  placeholder="Ej: 10" />
              <input matInput [hidden]="getItem(regla.id).type_id != 5" [(ngModel)]="regla.valor" name="valor-{{i}}" (blur)="calculateStores()" placeholder="Ej: Valor" />
              <ng-container *ngIf="getItem(regla.id).type_id == 3">
                <mat-select  [(ngModel)]="regla.valor" (selectionChange)="calculateStores()" name="valor-{{i}}">
                  <mat-option *ngFor="let valor of converToObject(getItem(regla.id).select_data)" [value]="valor" >{{ valor }}</mat-option>
                </mat-select>
              </ng-container>
              <ng-container *ngIf="getItem(regla.id).type_id == 1" >
                <mat-select  [(ngModel)]="regla.valor" name="valor-{{i}}" (selectionChange)="calculateStores()">
                  <mat-option  [value]="'SI'">SI</mat-option>
                  <mat-option  [value]="'NO'">NO</mat-option>
                </mat-select>
              </ng-container>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <button mat-icon-button color="warn" (click)="eliminarRegla(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <button mat-flat-button color="primary" (click)="agregarRegla()">
        <mat-icon>add</mat-icon> Añadir Regla
      </button>
    </div>

  </form>
</div>
<div mat-dialog-actions class="button-container">
  <button mat-raised-button *ngIf="!isEdit" class="nest-button" (click)="register()" [disabled]="invalidInput()">Agregar</button>
  <button mat-raised-button *ngIf="isEdit" class="nest-button" (click)="edit()" [disabled]="invalidInput()">Editar</button>
</div>