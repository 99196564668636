import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-providers-reload',
  templateUrl: './projects-providers-reload.component.html',
  styleUrls: ['./projects-providers-reload.component.scss']
})
export class ProjectsProvidersReloadComponent implements OnInit {

  bodyForm : FormData = new FormData(); 
  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);
  providers : any = [];
  folder : any;
  id_provider_survey : any;
  id_provider : any;
  nombre : any = "";
  hostName : any = "";
  id_campana_proyecto : any;
  id_proyecto_diagrama: any;
  id_proy_vobo: any;
  id_fase: any;
  archivos: any;
  archivo_c: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,    
    private fb : FormBuilder,    
    private projectService : ProjectsService,
    private main : MainService,
    private dialogRef: MatDialogRef<ProjectsProvidersReloadComponent>,
  ) { 

    this.folder = this.data.folder;
    this.id_provider_survey = this.data.id_provider_survey;
    this.nombre = this.data.nombre;
    this.id_provider = this.data.id_provider;
    this.id_campana_proyecto = this.data.id_campana_proyecto;
    this.id_proyecto_diagrama = this.data.id_proy_dia;
    this.id_proy_vobo = this.data.id_proy_vobo;
    this.id_fase = this.data.id_fase;
    
    this.hostName = window.location.protocol + '//' + window.location.host + "/providers/";
  }

  ngOnInit(): void {
    this.getProviders();
    this.loadForm();
    this.preloadFormData();
    this.getArchivosByPhase();
  }
   
  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}],
      provider_msg : [""]
    });
  }

    getArchivosByPhase() {
      this.main.ShowLoading();
      const data = {
          id_diagrama: this.id_proyecto_diagrama,
          id_fase: this.id_fase
      }
      this.projectService.getdiagramFiles(data).subscribe((x: any) => {
        this.archivos = x.data;
        if(this.archivos.length == 0){
          Swal.fire({
            icon: 'warning',
            title: 'Alerta',
            html: 'Aún no hay archivos de artes, por lo que no puede enviar el formulario hasta que el archivo de artes se encuentre disponible'
          });
        }else{
          this.archivo_c = JSON.parse(JSON.stringify(this.archivos[0])).file;
          console.log(this.archivos);
        }
  
      },
      (err:any) => {
        console.log(err);
            this.main.HideLoading();
      })
    }

  preloadFormData(){
    this.bodyForm.append('folder', this.folder as string);
    this.bodyForm.append('id_provider_survey', this.id_provider_survey);
  }


  onValueChange(files : any)
  { 
    if(files.length >= 2)
    {      
      files.splice(1, 1);
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Sólo puede adjuntarse un documento'
      });
    }

    for (const item of files) {
      if(!(item.name.endsWith('.pdf')))
      {
        const index = files.indexOf(item);
  
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Solo se permiten documentos en formato .PDF'
        });
    
        if (index >= 0) {
          files.splice(index, 1);
        }
      }
    }
  }

  saveFile() {
      Swal.fire({
        title: '¿Estás seguro que deseas guardar el registro?',
        text: '¡Se enviará notificación a Proveedor!',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
            this.sendFile();
        }
      });
  }

  getProviders(){
    this.projectService.getProviders().subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.providers = result.data;
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros de proveedores.'
          });
        
      }
    );
  }

    sendFile(){ 
        this.bodyForm.append("file_c", this.archivo_c);
        this.bodyForm.append("provider_msg", this.form.controls.provider_msg.value);
        this.bodyForm.append("id_provider", this.id_provider);
        this.bodyForm.append("id_campana_proyecto", this.id_campana_proyecto);
        this.bodyForm.append("host", this.hostName);
        Swal.fire({
          icon: 'info',
          title: 'Enviando formulario...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        this.projectService.reuploadCampaignProviderFile(this.bodyForm).subscribe(
          (result:any)=>{
            if(result.message == 'Success'){
              Swal.fire({
                icon: 'success',
                title: 'Exito',
                text: 'Formulario enviado exitosamente'          
              }).then(() => {            
                this.dialogRef.close({ reload: true });
              });
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error al enviar',
                text: 'Ocurrió un error al enviar el formulario, por favor vuelve a intentarlo.'
              });
            }
          },
          (err:any)=>{
            this.main.HideLoading();
            if(err.error.message == "Invalid file") {
              Swal.fire({
                icon: 'warning',
                title: 'Error',
                html: 'Solo se permiten documentos en formato .PDF'
              });
        
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Error al enviar',
                text: 'Ocurrió un error al enviar el formulario, por favor vuelve a intentarlo.'
              });
            }
          }
        )
  
    }


  uploadFile(){ 
    if(this.form.value.blob != null && this.form.value.blob?.length!=0 ){
      this.bodyForm.append("blob", this.form.value.blob[0] as File);
      this.bodyForm.append("provider_msg", this.form.controls.provider_msg.value);
      this.bodyForm.append("id_provider", this.id_provider);
      this.bodyForm.append("id_campana_proyecto", this.id_campana_proyecto);
      this.bodyForm.append("host", this.hostName);
      Swal.fire({
        icon: 'info',
        title: 'Subiendo archivo...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this.projectService.reuploadCampaignProviderFile(this.bodyForm).subscribe(
        (result:any)=>{
          if(result.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Archivo subido',
              text: 'Archivo subido exitosamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
            });
          }
        },
        (err:any)=>{
          this.main.HideLoading();
          if(err.error.message == "Invalid file") {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              html: 'Solo se permiten documentos en formato .PDF'
            });
      
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
            });
          }
        }
      )
      
  }else{
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'El documento no ha sido adjuntado'
      });
    }
  }
}
