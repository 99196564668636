 import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';
import { ProjectsProvidersImgAddFileComponent } from '../projects-providers-view-img-file/projects-providers-view-img-file.component';
import { MainService } from 'src/app/services/main.service';
  
  @Component({
    selector: 'app-projects-providers-factura-add',
    templateUrl: './projects-providers-factura-add.component.html',
    styleUrls: ['./projects-providers-factura-add.component.scss']
  })
  export class ProjectsProvidersFacturaAdd implements OnInit {
  


    factura_data : any = {}
    isnew : boolean = false;
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data:any,
      private fb: FormBuilder,
      private userS: UsersService,
      private projectS : ProjectsService,
      private dialogRef: MatDialogRef<ProjectsProvidersFacturaAdd>,
      private matDialog: MatDialog,
      private main : MainService
    ) {

      this.isnew = this.data.new;

    }
  
    ngOnInit(): void {

      if(this.isnew){
        this.factura_data = {
          nombre : this.data.nombre_campana,
          razon_social: "",
          empresa_recuperadora: "",
          concepto: "",
          monto: this.data.monto,
          metodo_pago: "",
          id_provider : this.data.id_provider,
          provider_survey_ids : this.data.provider_survey_ids.toString()
          }
      }else{
        this.factura_data = this.data.factura;
      }

      console.log(this.factura_data);
    }

    bodyForm : FormData = new FormData(); 

    ruSure() {
      Swal.fire({
        title: '¿Estás seguro que deseas guardar este registro?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.saveFact();
        }
      });
    }
  
    saveFact() {
        Swal.fire({
          icon: 'info',
          title: 'Guardando...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
       
        this.projectS.setFactura(this.factura_data).subscribe(
          (result:any) => {
            if(result.message == "Success") {
              this.uploadImg(result.id_response)
             // Swal.fire({
             //   icon: 'success',
             //   title: 'Guardado',
             //   html: 'Registro guardado de manera correcta!'
             // }).then(() => {            
             //   this.dialogRef.close({ reload: false });
             // });
            }
          },
          (err:any) => {
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
            });
          }
        );
      
    }

      fileComentario: File[] = [];
    
      openUploadFileComentario() {
        this.matDialog.open(ProjectsProvidersImgAddFileComponent, {
          panelClass: ['dialogs-sm'],
          data: {
          }
        }).afterClosed().subscribe(
          resp => {
            if (resp && resp.files) {
              this.fileComentario = [...(this.fileComentario || []), ...resp.files];
              console.log(this.fileComentario);
            }
          }
        );
      }

      removeFileComentario(index: number) {
        this.fileComentario = this.fileComentario.filter((_, i) => i !== index);
      }

     uploadImg(id_fac : number){
          console.log("uploading files")
          this.bodyForm.append("id_provider_factura", id_fac.toString());
          
          this.bodyForm.append("folder", "datosfactura");
          (this.fileComentario as File[]).forEach(file => {
             this.bodyForm.append("blob", file, file.name);
        })
      
          this.projectS.uploadProviderFacturaFile(this.bodyForm).subscribe(
            (result:any)=>{
              if(result.message == 'Success'){
                  Swal.fire({
                  icon: 'success',
                  title: 'Guardado',
                  text: 'Registro guardado de manera correcta!'          
                }).then(() => {            
                  this.dialogRef.close({ reload: false });
                });
              }
              else {
                Swal.fire({
                  icon: 'success',
                  title: 'Guardado.',
                  text: 'Registro guardado de manera correcta!'          
                }).then(() => {            
                  this.dialogRef.close({ reload: false });
                  
                });
              }
            },
            (err:any)=>{
              this.main.HideLoading();
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                text: 'Registro guardado de manera correcta!'          
              }).then(() => {            
                this.dialogRef.close({ reload: false });
                
              });
            }
          )
      
        }
      
        
          getFiles(id:any){
            this.main.ShowLoading();
            this.projectS.getProvidersFacturaFiles(id).then((x:any)=>{
              this.main.HideLoading();
         
              const blob = new Blob([x], { type: 'application/zip' });
        
              const link = document.createElement('a');
              const url = window.URL.createObjectURL(blob);
            
              link.href = url;
              link.download = "archivo_datos_pago_provee" + id;
        
              link.click();
          
              window.URL.revokeObjectURL(url);
            }).catch((error: any) => {
        
              this.main.HideLoading();
              Swal.fire({
                icon: 'warning',
                title: 'Aviso',
                text: 'No se encontraron archivos en este registro.'
              });
            });;
          }
  }
  