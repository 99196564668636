<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Enviar nuevo formulario proveedor</h4>    
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h5><strong>Material - {{ nombre }}</strong></h5>    
    <form [formGroup]="form">
        <!--<ngx-file-drag-drop class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
        </ngx-file-drag-drop>-->
        <div class="row">            
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Mensaje para proveedor</mat-label>
                    <textarea formControlName="provider_msg" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5" rows="3"></textarea>
                </mat-form-field>
            </div>
        </div>        
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" (click)="saveFile()">Guardar <mat-icon>save</mat-icon></button>
</div>

