import { Component } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MainService } from 'src/app/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProjectsService } from 'src/app/services/projects.service';
import { ProjectsVoboAddComponent } from '../../shared/dialogs/projects-vobo-add/projects-vobo-add.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-project-config',
  templateUrl: './project-config.component.html',
  styleUrls: ['./project-config.component.scss']
})
export class ProjectConfigComponent {

  constructor(
    private matDialog: MatDialog,
    private main : MainService,
    private _params: ActivatedRoute,
    private _titleService: Title,
    private _router : Router,
    private projectService : ProjectsService,
    private userService : UsersService,
  ) { }

  id_campana_proyecto = 0;
  formList : any[] = [];
  usersList : any[] = [];
  user_selected :  any[] = [];

  ngOnInit(): void {
    this._params.params.subscribe(params => {
      if(params.id){

        this.id_campana_proyecto = params.id;
        this.loadFormularios();
        this.userService.getUserList().subscribe(
          (result : any) => {
            this.usersList = result;
            this.main.HideLoading();
            this.loadProjectData();
          },
          (err : any) => {
            console.log(err);
            this.main.HideLoading();
          }
        );
    
      }else{
        this._router.navigateByUrl('/admin/dashboard');
      } 
    });
  }

  project : any;
  haschanges : boolean = false;

  diagramacionItems = [
    { id: 1,  area: 'Juan Pérez', scheluded_time_days: 4, deleted : 0},
    { id: 2, area: 'Ana Gómez',  scheluded_time_days: 4, deleted : 0 },
    { id: 3,  area: 'Carlos Ruiz',  scheluded_time_days: 4, deleted : 0  },
  ];

  artesItems = [
    { id: 1,  area: 'Juan Pérez', scheluded_time_days: 4, deleted : 0 },
    { id: 2, area: 'Ana Gómez',  scheluded_time_days: 4, deleted : 0 },
    { id: 3,  area: 'Carlos Ruiz',  scheluded_time_days: 4, deleted : 0 }
  ];

  dataPhases : any;

  loadProjectData(){
    console.log("Project data", this._params.snapshot.data.data);
    this.main.HideLoading();

    if( this._params.snapshot.data.data ){
       if(this._params.snapshot.data.data.message = 'Success'){
        console.log(this._params.snapshot.data.data.data);
         this.project = this._params.snapshot.data.data.data.project[0];
         if(this._params.snapshot.data.data.data.project[0].users_upload != ""){
          this.user_selected = JSON.parse(this._params.snapshot.data.data.data.project[0].users_upload);
         }
         
         this.dataPhases = this._params.snapshot.data.data.data.data;
        // this.diagramacionItems = this._params.snapshot.data.data.data.fases_diagramacion;
        // this.artesItems = this._params.snapshot.data.data.data.fases_artes;
       }
    }else{
      this._router.navigateByUrl('/admin/dashboard');
    }
   }

   reloadProjectData(){
    this.main.ShowLoading();

    this.projectService.getConfigData(this.id_campana_proyecto).subscribe((x:any)=>{

      if(x.data.message = 'Success'){
        console.log(this._params.snapshot.data.data.data);
         this.project = x.data.project[0];
         if(x.data.project[0].users_upload != ""){
          this.user_selected = JSON.parse(x.data.project[0].users_upload);
         }
         
         this.dataPhases = x.data.data;
         this.haschanges = false;
       }

       this.main.HideLoading();
      
    });


   }

   loadFormularios(){
      this.projectService.getFormularios().subscribe((x:any)=>{
        this.formList = x.data;
      });
   }

  loadDiagramacionItems(){
    return this.diagramacionItems.filter((x:any)=> x.deleted == 0 );
  }

  loadArtesItems(){
    return this.artesItems.filter((x:any)=> x.deleted == 0 );
  }

  drop(list : any, event: CdkDragDrop<string[]>) {
    this.haschanges = true;
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  addItem(lista : any){
    this.haschanges = true;
    let newVobo = {id: 0, area: '', scheluded_time_days: 0, id_campana_proyecto: this.id_campana_proyecto, deleted : 0, users: [], vobos: []};
    lista.push(newVobo)
    this.openAddEditVobo(newVobo);
  }

  addPhase(){
    Swal.fire({
      title: 'Nueva fase',
      html: `
        <input id="swal-input-name" class="swal2-input" placeholder="Nombre de la fase">
        <input id="swal-input-days" type="number" class="swal2-input" placeholder="Días programados">
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`,
      preConfirm: () => {
        const name = (document.getElementById('swal-input-name') as HTMLInputElement).value.trim();
        const days = (document.getElementById('swal-input-days') as HTMLInputElement).value.trim();
    
        if (!name) {
          Swal.showValidationMessage('El nombre es necesario');
          return;
        }
    
        if (!days || isNaN(Number(days)) || Number(days) <= 0) {
          Swal.showValidationMessage('Ingrese un número válido de días');
          return;
        }
    
        return { name, days: Number(days) };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { name , days } = result.value as { name: string; days: number };
         this.dataPhases.push({id: 0, nombre : name.trim(), scheluded_time_days: days, id_campana_proyecto : this.id_campana_proyecto, vobos: [], deleted : 0});
         this.haschanges = true;
      }
  });
  }

  editPhase(phaseEdit : any){
    console.log(phaseEdit);
    Swal.fire({
      title: 'Editar fase',
      html: `
        <input id="swal-input-name" class="swal2-input" placeholder="Nombre de la fase" value="`+phaseEdit.nombre +`">
        <input id="swal-input-days" type="number" class="swal2-input" placeholder="Días programados" value=`+phaseEdit.scheluded_time_days.toString() +`>
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`,
      preConfirm: () => {
        const name = (document.getElementById('swal-input-name') as HTMLInputElement).value.trim();
        const days = (document.getElementById('swal-input-days') as HTMLInputElement).value.trim();
    
        if (!name) {
          Swal.showValidationMessage('El nombre es necesario');
          return;
        }
    
        if (!days || isNaN(Number(days)) || Number(days) <= 0) {
          Swal.showValidationMessage('Ingrese un número válido de días');
          return;
        }
    
        return { name, days: Number(days) };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { name , days } = result.value as { name: string; days: number };
         phaseEdit.nombre = name;
         phaseEdit.scheluded_time_days = days;
         this.haschanges = true;
      }
  });

  }


  removeItem(item : any){
    this.haschanges = true;
    item.deleted = 1;
  }

  openAddEditVobo(vobo:any) {
    this.matDialog.open(ProjectsVoboAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        vobo : vobo,
        users : this.usersList,
        forms : this.formList
      }
    }).afterClosed().subscribe(
      resp => {
      
        
      }
    );
  }

  ruSure() {
    Swal.fire({
      title: '¿Esta seguro que desea guardar la configuración?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Guardando...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        this.projectService.saveProjectVobos({id_proy : this.id_campana_proyecto, users : JSON.stringify(this.user_selected), phases: this.dataPhases}).subscribe(
          (result:any) => {
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                text: 'Datos guardados correctamente'          
              }).then(() => {            
                this.reloadProjectData();
              });
            }
          },
          (err:any) => {
              console.log(err);
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
              });
            }
          
        );
      }
    });
  }


  addDiagramacion(){
    this.haschanges = true;
    let newVobo = {id: 0, area: '', scheluded_time_days: 0, id_campana_proyecto: this.id_campana_proyecto, deleted : 0};
    this.diagramacionItems.push(newVobo)
    this.openAddEditVobo(newVobo);
  }

  addArtes(){
    this.haschanges = true;
    let newVobo = {id: 0, area: '', scheluded_time_days: 0, id_campana_proyecto: this.id_campana_proyecto, deleted : 0};
    this.artesItems.push(newVobo);
    this.openAddEditVobo(newVobo);
  }
}