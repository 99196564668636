<div style="display: flex; justify-content: flex-end;">
  <button type="button" class="btn-close" [mat-dialog-close]=""></button>
</div>
<h1 *ngIf="!isEdit" mat-dialog-title class="m-0">Nuevo Medio</h1>
<h1 *ngIf="isEdit" mat-dialog-title class="m-0">Editar Medio</h1>

<div class="modal-body-scroll">
  <form class="inputform">
    <mat-form-field appearance="outline">
      <mat-label>Nombre: </mat-label>
      <input matInput [(ngModel)]="dataSt.name" name="name" required>
    </mat-form-field>

    <mat-slide-toggle [(ngModel)]="dataSt.multipleItems" name="select1">
      Permitir múltiples elementos por sucursal
    </mat-slide-toggle>
    <!--<mat-slide-toggle [(ngModel)]="dataSt.filtroSucursales" name="select3">
      Filtro Sucursales
    </mat-slide-toggle>-->
    <mat-slide-toggle [(ngModel)]="dataSt.sucursalesVacias" name="select2">
      Permitir dejar sucursales vacías
    </mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="dataSt.segmentable" name="select3">
      Segmentable
    </mat-slide-toggle>



    <mat-form-field appearance="outline">
      <mat-label>Formato de Distribución</mat-label>
      <mat-select  [(ngModel)]="selectedFormato" name="formato">
        <mat-option [value]="2">Por Prioridad</mat-option>
        <mat-option [value]="1">Por Reglas</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="mb-4" *ngIf="selectedFormato == 2">
      <div class="row align-items-center"> 
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Atributo</mat-label>
            <mat-select [(ngModel)]="prioridadConf[0].id" (selectionChange)="setItemName($event.value, prioridadConf[0])" name="atributo-formato">
              <mat-option *ngFor="let atributo of atributos" [value]="atributo.id">{{ atributo.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        <div class="col-md-3 d-flex justify-content-center">
          <span class="x-label">Multiplicado por:</span> 
        </div>
    
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Valor</mat-label>
            <input [(ngModel)]="prioridadConf[0].multiplicador" name="multiplicadorc" matInput placeholder="Ej: 10" />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <h5 class="fw-bold text-primary">Filtro sucursales</h5>
      <p *ngIf="reglasAlcance.length>0"><span class="tinyfont">*Cantidad de sucursales filtradas : <b>{{ sucursalesCount }}</b></span></p>
      <div *ngFor="let regla of reglasAlcance; let i = index; trackBy: trackByFn" class="mb-3">
        <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Atributo</mat-label>
              <mat-select [(ngModel)]="regla.id" name="atributo-{{i}}" (selectionChange)="setItemName($event.value, regla)">
                <mat-option *ngFor="let atributo of atributos" [value]="atributo.id">{{ atributo.name }}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-md-3">
            <mat-form-field *ngIf="getItem(regla.id) != null" appearance="outline" class="form-field">
              <mat-label>Condición</mat-label>
              <mat-select [(ngModel)]="regla.operador" name="operador-{{i}}">
                <ng-container>
                  <mat-option *ngIf="getItem(regla.id).type_id == 4 || getItem(regla.id).type_id == 6 || getItem(regla.id).type_id == 5 || getItem(regla.id).type_id == 3 || getItem(regla.id).type_id == 1" [value]="1">Igual que</mat-option>
                  <mat-option *ngIf="getItem(regla.id).type_id == 4 || getItem(regla.id).type_id == 6 " [value]="2">Mayor que</mat-option>
                  <mat-option *ngIf="getItem(regla.id).type_id == 4 || getItem(regla.id).type_id == 6 " [value]="3">Menor que</mat-option>

                </ng-container>

              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field *ngIf="getItem(regla.id) != null" appearance="outline" class="form-field">
              <mat-label>Valor</mat-label>
              <input matInput [hidden]="getItem(regla.id).type_id != 4" [(ngModel)]="regla.valor" name="valor-{{i}}" (blur)="calculateStores()" type="number" placeholder="Valor de {{getRanges(getItem(regla.id).select_data)[0]}} a {{getRanges(getItem(regla.id).select_data)[1]}}" min="{{getRanges(getItem(regla.id).select_data)[0]}}" max="{{getRanges(getItem(regla.id).select_data)[1]}}">
              <input matInput [hidden]="getItem(regla.id).type_id != 6" [(ngModel)]="regla.valor" name="valor-{{i}}" (blur)="calculateStores()" type="number"  placeholder="Ej: 10" />
              <input matInput [hidden]="getItem(regla.id).type_id != 5" [(ngModel)]="regla.valor" name="valor-{{i}}" (blur)="calculateStores()" placeholder="Ej: Valor" />
              <ng-container *ngIf="getItem(regla.id).type_id == 3">
                <mat-select  [(ngModel)]="regla.valor" (selectionChange)="calculateStores()" name="valor-{{i}}">
                  <mat-option *ngFor="let valor of converToObject(getItem(regla.id).select_data)" [value]="valor" >{{ valor }}</mat-option>
                </mat-select>
              </ng-container>
              <ng-container *ngIf="getItem(regla.id).type_id == 1" >
                <mat-select  [(ngModel)]="regla.valor" name="valor-{{i}}" (selectionChange)="calculateStores()">
                  <mat-option  [value]="'SI'">SI</mat-option>
                  <mat-option  [value]="'NO'">NO</mat-option>
                </mat-select>
              </ng-container>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <button mat-icon-button color="warn" (click)="eliminarRegla(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <button mat-flat-button color="primary" (click)="agregarRegla()">
        <mat-icon>add</mat-icon> Añadir Regla
      </button>
    </div>
  </form>
</div>

<!-- Footer del modal -->
<div mat-dialog-actions class="button-container">
  <button mat-raised-button class="nest-button" *ngIf="!isEdit" (click)="register()" [disabled]="invalidInput()">Agregar</button>
  <button mat-raised-button class="nest-button" *ngIf="isEdit" (click)="edit()" [disabled]="invalidInput()">Editar</button>
</div>