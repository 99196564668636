import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { ProjectPhase } from 'src/app/models/project.model';

@Component({
  selector: 'app-project-diagramation-vobos-config',
  templateUrl: './project-diagramation-vobos-config.component.html',
  styleUrls: ['./project-diagramation-vobos-config.component.scss']
})
export class ProjectDiagramationVobosConfigComponent implements OnInit {

  voboForm!: FormGroup;
  phase: ProjectPhase = new ProjectPhase();
  sel_vobos: any = {};

  constructor(
    public dialogRef: MatDialogRef<ProjectDiagramationVobosConfigComponent>,
    private mainService : MainService,
    private fb: FormBuilder,  
    @Inject(MAT_DIALOG_DATA) public data: any 
  ) {

    this.phase = data.phase;
    this.sel_vobos = data.sel_vobos;
  }

  guardarVobos() {
    if (this.voboForm.valid) {
      this.dialogRef.close(this.voboForm.value);
    }
  }

  ngOnInit(): void {
    const ob_fc: any = {};
    const fc = this.phase.vobos.map((e:any)=>{ 
      let obj_vobo:any = {};
      obj_vobo[e.area] = ['', Validators.required];
      return obj_vobo;
    });
    this.voboForm = this.fb.group(fc.reduce((prev, curr) => ({...prev, ...curr}) , {}));
    if(this.sel_vobos) this.setForm();
  }
  setForm() {
    const sel_data = this.sel_vobos.vobos.map((e:any)=> {
      let res_data: any = {};      
      res_data[e.area] = e.users;
      return res_data;
    });
    console.log(sel_data.reduce((prev: any, curr: any) => ({...prev, ...curr}) , {}));
    const new_vobos = this.phase.vobos.map((e:any)=>e.area).filter((e:any)=> !sel_data.map((s:any)=> Object.keys(s)).flat().includes(e));
    new_vobos.map((e=> {
      const new_ob:any = {};
      new_ob[e] = [];
      sel_data.push(new_ob);
    }));
    this.voboForm.setValue(sel_data.reduce((prev: any, curr: any) => ({...prev, ...curr}) , {}));
  }
  
  cerrarModal() {
    this.dialogRef.close();
  }
}
