<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">{{ isEdit? 'Editar' : 'Nuevo ' }} Visto Nuevo</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h6>Ingresa la siguiente información:</h6>
    <form #f="ngForm">
        <div class="row">
            <div class="col mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre del Área</mat-label>
                    <input  matInput placeholder="Ej: BEBÉS" name="name" [(ngModel)]="newVobo.area" required>
                    <mat-icon matSuffix>users</mat-icon>
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Dias programados</mat-label>
                    <input  matInput matTooltip="Dias limite en el que el visto bueno tiene para ser aprobado respecto al visto bueno anterior" placeholder="Ej: 2" name="time" [(ngModel)]="newVobo.scheluded_time_days" type="number" required>
                    <mat-icon matSuffix>schelude</mat-icon>
                </mat-form-field>
                <mat-slide-toggle [disabled]="hasProveedor && !newVobo.is_provider_vobo" name="is_provider_vobo" [(ngModel)]="newVobo.is_provider_vobo">Proveedor</mat-slide-toggle>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label *ngIf="!newVobo.is_provider_vobo">Usuarios del area</mat-label>
                    <mat-label *ngIf="newVobo.is_provider_vobo">Permisos de archivo</mat-label>
                    <mat-select  [(ngModel)]="newVobo.users" name="users_name" multiple>
                        <mat-option *ngFor="let user of usersList"  [value]="user.id">{{ user.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!newVobo.is_provider_vobo" class="w-100" appearance="outline">
                    <mat-label>Folmulario de VoBo</mat-label>
                    <mat-select [(ngModel)]="newVobo.vobos" name="nombre_form" multiple>
                        <mat-option *ngFor="let form of formsList"  [value]="form.id">{{ form.nombre }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!newVobo.is_provider_vobo" class="w-100" appearance="outline">
                    <mat-label>Nuevo Campo</mat-label>
                    <input matInput placeholder="Ej: VoBo" name="Vobo" [(ngModel)]="itemToAdd">
                    <mat-icon matSuffix>check</mat-icon>
                </mat-form-field>
                <button *ngIf="!newVobo.is_provider_vobo" mat-flat-button color="primary" (click)="saveFormulario()">
                    <mat-icon>add</mat-icon> Añadir nuevo campo a Formulario
                </button>
            </div>
        </div>

    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close class="bg-success text-white" >Ok <mat-icon>save</mat-icon></button>
</div>