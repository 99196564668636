<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Ordenar Fases</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">

   
    <form style="max-height: 350px; overflow-y: scroll;" autocomplete="off" class="mt-3" id="userForm">
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
         
                <div *ngFor="let phase of phases; track phase" class="example-box" cdkDrag>
                  <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                  {{ phase.nombre  }}
                </div>
    
          </div>
    </form>
</div>
<hr>
<div class="modal-footer">
    <button mat-flat-button class="bg-success text-white" form="userForm"  (click)="saveReOrder()">Aceptar <mat-icon>save</mat-icon></button>
</div>