<div class="modal-header">
    <h4 *ngIf="edit" class="modal-title" id="mondalAddCampaignTitle">Editar diagramacion</h4>
    <h4 *ngIf="!edit" class="modal-title" id="mondalAddCampaignTitle">Diagramar</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body" style="min-height: 580px;">       
        <mat-divider></mat-divider>
        <div>
            <mat-horizontal-stepper [linear]="true" #stepper>
                <!-- Paso 1 -->
                <mat-step [stepControl]="firstFormGroup">
                  <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Paso 1 - Selección POP</ng-template>
                    <!--<mat-form-field>
                      <mat-label>Campo 1</mat-label>
                      <input matInput formControlName="medio" required>
                      <mat-error *ngIf="firstFormGroup.get('medio')?.hasError('required')">
                        Este campo es obligatorio
                      </mat-error>
                    </mat-form-field>-->
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="row">
                                    <mat-form-field appearance="outline" class="form-field">
                                        <mat-label>Medio</mat-label>
                                        <mat-select [disabled]="edit" matInput formControlName="medio" (selectionChange)="medioSelected($event.value);" required name="medio">
                                          <mat-option *ngFor="let m of medios" [value]="m.id">{{ m.nombre }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="firstFormGroup.get('medio')?.hasError('required')">
                                           El medio es requerido.
                                        </mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field appearance="outline" class="form-field">
                                        <mat-label>Elemento POP</mat-label>
                                        <mat-select (selectionChange)="popSelected($event.value);"  [disabled]="(firstFormGroup.get('medio')?.value == '')||edit" matInput formControlName="elemento_pop" required name="elemento_pop">
                                          <mat-option *ngFor="let m of elementos" [value]="m.id">{{ m.nombre }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="firstFormGroup.get('elemento_pop')?.hasError('required')">
                                           El elemento POP es requerido.
                                        </mat-error>
                                    </mat-form-field>
    
                                    <mat-form-field appearance="outline" class="form-field" [hidden]="!hasPositions">
                                        <mat-label>Posición</mat-label>
                                        <input type="number" [min]="min_pos" [max]="max_pos" [readonly]="edit" (change)="positionSelected($event)"  matInput formControlName="position"  required>
                                        <mat-error *ngIf="firstFormGroup.get('position')?.hasError('required')">
                                          Este campo es obligatorio
                                        </mat-error>
                                    </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-9">
                              <div class="selected-codes">
                                <span><b>Promociones seleccionadas:</b> <span *ngIf="selectedPromotions.length == 0">Ninguno</span> <span *ngIf="selectedPromotions.length > 0">{{ selectedPromotions.length }}</span></span>
                              
                              </div>
                              <div class="overflow-auto w-200">
                                <mat-form-field>
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event, dspromotionFile)" placeholder="Dangler" #input>
                                </mat-form-field>
                            </div>
                              <div class="promo-container mat-elevation-z0 table-responsive text-nowrap" style="max-height: 300px; overflow-y: auto; padding: 10px; border: 1px solid #ccc;">
                                <table mat-table [dataSource]="dspromotionFile" matSort #sort="matSort" class="mat-elevation-z8" style="width: 100%;">
                                  
                                  <!-- Checkbox para seleccionar todos -->
                                  <ng-container matColumnDef="select">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef style="min-width: 80px; margin-left: 14px;"> 
                                      <input type="checkbox" (change)="selectAll($event)">
                                    </th>
                                    <td  class="text-center" mat-cell *matCellDef="let promo" style="min-width: 80px; margin-left: 14px;">
                                      <input type="checkbox" (change)="toggleSelection(promo['CODIGO PRINCIPAL'])" [checked]="selectedPromotions.includes(promo['CODIGO PRINCIPAL'])">
                                    </td>
                                  </ng-container>
                              
                                  
                                  <ng-container *ngFor="let column of displayedColumns">
                                    <ng-container matColumnDef="{{column}}">
                                      <th mat-header-cell *matHeaderCellDef  style="min-width: 170px; margin-left: 14px;"> {{ column }} </th>
                                      <td mat-cell *matCellDef="let promo"  style="min-width: 170px; margin-left: 14px;"> {{ promo[column] }} </td>
                                    </ng-container>
                                  </ng-container>
                              
                                  <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: allColumns;"></tr>
                                </table>
                                <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                              </div>
                        </div>
    
                    </div>

                    <div>
                      <button mat-raised-button matStepperNext color="primary" [disabled]="!(firstFormGroup.valid)||selectedPromotions.length==0" (click)="setSecondData()">Siguiente</button>
                    </div>
                  </form>
                </mat-step>
              
                <!-- Paso 2 -->
                <mat-step [stepControl]="secondFormGroup">
                  <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>Paso 2 - Inventarios y Sucursales</ng-template>

                    <div class="row">
                        <div class="col-sm-3">
                            <div class="row  text-center">
                                <button (click)="goToConfig()" mat-flat-button color="primary" class="same-height" style="width: 190px;">
                                    <mat-icon>edit</mat-icon>  Configuración VoBos
                                 </button>
                            </div>
                            <br>
                            <table class="table table-hover">
                                <thead>
                                  <tr>
                                    <th class="text-center" scope="col">Fases Configuradas</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngFor="let ph of phases">
                                    <tr>
                                      <td>{{ph.name}} 
                                        <p *ngIf="!ph.selected" (click)="openVobosDiagramConfigDialog(ph)" class="badge badge-info"  data-toggle="tooltip" data-placement="right" matTooltip="Selecciona los usuarios para el vobo por area." title="Cantidad de Vobos">Selecciona usuarios</p>
                                        <p *ngIf="ph.selected" (click)="openVobosDiagramConfigDialog(ph)" class="badge badge-success"  data-toggle="tooltip" data-placement="right" matTooltip="Edita los usuarios seleccionados para el vobo por area." title="Cantidad de Vobos">Edita usuarios</p>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </tbody>
                            </table>

                        </div>
                        <div class="col-sm-9">
                                                          
                            <div class="selected-codes">
                                <span><b>Sucursales seleccionadas: </b> <span *ngIf="selectedStores.length == 0">Ninguna</span> <span *ngIf="selectedStores.length > 0">{{ selectedStores.length }}</span></span>
                              </div>
                              <div class="overflow-auto w-200">
                                <mat-form-field>
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event, dsstoreFile)" placeholder="Dangler" #input>
                                </mat-form-field>
                            </div>
                              <div class="promo-container mat-elevation-z0 table-responsive text-nowrap" style="max-height: 260px; overflow-y: auto; padding: 10px; border: 1px solid #ccc;">
                                <table mat-table [dataSource]="dsstoreFile" matSort #sortSuc="matSort" class="mat-elevation-z8" style="width: 100%;">
                                  
                                  <!-- Checkbox para seleccionar todos -->
                                  <ng-container matColumnDef="select">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef style="min-width: 80px; margin-left: 14px;"> 
                                      <input type="checkbox" [hidden]="(same_stores||!segmentable)&&!edit" (change)="toggleSelectionStore(0, $event)">
                                    </th>
                                    <td  class="text-center" mat-cell *matCellDef="let store" style="min-width: 80px; margin-left: 14px;">
                                      <input type="checkbox" [hidden]="(same_stores||!segmentable)&&!edit" (change)="toggleSelectionStore(store['id'], null)" [checked]="selectedStores.includes(store['id'])">
                                    </td>
                                  </ng-container>
                              
                              
                                  <ng-container *ngFor="let column of displayedColumnsStore">
                                    <ng-container matColumnDef="{{column}}">
                                      <th mat-header-cell *matHeaderCellDef  style="min-width: 80px; margin-left: 14px;"> {{ column }} </th>
                                      <td mat-cell *matCellDef="let store"  style="min-width: 80px; margin-left: 14px;"> {{ store[column] }} </td>
                                    </ng-container>
                                  </ng-container>
                              
                                  <tr mat-header-row *matHeaderRowDef="allColumnsStore"></tr>
                                  <tr mat-row *matRowDef="let row; columns: allColumnsStore;"></tr>
                                </table>
                                <mat-paginator #paginatorSuc [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                              </div>

                              <table class="table">
                                <thead>
                                  <tr class="text-center">
                                    <!-- <th scope="col">Inventario Inicial</th>
                                    <th scope="col">Inventario Disponible</th> -->
                                    <th scope="col">Inventario Asignado</th>
                                    <!-- <th scope="col">Inventario Selecionado</th> -->
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="text-center">
                                    <td>{{cant_sel}}</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
    
                    </div>


                    <div>
                      <button mat-button matStepperPrevious>Anterior</button>
                      <button mat-raised-button color="primary" (click)="guardarDiagrama()" [disabled]="this.stores_sel.length==0||vobos_sel.length!=phases.length" ><mat-icon>save</mat-icon> Finalizar y guardar</button>

                    </div>
                  </form>
                </mat-step>
              </mat-horizontal-stepper>
        </div>
    </div>
</mat-dialog-content>