import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CampaignLogsComponent } from '../../shared/dialogs/campaign-logs/campaign-logs.component';
import Swal from 'sweetalert2';
import { ProjectsProvidersResponseComponent } from '../../shared/dialogs/projects-providers-response/projects-providers-response.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectsProvidersFormHistoryComponent } from '../../shared/dialogs/projects-providers-form-history/projects-providers-form-history.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsProvidersViewConceptoFileComponent } from '../../shared/dialogs/projects-providers-view-concepto-file/projects-providers-view-concepto-file.component';
import { ProjectsProvidersImgAddFileComponent } from '../../shared/dialogs/projects-providers-view-img-file/projects-providers-view-img-file.component';
import { AuthService } from 'src/app/services/auth.service';
import { ProvidersSurveysLogsComponent } from '../../shared/dialogs/providers_surveys_logs/providers_surveys_logs.component';


@Component({
  selector: 'app-projects-providers-view',
  templateUrl: './projects-providers-view.component.html',
  styleUrls: ['./projects-providers-view.component.scss']
})
export class ProjectsProvidersViewComponent implements OnInit {
  
  viewData : string = "";
  formMaterial!: FormGroup;
  dataGet : any = { provider_suv : null };
  bodyForm : FormData = new FormData(); 
  isLogged : boolean = false;
  formProvider!: FormGroup;
  id_provider_survey : number = 0;
  id_ps = 0;
  isUserView = false;
  surveyResponse : any = {};
  formulario : Array<any> = [];

  constructor(
    private matDialog: MatDialog,
    private fb: FormBuilder,
    private projectService : ProjectsService,
    private main : MainService,
    private _params: ActivatedRoute,
    private _router : Router,
    private auth: AuthService,
    private cdr: ChangeDetectorRef
  ) { 
    this._params.params.subscribe(params => {
      console.log(params);
      if(params.id_usr){
        this.id_ps = params.id;
        this.getPreView();
      }else{
        if(params.id){
          this.viewData = params.id;
          this.getView();
        }else{
          this._router.navigateByUrl('/login');
        }  
      } 
    });
  }

  ngOnInit(): void {
    this.checkIfLogged();
    if(this.isLogged){
      let dataAux : any =this.projectService.getTokenData(this.viewData)
      this.id_provider_survey =  dataAux.id_provider_survey;
    }
    //this.createFormProvider();

    //this.setValueToggles();
  }

  reloadUi(){
    this._params.params.subscribe(params => {
      console.log(params);
      if(params.id_usr){
        this.id_ps = params.id;
        this.getPreView();
      }else{
        if(params.id){
          this.viewData = params.id;
          this.getView();
        }else{
          this._router.navigateByUrl('/login');
        }  
      } 
    });
  }

  checkIfLogged(){
    this.isLogged = this.auth.isAuthenticated()
  }

  createFormProvider() {
    this.surveyResponse = {
      id_member : this.dataGet.memb_sts[0].id,
      id_provider_survey : this.dataGet.provider_suv[0].id,
      respuesta : "",
      aprobado : false,
      password : "",
      comentario : ""
    }
   //this.formProvider = this.fb.group({
   //  id_member : [this.dataGet.memb_sts[0].id],
   //  id_provider_survey : [this.dataGet.provider_suv[0].id],
   //  desc_producto : [true],
   //  sku : [true],
   //  imagen_vobo : [true],
   //  mec_promo : [true],
   //  pub_vobo : [true],
   //  razon_social : [this.dataGet.memb_sts[0].razon_social, [ Validators.required]],
   //  empresa_recuperadora : ["", [ Validators.required]],
   //  concepto : ["", [ Validators.required]],
   //  monto : ["", [ Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/) ]],
   //  metodo_pago : ["", [ Validators.required]],
   //  desc_producto_com : [""],
   //  sku_com : [""],
   //  imagen_vobo_com : [""],
   //  mec_promo_com : [""],
   //  pub_vobo_com : [""],
   //  password : [""],
   //});

   //this.bodyForm  = new FormData(); 
  }
  
  createFormProviderBasic() {
    this.surveyResponse = {
      id_member : 0,
      id_provider_survey : 0,
      respuesta : "",
      aprobado : false,
      password : "",
      comentario : ""
    }
  //this.formProvider = this.fb.group({
  //  id_member : [0],
  //  id_provider_survey : [0],
  //  desc_producto : [true],
  //  sku : [true],
  //  imagen_vobo : [true],
  //  mec_promo : [true],
  //  pub_vobo : [true],
  //  razon_social : ["", [ Validators.required]],
  //  empresa_recuperadora : ["", [ Validators.required]],
  //  concepto : ["", [ Validators.required]],
  //  monto : ["", [ Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/) ]],
  //  metodo_pago : ["", [ Validators.required]],
  //  desc_producto_com : [""],
  //  sku_com : [""],
  //  imagen_vobo_com : [""],
  //  mec_promo_com : [""],
  //  pub_vobo_com : [""],
  //  password : [""],
  //});

  //this.bodyForm  = new FormData(); 
  }

  createFormMaterial() {
    this.formMaterial = this.fb.group({
      toggle1: ['', [ Validators.required]]
    });
  }

  openDialogChat() {
    console.log(this.id_provider_survey);
    this.matDialog.open(ProvidersSurveysLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
          id_provider_survey : this.id_provider_survey
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  locked : boolean = false;

  getView(){
    //this.removeFileImg()
    //this.removeFileConcepto();
    this.main.ShowLoading();
    this.projectService.getProviderView(this.viewData).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.dataGet = result.data;
       this.getFormulario();
        if(this.dataGet.provider_suv[0].locked == 1){
          this.locked = true;
          Swal.fire({
            icon: 'warning',
            title: 'Este formulario se encuentra bloqueado',
            text: 'No puede responder este formulario por que se encuentra bloqueado',
            allowOutsideClick: false,
            showConfirmButton: true
          });
        }

        if(this.dataGet.provider_suv[0].status == 2){
          this.locked = true;
          Swal.fire({
            icon: 'warning',
            title: 'Este formulario se encuentra aprobado',
            text: 'Ya no puede responder este formulario por que ya se encuentra aprobado',
            allowOutsideClick: false,
            showConfirmButton: true
          });
        }

       this.createFormProvider();
       console.log(this.dataGet);
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros de proveedores.'
          });
        
      }
    );
  }

  getPreView(){
   // this.removeFileImg()
   // this.removeFileConcepto();
    this.main.ShowLoading();
    this.projectService.getProviverSurveyPreview(this.id_ps).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.dataGet = result.data;
       this.locked = true;
       this.isUserView = true;
       this.getFormulario();
        if(this.dataGet.provider_suv[0].locked == 1){
          this.locked = true;
          Swal.fire({
            icon: 'warning',
            title: 'Este formulario se encuentra bloqueado',
            text: 'No puede responder este formulario por que se encuentra bloqueado',
            allowOutsideClick: false,
            showConfirmButton: true
          });
        }
        if(this.dataGet.provider_suv[0].status == 2){
          this.locked = true;
          Swal.fire({
            icon: 'warning',
            title: 'Este formulario se encuentra aprobado',
            text: 'Ya no puede responder este formulario por que ya se encuentra aprobado',
            allowOutsideClick: false,
            showConfirmButton: true
          });
        }

       this.createFormProviderBasic();
       console.log(this.dataGet);
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros de proveedores.'
          });
        
      }
    );
  }

  getFormulario(){
    let formularioAux = JSON.parse(this.dataGet?.provider_suv[0]?.formulario);
    this.formulario = formularioAux.map((x:any)=> ({ id: x.id, nombre : x.nombre, respuesta : true, comentario : "" }));
  }

  detectViewChange(){
    this.cdr.detectChanges();
  }

  ruSure() {
      if(this.dataGet.memb_sts[0].can_edit == 1){
        
        Swal.fire({
          title: this.formulario.some((x: any) => x.respuesta === false) ? '¿Está seguro que deseas desaprobar el material?' : '¿Está seguro que deseas aprobar el material?',
          text: 'Por favor, ingresa tu contraseña para confirmar tus cambios.',
          icon: 'warning',
          input: 'password',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
         // footer: '<a href="">Olvidé mi contraseña</a>'
        }).then((result) => {      
          if (result.isConfirmed) {
            this.surveyResponse.password = result.value;
            this.savePasswordResponse();
          }
        });
      }
  }

 private savePasswordResponse(){
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.surveyResponse.aprobado = this.formulario.every(item => item.respuesta);
    this.surveyResponse.respuesta = JSON.stringify(this.formulario);
    console.log("saving")
    this.projectService.saveResponse(this.surveyResponse).subscribe(
      (result:any) => {
     // if(result.message == "Success") {
     //  Swal.fire({
     //    icon: 'success',
     //    title: 'Guardado',
     //    text: 'Respuesta guardada de manera correcta!'          
     //  }).then(() => {            
     //    this.getView();
     //  });
     // 
     // 
     // }
        console.log("respuesta salvada, subiendo imagenes", result.id_response);
        this.uploadImg(result.id_response)
      },
      (err:any) => {
        if(err.error.message.indexOf("Member not valid") > -1)
        {
          Swal.fire({
            icon: 'error',
            title: 'Constraseña no válida',
            text: 'La constraseña es incorrecta.'
          });
        }
        else {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }

        
      }
    );
  }

  openDialogReject() {
    this.matDialog.open(ProjectsProvidersResponseComponent, {
      panelClass: ['dialogs-sm'],
      data: {}
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }
  
  openDialogFormHistory() {
    this.matDialog.open(ProjectsProvidersFormHistoryComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        responses : this.dataGet?.responses,
        files : this.dataGet?.files,
        nombre_proveedor : this.dataGet?.provider_suv[0]?.nombre_proveedor,
        folder : this.dataGet?.provider_suv[0]?.folder
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  fileComentario: File[] = [];

  fileConcepto: File | undefined = undefined;
  fileImage: File | undefined = undefined;


  openUploadFileComentario() {
    this.matDialog.open(ProjectsProvidersViewConceptoFileComponent, {
      panelClass: ['dialogs-sm'],
      data: {
      }
    }).afterClosed().subscribe(
      resp => {
        if (resp && resp.files) {
          this.fileComentario = [...(this.fileComentario || []), ...resp.files];
          console.log(this.fileComentario);
        }
      }
    );
  }


  openUploadFileConcepto() {
    this.matDialog.open(ProjectsProvidersViewConceptoFileComponent, {
      panelClass: ['dialogs-sm'],
      data: {
      }
    }).afterClosed().subscribe(
      resp => {
        this.fileConcepto = resp.file;
        console.log( this.fileConcepto );
      }
    );
  }

  openUploadFileImg() {
    this.matDialog.open(ProjectsProvidersImgAddFileComponent, {
      panelClass: ['dialogs-sm'],
      data: {
      }
    }).afterClosed().subscribe(
      resp => {
        this.fileImage = resp.file;
        console.log(this.fileImage);
      }
    );
  }

  uploadImg(id_resp : number){
    console.log("uploading files")
    this.bodyForm.append("id_provider_survey_response", id_resp.toString());
    this.bodyForm.append("id_member", this.dataGet.memb_sts[0].id);
    this.bodyForm.append("password", this.surveyResponse.password);
    this.bodyForm.append("folder", this.dataGet?.provider_suv[0]?.folder);
    (this.fileComentario as File[]).forEach(file => {
      this.bodyForm.append("blob", file, file.name);
  })

    this.projectService.uploadProviderResponseFile(this.bodyForm).subscribe(
      (result:any)=>{
        if(result.message == 'Success'){
            Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Respuesta guardada de manera correcta!'          
          }).then(() => {            
            this.getView();
          });
        }
        else {
          Swal.fire({
            icon: 'success',
            title: 'Guardado.',
            text: 'Respuesta guardada de manera correcta!'          
          }).then(() => {            
            this.getView();
          });
        }
      },
      (err:any)=>{
        this.main.HideLoading();
        Swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: 'Respuesta guardada de manera correcta!'          
        }).then(() => {            
          this.getView();
        });
      }
    )

  }

  removeFileImg(){
   this.fileImage = undefined;
  }

  removeFileConcepto(){
    this.fileConcepto = undefined;
  }

  removeFileComentario(index: number) {
    this.fileComentario = this.fileComentario.filter((_, i) => i !== index);
  }
}
