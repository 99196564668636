import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ProjectsService } from 'src/app/services/projects.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { MainService } from 'src/app/services/main.service';
import { CutoffService } from 'src/app/services/cutoff.service';
import { NewPopConfigComponent } from '../..//shared/dialogs/new-pop-config/new-pop-config.component';

@Component({
  selector: 'app-project-pop-config',
  templateUrl: './project-pop-config.component.html',
  styleUrls: ['./project-pop-config.component.scss']
})
export class ProjectPopConfigComponent {
    selectedSucursalCorte: any;
    reglasAlcance: any[] = [];
    alcance: string = '';
    codigosPromo = [
      "7501089809452",
      "7501089809452",
      "7501089809469",
      "7501089809469",
    ]

    sucursalesCorte = [
      { nombre: 'Corte Septiembre' },
      { nombre: 'Corte Agosto' }
    ];
  
   //digramationList = [{pop_id: 0, name: "MDP Trovicel", price: 135, cantidad: 1, reglas : [{
   //  atributo: 'MDP Trovicel',
   //  comparador: null,
   //  valor: 1
   //}]}];

    atributosSucursales : Array<any> = [];
    comparadores = ['Igual a', 'Mayor o igual que', 'Menor o igual que'];

    popid = 0;
    popActual : any = [];
    mediaActual : any;
    elementosConfig : any = [];
  
    constructor(private matDialog: MatDialog, private projService : ProjectsService, private route: ActivatedRoute, private router: Router,
      private _main : MainService, private _cutoffS: CutoffService, private cdr: ChangeDetectorRef
    ) {
      //this.agregarRegla(this.digramationList[1].reglas); 
    }

    ngOnInit(): void {
      this.getAtributos();
      this.getData();
    }
  
    onFileSelected(event: any) {
      const file = event.target.files[0];
      // Lógica para manejar el archivo
      console.log(file);
    }
  
    agregarRegla(list : any) {
      if(!list){
        list = [];
      }
      list.push({
        id: 0,
        nombre:  '',
        multiplicador: 1
      });
      this.cdr.detectChanges();
    }
  
    eliminarRegla(index: number, list :any) {
      list.splice(index, 1);
    }
  
    openAddEdit(isEdit : boolean, row : any = null) {
      this.matDialog.open(NewPopConfigComponent, {
        panelClass: ['dialogs-md'],
        data: {
          popid:  this.popActual.id,
          mediaActual : this.mediaActual,
          isEdit : isEdit,
          config: row
        }
      }).afterClosed().subscribe(resp => {
        this.getAtributos();
        this.getData();
      });
    }


    getPopList(id:number){
      // return this.projService.poplist.filter(x=>( x.id == id))[0];
 
       this._main.ShowLoading();
       this.projService.getPOPConfigs(id).subscribe((x: any) => {
         this._main.HideLoading();
         console.log(x.data);
         this.mediaActual = x.data.medio;
         this.popActual = x.data.elemento_pop;
         this.elementosConfig = x.data.configs;
 
       //ESTO DEBE DE ESTAR EN TODAS LOS COMPONENTESD DE PROYECTOS
       this._cutoffS.checkIfSameProjectThenReplace(this.mediaActual.id_campana_proyecto).then(x=>{
         this.getInventory();
       });
 
         this.elementosConfig.forEach((x:any) => {
             x?.reglas_distribucion ? x['reglas'] = this.converToObject(x?.reglas_distribucion) : x['reglas'] = [];
             //x['isQuantity'] = x.cantidad == 0 || x.cantidad == null ? false : true;
             x['isQuantity']  = false;
         });
       
       },
       (err:any) => {
         console.log(err);
             this._main.HideLoading();
             Swal.fire({
               icon: 'error',
               title: 'Error al obtener registros',
               text: 'Ocurrió un error al obtener registros, vuelva a intentarlo más tarde.'
             });
       });
     }


    showFilters = true;
    showCantidad = true;
    showPrecio = true;
    async getData(): Promise<void> {
      try {
       
        const url: ParamMap = await firstValueFrom(this.route.paramMap);
        this.popid = Number(url.get('popid'));

        this._main.ShowLoading();
        this._cutoffS.getAtributos().subscribe((x: any) => {
          this._main.HideLoading();
          this.atributosSucursales = x.data.filter((x:any)=> x.type_id == 4 || x.type_id == 6);
          this.getPopList(this.popid);
    
        });
        this._main.ShowLoading();
         this.getPopList(this.popid);
        if (!this.popid)
          throw new Error('No se recibió ningún ID.');
      } catch (error) {
        console.error(error);
      }
    }



    getAtributos(){
      this._main.ShowLoading();
      this._cutoffS.getAtributos().subscribe((x: any) => {
        this._main.HideLoading();
  
        this.atributosSucursales = x.data;
  
      });
    }

    setItemName(value : any, rule : any){
      const selectedAtributo = this.atributosSucursales.find((x:any) => x.id === value);
      if (selectedAtributo) {
          rule.nombre = selectedAtributo.name;
      }
    }

    converToObject(obj: string){
      try{
        return JSON.parse(obj);
      }catch(ex){
        return [];
      }
    }

    addDigramation(){
      Swal.fire({
        title: 'Nombre de la configuración',
        input: 'text',  
        inputPlaceholder: 'Nombre de la configuración',
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
        inputValidator: (value :string) => {
            if (!value) {
                return 'El nombre es necesario'
            }else{
              return "";
            }
        }
      }).then((result) => {
          if (result.isConfirmed) {
             this.elementosConfig.push({id: 0, id_elemento_pop : this.popid , nombre: result.value.trim(), isQuantity: false, precio:null, cantidad : null, reglas : []});
          }
      });
    }

  
    save(elemento : any){
      
      let registerData = { nombre : elemento.nombre, id_elemento_pop : elemento.id_elemento_pop, 
        
        reglas_distribucion : elemento.isQuantity? null : JSON.stringify(elemento.reglas), cantidad: elemento.isQuantity? elemento.cantidad : 0, precio : elemento.precio }
      
      Swal.fire({
       icon: 'info',
       title: 'Guardando...',
       allowEscapeKey: false,
       allowOutsideClick: false,
       showConfirmButton: false,
       didOpen: () => {
         Swal.showLoading();
       }
     });

     this.projService.createPOPConfig(registerData).subscribe(
       (result:any) => {
         if(result.message == "Success") {
           Swal.fire({
             icon: 'success',
             title: 'Guardado',
             html: 'POP guardado correctamente'
           }).then(() => {            
            this.getData();
           });
         }
       },
       (err:any) => {
         console.log(err);
         Swal.fire({
           icon: 'error',
           title: 'Error al guardar',
           text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
         });
       }
     );

    }

    edit(elemento : any){
      
      let registerData = {id: elemento.id, nombre : elemento.nombre, id_elemento_pop : elemento.id_elemento_pop,
        reglas_distribucion : elemento.isQuantity? null : JSON.stringify(elemento.reglas), cantidad: elemento.isQuantity? elemento.cantidad : 0, precio : elemento.precio }
      
      Swal.fire({
       icon: 'info',
       title: 'Guardando...',
       allowEscapeKey: false,
       allowOutsideClick: false,
       showConfirmButton: false,
       didOpen: () => {
         Swal.showLoading();
       }
     });

     this.projService.editPOPConfig(registerData).subscribe(
       (result:any) => {
         if(result.message == "Success") {
           Swal.fire({
             icon: 'success',
             title: 'Guardado',
             html: 'POP guardado correctamente'
           }).then(() => {            
            this.getData();
           });
         }
       },
       (err:any) => {
         console.log(err);
         Swal.fire({
           icon: 'error',
           title: 'Error al guardar',
           text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
         });
       }
     );

    }
    totales : Array<any> = [];
    loadedInventorys = false;
    getInventory(){
      this.loadedInventorys = false;
      this.projService.getProjectInvTotals(this.mediaActual.id_campana_proyecto).subscribe(
        (result:any) => {
          this.loadedInventorys = true;
         if(result.message == "Success") {
  
              let columnas_sum:any = {};
              //let columnas_total:any = {};
              let arr_reglas:any = [];
              let arr_cant:any = [];
              const def_fields_stores:any = ["id", "Plaza", "FarmID", "Filial", "Nombre"];
              
              result.totales.map((config:any)=> {
                  if(config.formato == 2) {
                      const r = JSON.parse(config.prioridad_filtro)[0];
                      r.cant_type = 3;
                      r.id_pop_config = config.id_pc;
                      r.pop_config = config.pop_config;
                      arr_reglas.push(r);
                  }
                  else if(!config.reglas_distribucion) {
                      const r = {cant_type: 1, multiplicador: 1, id_pop_config: config.id_pc, pop_config: config.pop_config};
                      arr_reglas.push(r);
                  }
                  else  {
                      const r = JSON.parse(config.reglas_distribucion)[0];
                      r.cant_type = 2;
                      r.id_pop_config = config.id_pc;
                      r.pop_config = config.pop_config;
                      arr_reglas.push(r);
                  }
              });
          
              let arr_fields_stores:any = [];
              arr_reglas.map((e:any)=> {
                  if(e.nombre) {
                    arr_fields_stores.push(e.nombre);
                    if(!arr_cant.some((ac:any)=> ac.atributo==e.nombre)) {
                      arr_cant.push({
                          id : e.id,
                          pop_config: e.pop_config,
                          atributo: e.nombre,
                          cantidad: parseInt(e.multiplicador),
                          tipo: e.cant_type
                      });
                    }
                  }
              });
              //const arr_fields_totals = arr_fields_stores;
              arr_fields_stores = def_fields_stores.concat(arr_fields_stores);
              
              let filtros:any = [];
              if(result.totales.pop_sucursales_filtro) filtros = JSON.parse(result.totales.pop_sucursales_filtro);
              if(result.totales.sucursales_filtro) filtros = [ ...filtros, ...JSON.parse(result.totales.sucursales_filtro)];
              
              const storeFile:any = this._cutoffS.filtrarSucursales(filtros).map((e:any)=>{ 
                  let obj_store:any = {};
                  arr_fields_stores.map((key:string)=> {
                    obj_store[key]= e[key];
                  });
                  return obj_store;
              });
  
              arr_fields_stores.map((key:string)=> {
                columnas_sum[key] = this._cutoffS.project_cutoff_file_data.map((e:any)=>e[key]).filter((e:any)=>e).map((e:any)=>parseInt(e)).reduce((a:any, b:any) => a + b, 0);
              });
              
              const dt_totales:any = [];
              arr_cant.map((e:any)=> { 
                  let cantidad = 0;
                  let sel_data = result.data.find((rd:any)=>rd.pop==e.pop_config);
                  if(e.cant_type == 1) {
                    cantidad = storeFile.length*e.cantidad;
                  }
                  else {
                    cantidad = columnas_sum[e.atributo]?columnas_sum[e.atributo]*e.cantidad:0;
                  }
                  const sel = sel_data?sel_data.cantidad:0;
                  const disp = cantidad-sel;
                  dt_totales.push({
                    id_atributo : e.id,
                    atributo: e.atributo,
                    inventario: cantidad,
                    disponible: disp,
                    seleccionado: sel
                  }) 
              });
              console.log(dt_totales);
              this.totales = dt_totales;
            }
        },
        (err:any) => {
          this.loadedInventorys = true;
          console.log(err);
        }
      );
    }

    findInventoryItem(id: number){
      if(this.loadedInventorys){
        try{
          return this.totales.filter((x:any)=> x.id_atributo == id )[0].inventario;
        }catch(ex){
          return "N/A"
        }

      }else{
        return 0;
      }
    }

  }