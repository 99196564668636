<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Enviar nuevo formulario proveedor</h4>    
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h5><strong>Material - {{ nombre }}</strong></h5>    
    <form [formGroup]="form">
       <!-- <ngx-file-drag-drop class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
        </ngx-file-drag-drop>-->
        <div class="row">            
            <div class="col-md-12">
                <mat-form-field class="w-100 mt-2" appearance="outline" >
                    <mat-label>Selecciona proveedor</mat-label>
                    <mat-select formControlName="id_provider" (selectionChange)="loadproviderInfo($event)" required>
                        <mat-option *ngFor="let p of providers"[value]="p.id">{{ p.nombre }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="members.length != 0" class="col-md-12  d-inline-block">
             <mat-expansion-panel >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Miembros  ({{  members.length }})
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="col-md-12  d-inline-block" style="overflow: auto; max-height: 200px;">
                    <mat-list >
                        <mat-list-item *ngFor="let member of members"><span style="font-size: 14px;">{{ member.nombre }} - {{ member.email }}  </span> <hr></mat-list-item>
                        <div *ngIf="members.length == 0">No hay miembros registrados para este proveedor</div>
                       
                    </mat-list>
                  
                </div>
              </mat-expansion-panel>
               <br/>
            </div>
           
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Mensaje para proveedor</mat-label>
                    <textarea formControlName="provider_msg" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5" rows="3"></textarea>
                </mat-form-field>
            </div>
            <!--<div *ngIf="members.length != 0" class="col-md-12  d-inline-block" style="overflow: auto; max-height: 200px;">
                <mat-list >
                    <h5>Miembros proveedor:</h5>
                    <mat-list-item *ngFor="let member of members"><span style="font-size: 14px;">{{ member.nombre }} - {{ member.email }}</span></mat-list-item>
                </mat-list>
            </div>-->
        </div>        
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" (click)="saveFile()">Enviar <mat-icon>save</mat-icon></button>
</div>