<div class="content-wrapper" style="background-color: white;">
    <div class="container-xl flex-grow-1 container-p-y">
        <div class="app-brand justify-content-center2 mb-4">
            <a href="index.html" class="app-brand-link gap-2">
                <span class="app-brand-logo demo">
                    <!--<img src="assets/img/Buho_Nest-BK.png" alt="" style="width: 250px;">-->
                    
                    <img src="../../../../assets/img/clients/fda2.png" alt="" style="width: 300px;">
                </span>
            </a>
        </div>
        <h4 class="fw-bold pt-3"><span class="text-muted fw-light">Proveedores /</span> {{ dataGet?.provider_suv[0]?.nombre_proveedor }} </h4>
        <mat-divider></mat-divider>
        <h5 class="pt-4 pb-2" *ngIf="!locked && dataGet?.memb_sts[0]?.can_edit == 1">Verifica el material adjunto y contesta el formulario <span
                class="text-success">APROBANDO </span>o <span class="text-danger">RECHAZANDO</span> el material según el concepto indicado.
        </h5>
        <div class="row mb-5">
            <div class="col-md-12 mb-3">
                <div class="card h-100">
                    <div class="card-body">
                        <h5 class="card-title">{{ dataGet?.provider_suv[0]?.nombre_campana  }}</h5>
                        <h6 class="card-subtitle text-muted">{{ dataGet?.provider_suv[0]?.created_at | date : "dd/MM/yyyy hh:mm"  }}</h6>                        
                    </div>
                    <div *ngIf="dataGet?.provider_suv != null" class="text-center">
                        <!--<iframe  [src]="'https://drive.google.com/file/d/' + dataGet?.provider_suv[0]?.archivo + '/preview' | safe "  width="95%"  height="500" allow="autoplay"></iframe>-->

                        <iframe [src]="dataGet?.provider_suv[0]?.archivo | safe "  width="95%"  height="500" allow="autoplay"></iframe>
                        <!--<iframe id="pdf" name="pdf" width="95%" height="500px" [src]="'https://drive.google.com/uc?export=view&id=' + dataGet?.provider_suv[0]?.archivo | safe "></iframe>-->
                    </div>   
                                
                    <!-- <div class="card-body text-center">
                        <button class="btn btn-xl btn-success" (click)="ruSure()">APROBAR <mat-icon class="align-middle">check</mat-icon></button>
                        <button class="ms-4 btn btn-xl btn-danger" (click)="openDialogReject()">RECHAZAR <mat-icon class="align-middle">close</mat-icon></button>
                    </div> -->
                    <div class="card-body" >
                        <div class="row">
                            <div class="col-sm-6">
                                <h4 class="card-title mt-4"  *ngIf="isUserView || dataGet?.memb_sts[0]?.can_edit == 1">Formulario de aprobación</h4>
                            </div>
                            <div *ngIf="isLogged" class="col-sm-6 mt-3 text-end">
                                <button *ngIf="!isUserView" mat-icon-button (click)="openDialogChat()" class="me-3">
                                    <mat-icon >chat</mat-icon>
                                </button>
                                <button mat-raised-button class="bg-info text-white"  (click)="openDialogFormHistory()">Ver historial de formulario <mat-icon>history</mat-icon></button>
                            </div>
                        </div>                        
                        <mat-divider></mat-divider>
                        <!--<form *ngIf="isUserView || dataGet?.memb_sts[0]?.can_edit == 1" autocomplete="off" [formGroup]="formProvider" (ngSubmit)="ruSure()" class="mt-3" id="userForm">-->
                         <div *ngIf="isUserView || dataGet?.memb_sts[0]?.can_edit == 1">
                            <small>Todos los campos son obligatorios *</small>
                            <div *ngFor="let forma of formulario;" class="row mt-2">
                                <div class="col-sm-4 pt-4">
                                    <span class="h5">{{ forma.nombre }}: </span>
                                </div>
                                <div class="col-sm-2">
                                    <mat-button-toggle-group appearance="legacy" [(ngModel)]="forma.respuesta" (change)="detectViewChange()" name="form"  class="mt-3">
                                        <mat-button-toggle [value]="true" [ngClass]="forma.respuesta ? 'bg-success text-white' : 'bg-white' ">SI</mat-button-toggle>
                                        <mat-button-toggle [value]="false" [ngClass]="forma.respuesta ? 'bg-white' : 'bg-danger text-white' ">NO</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                                <div class="col-sm-6">
                                    <mat-form-field class="w-100 no-space-bottom"  appearance="outline" *ngIf="!forma.respuesta">
                                        <mat-label>Ingresa comentarios</mat-label>
                                        <input [(ngModel)]="forma.comentario" formControlName="desc_producto_com"  matInput>
                                    </mat-form-field>
                                </div>
                            </div>


                           <!--<div class="row mt-2">
                                <div class="col-sm-4 pt-4">
                                    <span class="me-3 h5">Razón social: </span>
                                </div>
                                <div class="col-sm-8 pt-3">
                                    <mat-form-field class="w-100 no-space-bottom" appearance="outline">
                                        <mat-label>Ingresa razón social</mat-label>
                                        <input formControlName="razon_social" matInput value="PFIZER, S.A. DE C.V.">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4 pt-4">
                                    <span class="me-3 h5">Empresa recuperadora: </span>
                                </div>
                                <div class="col-sm-8 pt-2">
                                    <mat-form-field  class="no-space-bottom" appearance="outline">
                                        <mat-label>Selecciona una opción</mat-label>
                                        <mat-select formControlName="empresa_recuperadora">
                                            <mat-option value="SPT">SPT</mat-option>
                                            <mat-option value="COFACH">COFACH</mat-option>
                                            <mat-option value="MAYORISTA">MAYORISTA</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4 pt-4">
                                    <span class="me-3 h5"><mat-icon class="align-middle" matTooltip="Describe el concepto como se requiere el documento fiscal o carga algún archivo para describirlo" [matTooltipPosition]="'above'">error_outline</mat-icon> Concepto (descripción detallada):</span>
                                </div>
                                <div class="col-sm-6 pt-2">
                                    <mat-form-field class="w-100 no-space-bottom" appearance="outline">
                                        <mat-label>Detalla el concepto</mat-label>
                                        <textarea formControlName="concepto"  matInput></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-2 pt-2">
                                    <button mat-flat-button color="primary" (click)="openUploadFileConcepto()">Cargar archivo <mat-icon>upload</mat-icon> </button>
                                    <div *ngIf="fileConcepto"> {{ fileConcepto.name }} <span (click)="removeFileConcepto()"><mat-icon style="font-size: 20px; cursor: pointer">close</mat-icon></span></div>
                                </div>
                              
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4 pt-4">
                                    <span class="me-3 h5">Monto: </span>
                                </div>
                                <div class="col-sm-8 pt-2">
                                    <mat-form-field class="no-space-bottom" appearance="outline">
                                        <mat-label>Ingresa monto</mat-label>    
                                        <input formControlName="monto"  matInput value="">
                                        <span matPrefix class="align-middle"><mat-icon>attach_money</mat-icon></span>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4 pt-4">
                                    <span class="me-3 h5">Método de pago: </span>
                                </div>
                                <div class="col-sm-8 pt-2">
                                    <mat-form-field class="no-space-bottom" appearance="outline">
                                        <mat-label>Selecciona una opción</mat-label>
                                        <mat-select formControlName="metodo_pago" >
                                            <mat-option value="NOTA DE CRÉDITO">NOTA DE CRÉDITO</mat-option>
                                            <mat-option value="FACTURA">FACTURA</mat-option>        
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>--> 
                            <div class="row">
                                <div class="col-sm-10 pt-2">
                                    <mat-form-field class="w-100 no-space-bottom" appearance="outline">
                                        <mat-label>Escriba su comentario</mat-label>
                                        <textarea name="comentario" [(ngModel)]="surveyResponse.comentario" matInput></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-2 pt-2">
                                    <button mat-flat-button color="primary" (click)="openUploadFileComentario()">Cargar archivo <mat-icon>upload</mat-icon> </button>
                                </div>
                            </div>
                            <span *ngIf="fileComentario.length != 0">Archivos:</span>
                            <div *ngFor="let file of fileComentario; let i = index">
                                <b>{{ file.name }}</b>
                                <span (click)="removeFileComentario(i)">
                                  <mat-icon style="font-size: 20px; cursor: pointer">close</mat-icon>
                                </span>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 text-end" *ngIf="!locked && dataGet?.memb_sts[0]?.can_edit == 1 && dataGet?.provider_suv[0]?.status != 2">
                                    <button mat-flat-button mat-dialog-close>Limpiar <mat-icon>clear</mat-icon></button>
                                    <button mat-raised-button class="bg-success text-white" (click)="ruSure()">Guardar <mat-icon>save</mat-icon></button>
                                </div>
                            </div>
                        </div>
                       <!-- </form>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>