<div class="content-wrapped">
  <div class="container-xxl flex-grow-1 main-title-container">
    <h4 class="fw-bold pt-3 text-primary">
      <mat-icon class="align-middle head-icon">perm_media</mat-icon>
    </h4>
    <div class="title-container">
      <small > <span [routerLink]="'/admin/projects/'+ currentMedia?.id_proy_camp" style="cursor:pointer"> {{ currentMedia.nombre_campana }}</span> <span class="arrow">></span> 
        <span [routerLink]="'/admin/projects/project/'+ currentMedia?.id_proyecto + '/media'" style="cursor:pointer">{{ currentMedia.nombre_proyecto }}</span> </small>
      <h2>{{currentMedia.nombre}}</h2>
    </div>
  </div>
  <div style="padding-left: 25px;">
    <button mat-raised-button class="nest-button" [routerLink]="'/admin/projects/project/'+ currentMedia?.id_proyecto + '/media'">
      <mat-icon>arrow_back</mat-icon><span class="nb-text">Atras</span>
    </button>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card mb-4">
        <div class="heading-container">
          <h3 class="card-header">
            <mat-icon>shop</mat-icon> Elementos POP
          </h3>
          <div class="button-container">
            <button mat-raised-button class="nest-button" (click)="newPOP()">
              <mat-icon>add_circle</mat-icon><span class="nb-text">Nuevo elemento POP</span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="my-3">
            <div class="row">
              <div class="col-md-4 pop-card" *ngFor="let x of poplistActual; let i = index">
                <div class="card p-3 mb-2 mx-1">
                  <div class="ms-2">
                    <div class="row title-row" (click)="showPOPDetails(x)">
                      <h5 class="heading"><mat-icon class="align-middle head-icon">image</mat-icon>{{ x.nombre }}</h5>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between my-2" (click)="showPOPDetails(x)">
                    <div class="d-flex flex-row align-items-center">
                      <div class="ms-2 c-details">
                        <ul class="list-unstyled mb-0">
                          <!--<li>
                            <strong>Nombre:</strong> {{ x.nombre }}
                          </li>-->
                          <li *ngIf="x.prioridad && x.prioridad != 0">
                            <strong>Prioridad:</strong> {{ x.prioridad }}
                          </li>
                          <li *ngIf="x.posiciones && x.posiciones!= 0">
                            <strong>Posiciones:</strong> {{ x.posiciones }}
                          </li>
                        </ul>
                        <strong *ngIf="converToObject(x.sucursales_filtro).length > 0">Filtros:</strong>
                        <ul *ngFor="let s of converToObject(x.sucursales_filtro)">
                          <li>
                             <strong>Nombre:</strong> {{ s.nombre }}  
                          </li>
                          <li *ngIf="s.operador == 1">
                            <strong>Igual a</strong> 
                          </li>
                          <li *ngIf="s.operador == 2">
                            <strong>Mayor a</strong> 
                          </li>
                          <li *ngIf="s.operador == 3">
                            <strong>Menor a</strong> 
                          </li>
                          <li>
                            <strong>Valor:</strong> {{ s.valor }} 
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <button mat-flat-button color="accent" (click)="goToConfign(x.id)"> Configuración <mat-icon>edit</mat-icon></button>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="card">
  <div class="card-body">
    <div class="title-container">
      <small>PROYECTO {{ projectID }} <span class="arrow">></span> MEDIO {{ mediaID }}</small>
      <h3>Elementos POP</h3>
    </div>
    <div class="content-container">
      <div class="button-container">
        <button mat-raised-button class="nest-button" (click)="newPOP()">
          <mat-icon>add_circle</mat-icon><span class="nb-text">Nuevo Elemento</span>
        </button>
      </div>
      <div class="my-3">
        <div class="row">
          <div class="col-md-4" *ngFor="let x of poplist">
            <div class="card p-3 mb-2 mx-1">
              <div class="ms-2">
                <div class="row title-row">
                  <div class="col-auto pe-0">
                    <img class="icon" src="../../../../assets/img/pop/test.png">
                  </div>
                  <div class="col p-0 d-flex">
                    <h5 class="heading">{{ x.title }}</h5>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between my-2">
                <div class="d-flex flex-row align-items-center">
                  <div class="ms-2 c-details">
                    <ul class="list-unstyled mb-0">
                      <li *ngFor="let y of x.info">
                        <strong>{{ y[0] }}:</strong> {{ y[1] }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->