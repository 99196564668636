import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Injectable  } from '@angular/core'
import { MainService } from '../services/main.service';
import { Router } from '@angular/router';
import { catchError, Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProjectCampaingResolver implements Resolve<any>{
    constructor(
        private _main: MainService, 
        private _router : Router
    ) {

    }
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) : Observable<any>{
        this._main.ShowLoading();
        return  this._main.getRequest2("/projects/projects/config/" + route.paramMap.get('id')).pipe(catchError(error=> { this._main.HideLoading(); console.log("error loading campaign - redirect", error); this._router.navigateByUrl('/admin/dashboard'); return of(null)}));
    }

}