import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectsCampaignFileAddComponent } from '../../shared/dialogs/projects-campaign-file-add/projects-campaign-file-add.component';
import { CampaignLogsComponent } from '../../shared/dialogs/campaign-logs/campaign-logs.component';
import { ProjectsCampaignCommentsAddComponent } from '../../shared/dialogs/projects-campaign-comments-add/projects-campaign-comments-add.component';
import { ProjectsProvidersListComponent } from '../../shared/dialogs/projects-providers-list/projects-providers-list.component';
import { MainService } from 'src/app/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { ProjectsService } from 'src/app/services/projects.service';
import { environment } from 'src/environments/environment';
import { env } from 'process';
import { ProjectsCampaignsFileLogsComponent } from '../../shared/dialogs/projects-campaigns-file-logs/projects-campaigns-file-logs.component';

@Component({
  selector: 'app-projects-campaign-view',
  templateUrl: './projects-campaign-view.component.html',
  styleUrls: ['./projects-campaign-view.component.scss']
})
export class ProjectsCampaignViewComponent implements OnInit {
  //ESTE COMPONENTE ESTA DESCARTADO!
  constructor(
    private matDialog: MatDialog,
    private main : MainService,
    private _params: ActivatedRoute,
    private _titleService: Title,
    private _router : Router,
    private projectService : ProjectsService
  ) { }

  campaign : any;
  phases : any = [];
  archivos : any = [];
  infometadata : any;

  ngOnInit(): void {
    this._params.params.subscribe(params => {
      if(params.id){
        this.loadCampaignData();
      }else{
        //this._router.navigateByUrl('/admin/dashboard');
      } 
    });
  }

  //camp rol
  //trade 1
  //promociones 2
  //comercial 3
  //legal 4
  //comercialización 5
  //proveedores 6
  //marketing 7

  //fase id
  //diagrama - 1
  //artes  - 2   
  //artes 2 - 3
  //artes 3 - 4
  //distribucion


  openDialogUploadCampaignFile(phase_id : number) {
    this.matDialog.open(ProjectsCampaignFileAddComponent, {
      panelClass: ['dialogs-main'],
      data: {
        phase_id : phase_id,
        id_campana_proyecto : this.campaign.id,
        folder : this.campaign.folder,
        nombre : this.campaign.nombre
      }
    }).afterClosed().subscribe(
      resp => {
        this.reloadCampaignData();
      }
    );
  }

  openDialogFileLogs(fileid : number, filename : string) {
    this.matDialog.open(ProjectsCampaignsFileLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
        fileid : fileid,
        filename : filename
      }
    }).afterClosed().subscribe(
      resp => {
        this.reloadCampaignData();
      }
    );
  }

  openDialogCommentNoAuth(phase_id : number, rol_id : number) {
    this.matDialog.open(ProjectsCampaignCommentsAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
          phase_id : phase_id,
          rol_id : rol_id,
          camp_id : this.campaign.id,
          nombre : this.campaign.nombre
        }
    }).afterClosed().subscribe(
      resp => {
        this.reloadCampaignData();
      }
    );
  }

  openDialogProviders() {
    this.matDialog.open(ProjectsProvidersListComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        camp_id : this.campaign.id,
        folder : this.campaign.folder,
        nombre : this.campaign.nombre
      }
    }).afterClosed().subscribe(
      resp => {
        this.reloadCampaignData();
      }
    );
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.projectService.hasPermissions(key, buho, client);
  }

  loadCampaignData(){
    console.log("Distribución Campaña", this._params.snapshot.data.data);
    this.main.HideLoading();
    if( this._params.snapshot.data.data ){
       if(this._params.snapshot.data.data.message = 'Success'){
        console.log(this._params.snapshot.data.data.data);
         this.campaign = this._params.snapshot.data.data.data.campana[0];
         this.phases = this._params.snapshot.data.data.data.fases;
         this.archivos = this._params.snapshot.data.data.data.archivos;
         this._titleService.setTitle("Buho Nest - Campaña - " + this.campaign.nombre);
         this.infometadata = this._params.snapshot.data.data.data.info_metadata;
       }
    }else{
      this.main.HideLoading();
      //this._router.navigateByUrl('/admin/dashboard');
    }
   }

   reloadCampaignData(){
    this.main.ShowLoading();
    this.projectService.getProjectConfig(this.campaign.id).subscribe((result:any)=>{
      this.main.HideLoading();
      if( result.message = 'Success'){
        this.campaign = result.data.campana[0];
        this.phases =  result.data.fases;
        this.archivos = result.data.archivos;
        this._titleService.setTitle("Buho Nest - Campaña - " + this.campaign.nombre);
        this.infometadata = result.data.info_metadata;
      }
    });
   }

   getPhaseStatus(phase_id: number,rol_id : number) : number{
    if(this.phases.filter((x:any)=>{ return x.phase_id == phase_id && x.camp_rol == rol_id }).length > 0){
      return this.phases.filter((x:any)=>{ return x.phase_id == phase_id && x.camp_rol == rol_id })[0].status;
    }else{
      return 2;
    }
  }


  getPhaseDate(phase_id: number,rol_id : number) : Date{
    if(this.phases.filter((x:any)=>{ return x.phase_id == phase_id && x.camp_rol == rol_id }).length > 0){
      return this.phases.filter((x:any)=>{ return x.phase_id == phase_id && x.camp_rol == rol_id })[0].last_changed;
    }else{
      return new Date();
    }
  }

  isNextPhaseRol(phase_id: number,rol_id : number){
    return phase_id == this.infometadata?.last_phase && rol_id == this.infometadata?.curr_rol;
  }

  calculateNext(phase_id: number,rol_id : number){
    return this.main.addWorkingDays(this.infometadata?.last_date, 2);
  }

  //camp rol
  //trade 1
  //promociones 2
  //comercial 3
  //legal 4
  //comercialización 5
  //proveedores 6
  //marketing 7

  //fase id
  //diagrama - 1
  //artes  - 2   
  //artes 2 - 3
  //artes 3 - 4
  //distribucion


  phases_catalog = [{phase_id : 1, name : "Diagrama"},
                    {phase_id : 2, name : "Artes"},
                    {phase_id : 3, name : "Artes 2"},
                    {phase_id : 4, name : "Artes 3"},
                    {phase_id : 5, name : "Distribición"}];

  camp_rol_catalog = [{camp_rol : 1 , name : "Trade"},
                      {camp_rol : 2, name : "Promociones"},
                      {camp_rol : 3, name : "Comercial"},
                      {camp_rol : 4, name : "Legal"},
                      {camp_rol : 5, name : "Comercialización"},
                      {camp_rol : 6, name : "Proveedores"},
                       {camp_rol : 6, name : "Marketing"},]

  getStatusClassIcon(status : number){
      switch(status){
        case 1 :
          return { icon: "check",  class: "completed" };
          break; 
        case 2 :
          return { icon: "more_horiz", class: "active" };
          break;
        case 3 :
          return { icon: "close", class: "canceled" };
          break;
        default : 
          return { icon: "more_horiz", class: "active" };
          break;
      }
  }



  changePhaseSts(phase_id : number, rol_id : number, status  : number){
    if(phase_id == this.infometadata.last_phase && rol_id == this.infometadata.curr_rol){
    Swal.fire({
      title: '¿Desea cambiar el status de VoBo. a '+ (status == 1 ? 'Confirmado' : status == 2 ? 'Pendiente' : status == 3 ? 'No Autorizado'  : '') +' de la fase '+ this.phases_catalog.filter(x=>{ return x.phase_id == phase_id})[0].name + 
      ' en ' + this.camp_rol_catalog.filter(x=>{ return x.camp_rol == rol_id})[0].name  +'?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.main.ShowLoading();
            this.projectService.setPhase(phase_id, rol_id, status, this.campaign.id ,'').subscribe((x:any)=>{
              if(x.message == 'Success'){
                this.main.HideLoading();
                console.log("changed");
                Swal.fire({
                  icon: 'success',
                  title: 'Estatus actualizado',
                  text: 'Estatus actualizado exitosamente'          
                });
                this.reloadCampaignData();
              }
            })
        }
      });
    }else{
        Swal.fire({
          icon: 'warning',
          title: 'No puedes realizar esta acción',
          text: 'No han realizado la aprobación anterior o no cuentas con el permiso'
        });
      


    }
  }

  calculateAprovals(phase_id : number, maxRol : number){
      let noAprovals = this.phases.filter((x:any)=>{ return x.phase_id == phase_id && x.status == 1 }).length;
      let noDisAprovals = this.phases.filter((x:any)=>{ return x.phase_id == phase_id && x.status == 3 }).length;
      //let pendingAprovals = this.phases.filter((x:any)=>{ return x.phase_id == phase_id && x.status == 2 }).length;
      let classRet = '';
      if( noAprovals == maxRol) { classRet ='bg-success'} 
      if( noAprovals != maxRol ) { classRet ='bg-warning'};
      if( noAprovals != maxRol && noDisAprovals != 0) { classRet ='bg-danger'} 
      if(!this.hasFilesOnPhase(phase_id)) { classRet = 'bg-label-primary' }
      //if( noAprovals ==  0 && noDisAprovals == 0 && pendingAprovals == 0) { classRet ='bg-label-primary'}
      return { aprovals : noAprovals,  maxRol: maxRol, class : classRet }
  }

  getArchivosByPhase(phase_id : number){
    return this.archivos.filter((x:any)=>{ return x.phase_id == phase_id });
  }

  hasFilesOnPhase(phase_id : number){
    return this.archivos.filter((x:any)=>{ return x.phase_id == phase_id }).length > 0;
  }

  getRol(rol_id : number){
    return rol_id == 1 ? "Búho" : environment.roles.filter((x:any)=>{ return x.id == rol_id })[0].rol;
  }

  lastModPhase(phase_id : number){
    return this.archivos.filter((x:any)=>{ return x.phase_id == phase_id }).sort((a:any, b:any) => { 
      a = new Date(a.created_at);
      b = new Date(b.created_at);
      return a >b ? -1 : a < b ? 1 : 0;
     })[0];
  }
}
