<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            <mat-icon class="align-middle me-2 text-muted" role="button" (click)="goBack()">arrow_back_ios</mat-icon><mat-icon class="me-2 align-middle">list_alt</mat-icon>Atributos
            <button mat-flat-button color="primary" class="ms-4" (click)="openDialogAttibutesAdd()">Crear nuevo atributo <mat-icon>add</mat-icon></button>
        </h4>
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-body">                        
                        <div class="col-md-12">
                            <div class="overflow-auto w-100">
                                <div class="float-end">
                                    <button mat-flat-button color="primary" (click)="openDialogValuesUpload()">Carga Masiva <mat-icon>upload</mat-icon></button>
                                    <button mat-flat-button color="primary" class="mt-2 mb-2 ms-4" (click)="openDialogAttibutesReorder()">Reordenar <mat-icon>swap_vert</mat-icon></button>
                                </div>
                                <mat-form-field> 
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event)" placeholder="Ej.Botadero" #input>
                                </mat-form-field>
                            </div>
                            
                            <div class="mat-elevation-z8 table-responsive">
                                <table mat-table [dataSource]="dataSource" matSort class="w-100">
                        
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Atributo </th>
                                        <td mat-cell *matCellDef="let row"> <strong>{{row.name}}</strong> </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="type">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Categoría </th>
                                        <td mat-cell *matCellDef="let row"> <span class="badge bg-info">{{buscarPorTypeId(row.type_id)[0].name}}</span> </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="values">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Opciones </th>
                                        <td mat-cell *matCellDef="let row"> 
                                            <ng-container  *ngIf="row.type_id == 1">
                                                <span class="badge bg-purple me-2"> SI</span> 
                                                <span class="badge bg-purple me-2"> NO</span> 
                                            </ng-container> 
                                            <ng-container  *ngIf="row.type_id == 3">
                                                <span class="badge bg-purple me-2" *ngFor="let v of getOptions(row.select_data)">{{v}}</span> 
                                            </ng-container>      
                                            <ng-container  *ngIf="row.type_id == 4">
                                                <span class="badge bg-purple me-2"> {{row.select_data}}</span> 
                                            </ng-container> 
                                        </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="edit">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ¿Editable? </th>
                                        <td mat-cell *matCellDef="let row"> <span class="badge" [ngClass]="row.editable ? 'bg-success' : 'bg-secondary'">{{row.editable ? 'SI' : 'NO'}}</span> </td>
                                    </ng-container>

                                    <ng-container matColumnDef="autorization">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>¿ Requiere Autorización? </th>
                                        <td mat-cell *matCellDef="let row"> <span class="badge" [ngClass]="row.autorization ? 'bg-success' : 'bg-secondary'">{{row.autorization ? 'SI' : 'NO'}}</span> </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="createdDate">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                                        <td mat-cell *matCellDef="let row"> {{row.created_at  | date : "dd/MM/yyyy"}} </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                        <td mat-cell *matCellDef="let row" class="text-center">
                                            <button mat-icon-button matTooltip="Valores de Sucursales" [matTooltipPosition]="'above'" color="accent" (click)="openDialogAttibutesStoreList(row)">
                                                <mat-icon>store</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" color="accent" (click)="openDialogAttibutesAdd(true, row)">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                            <button *ngIf="row.type_id == 6 || row.type_id == 4" mat-icon-button matTooltip="Reglas" [matTooltipPosition]="'above'" color="accent" (click)="openDialogAttibutesRulesList(row)">
                                                <mat-icon>rule</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                        
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                    </tr>
                                </table>
                        
                                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>