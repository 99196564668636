import { Component, Inject, OnInit } from '@angular/core';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-phases-reorder',
  templateUrl: './phases-reorder.component.html',
  styleUrls: ['./phases-reorder.component.scss'],
})
export class PhasesRedorderComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data : any,
  private main : MainService,   private dialogRef: MatDialogRef<PhasesRedorderComponent>, ) {

    this.phases = this.data.phases;
    this.hasChanges = this.data.hasChanges;
   }

   phases : any[] = [];
   hasChanges : boolean = false;

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<any[]>) {
    this.hasChanges = true;
    moveItemInArray(this.phases, event.previousIndex, event.currentIndex);
  }

  saveReOrder(){
    console.log(this.phases);
    this.dialogRef.close({ reload: false });

  }

}
