<div style="display: flex; justify-content: flex-end;">
  <button type="button" class="btn-close" [mat-dialog-close]=""></button>
</div>
<h1 mat-dialog-title class="m-0">Configuración de Regla para {{ nombreAtributo }}</h1>

<div class="modal-body-scroll">
  <div class="inputform">

    <div class="col-md-5"> 
      <mat-form-field appearance="outline">
        <mat-label>Nombre </mat-label>
        <input matInput [(ngModel)]="dataR.nombre" name="nombre" required>
      </mat-form-field>
    </div>

    <div class="col-md-3">
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Tipo</mat-label>
        <mat-select [(ngModel)]="dataR.tipo" name="tipo" (selectionChange)="onTipoCambio($event)" [disabled]="isEdit">
          <mat-option [value]="1">Sumatoria</mat-option>
          <mat-option [value]="2">Mayor que o Menor que</mat-option>
          <mat-option [value]="3">Rangos</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div *ngIf= "dataR.tipo == 1" class="row">
      <h5 class="fw-bold text-primary">Atributos sumatoria</h5>
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Atributo</mat-label>
          <mat-select [(ngModel)]="dataR.data_regla.attributes" name="atributes" multiple>
            <mat-option *ngFor="let atributo of atributos" [value]="atributo.id">{{ atributo.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf= "dataR.tipo == 2" class="row">
      <h5 class="fw-bold text-primary">Configuración</h5>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Atributo</mat-label>
          <mat-select [(ngModel)]="dataR.data_regla.attribute" name="atribute" >
            <mat-option *ngFor="let atributo of atributos" [value]="atributo.id">{{ atributo.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Condición</mat-label>
          <mat-select [(ngModel)]="dataR.data_regla.operador" name="operador">
            <ng-container>
              <mat-option [value]="1">Mayor que</mat-option>
              <mat-option [value]="2">Menor que</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Valor a comparar</mat-label>
          <input matInput [(ngModel)]="dataR.data_regla.valor_comparar" name="valor" placeholder="Ej: Valor a comparar" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Valor que el atributo adquirirá</mat-label>
          <input matInput [(ngModel)]="dataR.data_regla.valor_atributo" name="valor" placeholder="Ej: Valor que el atributo adquirirá" />
        </mat-form-field>
      </div>
    </div>

    <div *ngIf= "dataR.tipo == 3" class="row">
      <h5 class="fw-bold text-primary">Configuración</h5>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Atributo</mat-label>
          <mat-select [(ngModel)]="dataR.data_regla.attribute" name="atribute" >
            <mat-option *ngFor="let atributo of atributos" [value]="atributo.id">{{ atributo.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Valor minimo</mat-label>
          <input matInput [(ngModel)]="dataR.data_regla.valor_min" name="valor" placeholder="Ej: Valor minimo" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-label></mat-label>
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Valor máximo</mat-label>
          <input matInput [(ngModel)]="dataR.data_regla.valor_max" name="valor" placeholder="Ej: Valor máximo" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Valor que el atributo adquirirá</mat-label>
          <input matInput [(ngModel)]="dataR.data_regla.valor_atributo" name="valor" placeholder="Ej: Valor que el atributo adquirirá" />
        </mat-form-field>
      </div>
    </div>
   
   <!-- <div class="mb-4">
      <h5 class="fw-bold text-primary">Filtro sucursales</h5>
      <div *ngFor="let regla of reglasAlcance; let i = index; trackBy: trackByFn" class="mb-3">
        <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Atributo</mat-label>
              <mat-select [(ngModel)]="regla.id" name="atributo-{{i}}" (selectionChange)="setItemName($event.value, regla)">
                <mat-option *ngFor="let atributo of atributos" [value]="atributo.id">{{ atributo.name }}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-md-3">
            <mat-form-field *ngIf="getItem(regla.id) != null" appearance="outline" class="form-field">
              <mat-label>Condición</mat-label>
              <mat-select [(ngModel)]="regla.operador" name="operador-{{i}}">
                <ng-container>
                  <mat-option *ngIf="getItem(regla.id).type_id == 4 || getItem(regla.id).type_id == 6 || getItem(regla.id).type_id == 5 || getItem(regla.id).type_id == 3 || getItem(regla.id).type_id == 1" [value]="1">Igual que</mat-option>
                  <mat-option *ngIf="getItem(regla.id).type_id == 4 || getItem(regla.id).type_id == 6 " [value]="2">Mayor que</mat-option>
                  <mat-option *ngIf="getItem(regla.id).type_id == 4 || getItem(regla.id).type_id == 6 " [value]="3">Menor que</mat-option>

                </ng-container>

              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field *ngIf="getItem(regla.id) != null" appearance="outline" class="form-field">
              <mat-label>Valor</mat-label>
              <input matInput [hidden]="getItem(regla.id).type_id != 4" [(ngModel)]="regla.valor" name="valor-{{i}}" (blur)="calculateStores()" type="number" placeholder="Valor de {{getRanges(getItem(regla.id).select_data)[0]}} a {{getRanges(getItem(regla.id).select_data)[1]}}" min="{{getRanges(getItem(regla.id).select_data)[0]}}" max="{{getRanges(getItem(regla.id).select_data)[1]}}">
              <input matInput [hidden]="getItem(regla.id).type_id != 6" [(ngModel)]="regla.valor" name="valor-{{i}}" (blur)="calculateStores()" type="number"  placeholder="Ej: 10" />
              <input matInput [hidden]="getItem(regla.id).type_id != 5" [(ngModel)]="regla.valor" name="valor-{{i}}" (blur)="calculateStores()" placeholder="Ej: Valor" />
              <ng-container *ngIf="getItem(regla.id).type_id == 3">
                <mat-select  [(ngModel)]="regla.valor" (selectionChange)="calculateStores()" name="valor-{{i}}">
                  <mat-option *ngFor="let valor of converToObject(getItem(regla.id).select_data)" [value]="valor" >{{ valor }}</mat-option>
                </mat-select>
              </ng-container>
              <ng-container *ngIf="getItem(regla.id).type_id == 1" >
                <mat-select  [(ngModel)]="regla.valor" name="valor-{{i}}" (selectionChange)="calculateStores()">
                  <mat-option  [value]="'SI'">SI</mat-option>
                  <mat-option  [value]="'NO'">NO</mat-option>
                </mat-select>
              </ng-container>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <button mat-icon-button color="warn" (click)="eliminarRegla(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <button mat-flat-button color="primary" (click)="agregarRegla()">
        <mat-icon>add</mat-icon> Añadir Regla
      </button>
    </div>!-->

  </div>
</div>
<div mat-dialog-actions class="button-container">
  <button mat-raised-button *ngIf="!isEdit" class="nest-button" (click)="register()" [disabled]="invalidInput()">Agregar</button>
  <button mat-raised-button *ngIf="isEdit" class="nest-button" (click)="edit()" [disabled]="invalidInput()">Editar</button>
</div>