import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router} from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { NewPopMaterialComponent } from '../../shared/dialogs/new-pop-material/new-pop-material.component';
import { EditPopMaterialComponent } from '../../shared/dialogs/edit-pop-material/edit-pop-material.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import { CutoffService } from 'src/app/services/cutoff.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pop-materials',
  templateUrl: './pop-materials.component.html',
  styleUrls: ['./pop-materials.component.scss']
})
export class PopMaterialsComponent implements OnInit {
  mediaID: number = 0;
  projectID: number | null = null;
  // HTML View Controller Elements
  activepop: number = -1;


  poplistActual : Array<any> = [];

  currentMedia : any;

  constructor(private dialog: MatDialog, private route: ActivatedRoute, 
    private router: Router, private projService : ProjectsService,
    private _main : MainService, private _cutoffS : CutoffService) { }

  ngOnInit(): void {
    this.getData();
  }


  /* async Promise<void> getData(0):
     Extracts data from URL. */
  async getData(): Promise<void> {
    try {
      // Extract ID
      const url: ParamMap = await firstValueFrom(this.route.paramMap);
      this.projectID = Number(url.get('pid'));
      this.mediaID = Number(url.get('mid'))

      this.getPopListActual(this.mediaID);

      if (!this.projectID)
        throw new Error('No se recibió ningún ID.');
    } catch (error) {
      console.error(error);
    }
  }


  getPopListActual(media_id:number){
   // return this.projService.poplist.filter(x=>( x.media_id == media_id));
    this.projService.getPOPs(media_id).subscribe((x: any) => {
      this._main.HideLoading();
      this.currentMedia = x.data.medio;
      this.poplistActual = x.data.elementos_pop;
      //ESTO DEBE DE ESTAR EN TODAS LOS COMPONENTESD DE PROYECTOS
      this._cutoffS.checkIfSameProjectThenReplace(this.currentMedia.id_proy_camp);

    },
    (err:any) => {
      console.log(err);
          this._main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener registros, vuelva a intentarlo más tarde.'
          });
    });
  }

  async newPOP(): Promise<void> {
    const npDialogRef: MatDialogRef<NewPopMaterialComponent, any> = this.dialog.open(NewPopMaterialComponent, {
      data: { medio: this.currentMedia, isEdit : false},
      panelClass: 'dialogs-main'
    });

    try {
      const result = await firstValueFrom(npDialogRef.afterClosed());

      if (result) {
        this.getPopListActual(this.mediaID);
      }
    } catch (error) {
      console.error('Error handling dialog result:', error);
    }
  }

  async showPOPDetails(POP : any): Promise<void> {
    const spDialogRef: MatDialogRef<NewPopMaterialComponent, any> = this.dialog.open(NewPopMaterialComponent, {
      data: { medio: this.currentMedia, isEdit : true, pop : POP},
      panelClass: 'dialogs-main'
    });

    try {
      const result = await firstValueFrom(spDialogRef.afterClosed());

      if (result) {
        this.getPopListActual(this.mediaID);
      }
    } catch (error) {
      console.error('Error handling dialog result:', error);
    }
  }

  converToObject(obj: string){
    try{
      return JSON.parse(obj);
    }catch(ex){
      return [];
    }
  }

  goToConfign(popid :  number ){
    this.router.navigateByUrl('/admin/projects/pop-config/'+ this.projectID +'/'+ this.mediaID +'/'+ popid)
  }

}
