<div class="modal-header">
    <h4 class="modal-title">Clonar Configuraciones</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <h5 class="pb-4">
            <span class="align-middle">Lista de proyectos pasados</span>
            <div class="row float-end align-middle">

            </div>
            
        </h5>
        <div class="col-sm-9">
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Seleccione Proyecto</mat-label>
                <mat-select  matInput  (selectionChange)="changeProyecto($event.value);" required name="proyect">
                  <mat-option *ngFor="let m of proyects" [value]="m">{{ m.nombre }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div>
            <div class="row g-2">
                <div class="col mb-0">
                    <div class="table-responsive text-nowrap">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Proyecto</th>
                                    <th>Fecha de Registro</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr *ngIf="selectedProyecto">
                                    <td>
                                     {{ selectedProyecto.id }}
                                    </td>
                                    <td>
                                     {{ selectedProyecto.nombre }}
                                    </td>
                                    <td>
                                        {{selectedProyecto.created_at  | date : "dd/MM/yyyy hh:mma" : "-0000"}}
                                    </td>
                                    <td>
                                        <button (click)="goToPreview(selectedProyecto.id)" mat-icon-button class="mb-1" title="Ver Proyecto">
                                            <mat-icon>remove_red_eye</mat-icon>
                                        </button> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer pt-4">
    <button mat-button mat-dialog-close>Cerrar<mat-icon>close</mat-icon></button>
  <button mat-flat-button class="btn bg-success text-white" [disabled]="!selected" (click)="copyConfigConfirm()" type="button">Copiar proyecto<mat-icon>file_copy</mat-icon></button>
</div>