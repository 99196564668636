import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-project-list-clone-config',
  templateUrl: './project-list-clone-config.component.html',
  styleUrls: ['./project-list-clone-config.component.scss']
})
export class ProjectsListCloneConfigComponent implements OnInit {

  dataSent : any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,    
    private matDialog: MatDialog,
    private projectService : ProjectsService,
    private main : MainService,
    public dref: MatDialogRef<ProjectsListCloneConfigComponent>
  ) {
      this.dataSent = this.data; 
    }


  proyects : any = [];
  selectedProyecto : any;
  
  ngOnInit(): void {
    this.getProyectos();
  }

  selected = false;
  changeProyecto(value : any){
      this.selected = true;
      this.selectedProyecto = value;
  }

  getProyectos(){
    this.projectService.getActiveProjectList().subscribe((result:any)=>{
      if(result.message = 'Success'){
        this.proyects = result.data.filter((x:any)=> x.id != this.dataSent.proyecto_id);
        console.log("proyects", this.proyects);
      }
     },
     (err:any)=>{
      console.log(err);
     });

  }

  DownloadElement(url : string){
    window.open(url, "_blank");
  }


  copyConfigConfirm(){
    Swal.fire({
      title: '¿Está seguro que desea copiar la configuración de VoBo de este proyecto?',
      text: "La configuración de VoBos actual de este proyecto se ELIMINARÁ y se sustituirá por el proyecto seleccionado, por lo que recomendamos que revise dos veces antes de efectuar esta acción",
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  .then((willDelete : any) => {
    if (willDelete.isConfirmed) {
      this.main.ShowLoading();
      this.projectService.cloneProjectConfig(this.selectedProyecto.id, this.dataSent.proyecto_id).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result?.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Copiado',
              text: 'La configuración de VoBos ha sido copiada exitosamente'          
            }).then(s=>{
              this.dref.close();
            });
          }
         },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          if(err.error.message != "No valid provider surveys were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al copiar',
              text: 'Ocurrió un error al copiar.'
            });
          }
        }
      );
    } else {
    
    }
  });
  }

  goToPreview(id: number){
    let urlSend = '/admin/projects/config/'+id;
    window.open(urlSend, "_blank");
  }

  checkIfCanUploadFile(){
    this.dataSent.vobos;
  }

}
