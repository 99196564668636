import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DistributionComponent } from './components/distribution/distribution.component';
import { LoginComponent } from './components/login/login.component';
import { SettingsComponent } from './components/settings/settings.component';
import { LayoutComponent } from './components/shared/layout/layout.component';
import { AuthGuard } from './guards/auth.guard';
import { TemplateDesignComponent } from './components/template-design/template-design.component';

//Encuestas related components modules
import { SurveysDistributionComponent } from './components/surveys/distribution/surveys-distribution.component';
import { SurveysViewComponent } from './components/surveys/surveys-view/surveys-view.component';
import { TicketDetailsComponent } from './components/tickets/ticket-details/ticket-details.component';
import { TicketListComponent } from './components/tickets/ticket-list/ticket-list.component';
import { TicketAllListComponent } from './components/tickets/ticket-all-list/ticket-all-list.component';

//Projects related components
import { ProjectsListComponent } from './components/projects/projects-list/projects-list.component';
import { ProjectsViewComponent } from './components/projects/projects-view/projects-view.component';
import { ProjectsCampaignViewComponent } from './components/projects/projects-campaign-view/projects-campaign-view.component';
import { ProjectsProvidersViewComponent } from './components/projects/projects-providers-view/projects-providers-view.component';
import { ProjectMediaComponent } from './components/projects/project-media/project-media.component';
import { PopMaterialsComponent } from './components/projects/pop-materials/pop-materials.component';
import { ProjectDiagramationComponent } from './components/projects/project-diagramation/project-diagramation.component';

//Preprojects related componentes
import { PreprojectGenerateComponent } from './components/preprojects/preproject-generate.component';

//Resolvers
import { DistributionResolver } from '../app/resolvers/distribution.resolver';
import { DashboardResolver } from '../app/resolvers/dashboard.resolver';
import { ProjectCampaingResolver } from '../app/resolvers/projects-campaign.resolver';
import { ProvidersListComponent } from './components/providers/providers-list/providers-list.component';
import { ProvidersAddComponent } from './components/providers/providers-add/providers-add.component';
import { ProvidersSignUpComponent } from './components/providers/providers-sign-up/providers-sign-up.component';
import { ProvidersProjectsComponent } from './components/providers/providers-projects/providers-projects.component';
import { StoresListComponent } from './components/stores/stores-list/stores-list.component';
import { StoresCampaignCutoffComponent } from './components/stores/stores-campaign-cutoff/stores-campaign-cutoff.component';
import { StoresAttributesListComponent } from './components/stores/stores-attributes-list/stores-attributes-list.component';
import { StoresListZoneComponent } from './components/stores/stores-list-zone/stores-list-zone.component';
import { GroupsComponent } from './components/segmentation/groups/groups.component';
import { MaterialsDistributionComponent } from './components/segmentation/distribution/distribution.component';
import { DashboardTrackingComponent } from './components/dashboard-tracking/dashboard-tracking.component';
import { ProjectConfigComponent } from './components/projects/project-config/project-config.component';
import { ProjectPopConfigComponent } from './components/projects/project-pop-config/project-pop-config.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'surveys/:id', component: SurveysViewComponent },
  { path: 'providers/', component: ProjectsProvidersViewComponent },
  { path: 'providers/:id', component: ProjectsProvidersViewComponent },
  { path: 'providers-preview/:id/:id_usr', component: ProjectsProvidersViewComponent },
  { path: 'providers-sign-up/:id', component: ProvidersSignUpComponent },
  { path: 'providers-sign-up/', component: ProvidersSignUpComponent },
  {
    path: 'admin', canActivate: [AuthGuard], component: LayoutComponent, data: {expectedRoles: ['Any']},
    children: [
      //{ path: '', canActivate: [AuthGuard], component: LayoutComponent, data: {expectedRoles: ['Any']} },
      { path: '', pathMatch: 'full', redirectTo: 'dashboard'},
      { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent, data: {expectedRoles: ['Any']}, resolve : { data : DashboardResolver } },
      { path: 'dashboard-tracking', canActivate: [AuthGuard], component: DashboardTrackingComponent, data: {expectedRoles: ['Any']} },
      { path: 'distribution', canActivate: [AuthGuard], resolve : { data : DistributionResolver }, component: DistributionComponent, data: {expectedRoles: ['Any']} },
      { path: 'settings', canActivate: [AuthGuard], component: SettingsComponent, data: {expectedRoles: ['Any']} },
      { path: 'distribution/:id', resolve : { data : DistributionResolver } , canActivate: [AuthGuard], component: DistributionComponent, data: {expectedRoles: ['Any']} },
      { path: 'template-design', canActivate: [AuthGuard], component: TemplateDesignComponent, data: {expectedRoles: ['Any']} },
      { path: 'surveys/:id', canActivate: [AuthGuard], component: SurveysDistributionComponent, data: {expectedRoles: ['Any']} },
      { path: 'tickets', canActivate: [AuthGuard], component: TicketListComponent, data: {expectedRoles: ['Any']} },
      { path: 'tickets-all', canActivate: [AuthGuard], component: TicketAllListComponent, data: {expectedRoles: ['Any']} },
      { path: 'ticket/:compid/:campid/:sucid/:type', canActivate: [AuthGuard], component: TicketDetailsComponent, data: {expectedRoles: ['Any']} },
      { path: 'projects', canActivate: [AuthGuard], component: ProjectsListComponent, data: {expectedRoles: ['Any']} },
      { path: 'projects/:id', canActivate: [AuthGuard], component: ProjectsViewComponent, data: {expectedRoles: ['Any']} },
      { path: 'projects/config/:id', canActivate: [AuthGuard], component: ProjectConfigComponent, data: {expectedRoles: ['Any']}, resolve : { data : ProjectCampaingResolver } },
      { path: 'projects/vobos/:id', canActivate: [AuthGuard], component: ProjectsCampaignViewComponent, data: {expectedRoles: ['Any']}, resolve : { data : ProjectCampaingResolver } },
      { path: 'projects/pop-config/:projid/:mediaid/:popid', canActivate: [AuthGuard], component: ProjectPopConfigComponent, data: {expectedRoles: ['Any']} },
      { path: 'projects/project/diagramation/:projid', canActivate: [AuthGuard], component: ProjectDiagramationComponent, data: {expectedRoles: ['Any']} },
      { path: 'providers', canActivate: [AuthGuard], component: ProvidersListComponent, data: {expectedRoles: ['Any']} },
      { path: 'providers/add', canActivate: [AuthGuard], component: ProvidersAddComponent, data: {expectedRoles: ['Any']} },
      { path: 'providers/edit/:id', canActivate: [AuthGuard], component: ProvidersAddComponent, data: {expectedRoles: ['Any']} },
      { path: 'providers/projects', canActivate: [AuthGuard], component: ProvidersProjectsComponent, data: {expectedRoles: ['Any']} },
      { path: 'stores', canActivate: [AuthGuard], component: StoresListComponent, data: {expectedRoles: ['Any']} },
      { path: 'stores/cutoffs', canActivate: [AuthGuard], component: StoresCampaignCutoffComponent, data: {expectedRoles: ['Any']} },
      { path: 'stores/attributes', canActivate: [AuthGuard], component: StoresAttributesListComponent, data: {expectedRoles: ['Any']} },
      { path: 'storesbyzone', canActivate: [AuthGuard], component: StoresListZoneComponent, data: {expectedRoles: ['Any']} },
      { path: 'segmentation/groups', canActivate: [AuthGuard], component: GroupsComponent, data: {expectedRoles: ['Any']} },
      { path: 'segmentation/distribution', canActivate: [AuthGuard], component: MaterialsDistributionComponent, data: {expectedRoles: ['Any']} },
      { path: 'preproject-generate/:id', canActivate: [AuthGuard], component: PreprojectGenerateComponent, data: {expectedRoles: ['Any']} },
      { path: 'projects/project/:id/media', canActivate: [AuthGuard], component: ProjectMediaComponent, data: { expectedRoles: ['Any'] } },
      { path: 'projects/project/:pid/media/:mid', canActivate: [AuthGuard], component: PopMaterialsComponent, data: { expectedRoles: ['Any'] } }
    ]
  },
  {
    path: 'provider-members', canActivate: [AuthGuard], component: LayoutComponent, data: {expectedRoles: ['Any']},
    children: [
      { path: 'providers/projects', canActivate: [AuthGuard], component: ProvidersProjectsComponent, data: {expectedRoles: ['Any']} }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
